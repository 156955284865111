<script>
export default {
  name: 'EaSimpleCheckbox',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    label: { type: String, default: null },
    modelValue: { type: [String, Number, Boolean, Array] },
    value: { type: [String, Number] },
    name: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    asSwitch: { type: Boolean, default: false },
    index: { type: [String, Number], default: '' },
    size: { type: String },
  },
  computed: {
    inputId() {
      return `${this.name || ''}${this.index.toString().length ? '.' + this.index : ''}`
        .toLowerCase()
        .replace(/\W+/gi, '-')
    },
  },
}
</script>

<!-- eslint-disable prettier/prettier -->
<template lang="pug">
.form-check(:class="{ 'form-switch': asSwitch, [size]: !!size }")
  input.form-check-input(
    :disabled="disabled"
    :id="inputId"
    v-model="modelValue"
    :name="name"
    :value="value"
    @update:modelValue="$emit('update:modelValue', $event)"
    type="checkbox"
  )
  label.form-check-label(v-if="label" :for="inputId")
    slot(name="label") {{ label }}
</template>
