<script>
import BreakingList from 'frontend/common/breaking-list.vue'
import DataTableShowButton from 'frontend/common/data-tables/data-table-show-button.vue'
import ModalButton from 'frontend/common/modal-button.vue'
import { useModals } from 'frontend/common/modals'
import { TransportationPoint } from 'frontend/transportation-points'

export default {
  name: 'TransportationPoints',
  props: {
    location: { type: Object, required: true },
  },
  setup() {
    return {
      ...useModals(),
    }
  },
  components: {
    BreakingList,
    TransportationPoint,
    ModalButton,
    DataTableShowButton,
  },
}
</script>

<template lang="pug">
.d-flex.flex-column
  breaking-list(
    v-if="location.transportationPoints?.length"
    :items="location.transportationPoints"
  )
    template(#default="{ item }")
      transportation-point(:transportationPoint="item")
  span.text-black-15(v-else) empty
  modal-button(
    v-if="location.transportationPoints?.length"
    :item="location"
    :modal="previewModals.Location"
    modalInitialTab="Transportation Points"
    table
  )
    .d-flex.align-items-baseline
      a See all
      data-table-show-button
</template>

<style scoped lang="scss"></style>
