import { defineAsyncComponent } from 'vue'

const ShiftJobsTable = defineAsyncComponent(() => import('./shift-jobs-table.vue'))

const ALL_KNOWN_AASM_STATES = [
  { value: 'pending', label: 'Pending' },
  { value: 'in_route', label: 'In route' },
  { value: 'at_pickup_site', label: 'At pick-up site' },
  { value: 'picked_up', label: 'Picked up' },
  { value: 'in_progress', label: 'In progress' },
  { value: 'started', label: 'Started' },
  { value: 'at_dropoff_site', label: 'At drop-off site' },
  { value: 'dropped_off', label: 'Dropped off' },
  { value: 'completed', label: 'Completed' },
  { value: 'no_show', label: 'Incomplete', hidden: true },
  { value: 'incomplete', label: 'Incomplete' },
  { value: 'declined', label: 'Declined', disabledForAd: true },
  { value: 'cancelled', label: 'Cancelled', disabledForAd: true },
]

const AASM_STATES = ALL_KNOWN_AASM_STATES.filter(el => !el.hidden)

const AASM_STATES_MAPPING = ALL_KNOWN_AASM_STATES.reduce((result, next) => {
  result[next.value] = next.label
  return result
}, {})

const TYPES = [
  { value: 'TransferGroup', label: 'Transfer Group' },
  { value: 'ShuttleTransfer', label: 'Shuttle Transfer' },
  { value: 'ShiftAction', label: 'Shift Action' },
]

const CUSTOM_JOB_KINDS = [
  {
    label: 'Training / dry run',
    value: 'training_dry_run',
    shiftActionKind: 'custom_job',
  },
  {
    label: 'Pick and drop off of materials',
    value: 'pick_and_drop_off_of_materials',
    shiftActionKind: 'custom_job',
  },
  {
    label: 'Back up location',
    value: 'back_up_location',
    shiftActionKind: 'location_backup',
  },
  {
    label: 'Demand block with unclear client information',
    value: 'demand_block_with_unclear_client_information',
    shiftActionKind: 'custom_job',
  },
  {
    label: 'Car washing',
    value: 'car_washing',
    shiftActionKind: 'custom_job',
  },
  {
    label: 'Refueling',
    value: 'refueling',
    shiftActionKind: 'custom_job',
  },
  {
    label: 'Waiting / Standby',
    value: 'waiting_standby',
    shiftActionKind: 'location_backup',
  },
  {
    label: 'Vehicle transfer',
    value: 'vehicle_transfer',
    shiftActionKind: 'custom_job',
  },
  {
    label: 'Break',
    value: 'break',
    shiftActionKind: 'break',
  },
]

const TYPE_MAPPING = TYPES.reduce((result, next) => {
  result[next.value] = next.label
  return result
}, {})

const TG_IN_PROGRESS_STATES = new Set([
  'in_route',
  'at_pickup_site',
  'picked_up',
  'in_progress',
  'at_dropoff_site',
])
const TG_PAST_STATES = new Set(['no_show', 'dropped_off'])

const SA_IN_PROGRESS_STATES = new Set(['started'])
const SA_PAST_STATES = new Set(['completed', 'incomplete', 'declined'])

const ST_IN_PROGRESS_STATES = new Set(['started'])
const ST_PAST_STATES = new Set(['cancelled', 'completed', 'incomplete'])

export {
  AASM_STATES,
  AASM_STATES_MAPPING,
  CUSTOM_JOB_KINDS,
  SA_IN_PROGRESS_STATES,
  SA_PAST_STATES,
  ShiftJobsTable,
  ST_IN_PROGRESS_STATES,
  ST_PAST_STATES,
  TG_IN_PROGRESS_STATES,
  TG_PAST_STATES,
  TYPE_MAPPING,
  TYPES,
}
