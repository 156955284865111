import {
  checkAccessToEventRouteByUser,
  getEventOrganizationUser,
} from 'frontend/common/access-helpers'
import { useSignOut } from 'frontend/common/sign-out'
import { compact, last, map } from 'lodash'
import { mapGetters } from 'vuex'

import { appModule } from '../../_config/modules'
import { eventModuleGuard, organizationModuleGuard } from '../../common/module-access-guard'

//@vue/component
export default {
  name: 'RouterHooks',
  data() {
    return {
      userFetchingUnsubscribe: null,
    }
  },
  setup() {
    return { ...useSignOut() }
  },
  computed: {
    ...mapGetters('currentContext', ['currentEvent', 'currentCluster']),
    ...mapGetters('session', ['currentUser']),
    isAuthenticated() {
      return !!this.currentUser
    },
  },
  created() {
    this.$router.beforeResolve(this.fetchUser)
    this.$router.beforeResolve(this.waitForCurrentUser)
    this.$router.beforeResolve(this.handleCurrentContextReload)
    this.$router.beforeResolve(this.routeChangeHandler)
  },
  methods: {
    fetchUser(to, from, next) {
      if (to.meta.module !== appModule.EXTERNAL) {
        this.$store.dispatch('session/getOrFetchUser')
      }
      next?.()
    },
    waitForCurrentUser(to, from, next) {
      if (this.$route.meta.module === appModule.EXTERNAL) return
      this.userFetchingUnsubscribe?.()
      if (this.currentlyFetching === true && !to.path.includes('/session/sso/')) {
        this.userFetchingUnsubscribe = this.$store.subscribe(mutation => {
          if (['session/setCurrentlyFetching', 'session/setUser'].includes(mutation.type)) {
            next?.()
            this.userFetchingUnsubscribe?.()
          }
        })
        return
      } else {
        next?.()
      }
    },
    routeChangeHandler(to, from, next) {
      if (this.$route.meta.module === appModule.EXTERNAL) return

      // Do not check if only query params changed
      if (from.path === to.path && from.name === to.name) {
        debugLogger.info("Skipping check, path didn't change")
        next?.()
        return
      }

      debugLogger.info('Checking paths:', to.path, to.matched)

      // initial app run has no matched for routes
      if (!to.matched?.length) {
        logger.fail('Route has no matched items', to)
        this.$error({ message: 'Requested page not found' })
        if (next) {
          document.title = 'FIFA Transport'
          next('/')
        } else {
          this.$router.push('/')
        }
      } else {
        const auth = last(compact(map(to.matched, el => el.meta?.auth)))

        // debugLogger.info(auth)
        // is opened or needs logging out
        if (auth) {
          debugLogger.info('Route meta:', auth)
          if (this.shouldLogOut(auth)) {
            this.signOutAndGo(from, to, next)
          }
          next?.()
          return
          // needs authentication and authorization
        } else if (!this.currentUser) {
          logger.fail('There is no logged user required by the page')

          this.$store.commit('session/setAfterSignInPath', to.fullPath)
          this.$warning({
            message: 'Please sign in to go to the requested page',
          })
          if (next) {
            if (from?.matched?.length) {
              next(false)
            } else {
              document.title = 'Sign in - FIFA Transport'
              next('/session/sign-in')
            }
          } else {
            this.$router.push('/session/sign-in')
          }
          return
        }
        if (to.name != 'choose-context') {
          this.$store.commit('session/setAfterSignInPath', null)
        }

        const guard = organizationModuleGuard(
          null,
          this.currentUser?.impersonatedCurrentOrganizationUser ||
            this.currentUser?.currentOrganizationUser,
        )
        if (!guard.hasAccessibleModules) {
          if (to.name !== 'tmm-hub') {
            next?.({ name: 'tmm-hub' })
            this.$warning({ message: "You don't have access to the organization" })

            return
          } else if (from?.name === 'tmm-hub') {
            next?.(false)
          }
        } else if (to.meta.module || to.name === 'tmm-hub') {
          if (to.meta.module !== appModule.PASSENGER && guard.hasOnlyPassengerAccess) {
            next?.({ name: 'passenger-portal' })
            if (to.meta.module) {
              this.$warning({ message: 'You can only access Passenger Portal.' })
            }
            return
          }

          if (to.meta.module !== appModule.OPERATIONAL && guard.hasOnlyOperationalAccess) {
            next?.({ name: 'events' })
            if (to.meta.module) {
              this.$warning({ message: 'You have only access to TMM module.' })
            }
            return
          }
        }

        if (to?.params?.eventSlug) {
          this.checkEventAccessAndRedirect(from, to, this.$store.state.session.user, next)
        } else {
          logger.success('success')
          if (next) {
            document.title = `${to.meta.title} - FIFA Transport`
            next()
          }
        }
      }
    },
    signOutAndGo(from, to, next) {
      debugLogger.info('will log out a user')

      this.signOut()
        .then(() => {
          logger.success('signed out')
          if (next) {
            document.title = `${to.meta.title} - FIFA Transport`
            next()
          }
        })
        .catch(() => {
          logger.fail("can't sign out the user")

          if (next) {
            if (from?.matched?.length) {
              next(false)
            } else {
              document.title = `FIFA Transport`
              next('/')
            }
          } else {
            this.$router.push('/')
          }
        })
    },
    checkEventAccessAndRedirect(from, to, user, next) {
      const eou = getEventOrganizationUser(user, to.params.eventSlug)
      const ou = user.impersonatedCurrentOrganizationUser || user.currentOrganizationUser
      const guard = eventModuleGuard(eou, ou)

      const isToPassengerModule = to.meta.module === appModule.PASSENGER

      let result = false

      if (isToPassengerModule) {
        result = guard.hasPassengerAccess
      } else if (guard.hasOnlyPassengerAccess && !isToPassengerModule) {
        next({ name: 'passenger-portal' })
        this.$warning({ message: 'You can only access this event through Passenger Portal.' })
        return
      } else {
        result = checkAccessToEventRouteByUser(user, to, true)
      }

      // debugLogger.info('checkAccessToEventRouteByUser?', result)

      if (result) {
        logger.success('Access granted', to.path)
        if (next) {
          document.title = `${to.meta.title} - FIFA Transport`
          // if (to.query?.perm_id) {
          //   const newQuery = { ...to.query }
          //   delete newQuery.perm_id
          //   debugLogger.info(
          //     'PROVIDED SPECIFIC PERM ID, will set and redirect to path skipping the `perm_id` param',
          //   )
          //   next({
          //     path: to.path,
          //     hash: to.hash,
          //     query: newQuery,
          //     replace: true,
          //   })
          // } else {
          //   next()
          // }
          next()
        }
      } else {
        logger.fail('no access!', to.path)
        this.$error({ message: 'You have no access to this page' })
        if (next) {
          if (from && from.matched?.length) {
            next(false)
          } else {
            if (to.meta.module === appModule.PASSENGER) {
              if (eou) {
                next(`/passenger-portal/events/${to.params.eventSlug}/dashboard`)
              } else {
                next('/passenger-portal/events')
              }
            } else {
              if (eou) {
                next(`/events/${to.params.eventSlug}/dashboard`)
              } else {
                next('/events')
              }
            }
          }
          return
        } else {
          this.$router.push('/')
          return
        }
      }
    },
    async handleCurrentContextReload(to, from, next) {
      if (this.$route.meta.module === appModule.EXTERNAL) return

      const { eventSlug, clusterSlug } = to.params

      await this.$store.dispatch('currentContext/fetchEvent', { slug: eventSlug })
      await this.$store.dispatch('currentContext/fetchCluster', { slug: clusterSlug })

      next?.()
    },
    // private
    shouldLogOut(auth) {
      return auth === 'logoutAndGo' && this.currentUser
    },
  },
}

class Logger {
  constructor(
    { promptStyle = 'background: #000; color: #ffffb3; margin-right: 5px', level = 'log' } = {
      promptStyle: 'background: #000; color: #ffffb3; margin-right: 5px',
      level: 'log',
    },
  ) {
    this.promptStyle = promptStyle
    this.level = level
  }

  success(...params) {
    this.log(
      ['%cAuthorization Check%c[Success]', this.promptStyle, 'background: #000; color: lightgreen'],
      ...params,
    )
  }

  fail(...params) {
    this.log(
      ['%cAuthorization Check%c[Fail]', this.promptStyle, 'background: #000; color: orange'],
      ...params,
    )
  }

  info(...params) {
    this.log(
      ['%cAuthorization Check%c[Info]', this.promptStyle, 'background: #000; color: lightblue'],
      ...params,
    )
  }
  log(header, ...params) {
    if (this.level === 'debug' && import.meta.env.MODE === 'development') {
      console[this.level](...header, ...params)
    }
  }
}

const logger = new Logger({ level: 'log' })
const debugLogger = new Logger({ level: 'debug' })
