import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import {
  COLUMNS_CONFIG,
  DEFAULT_COLUMNS,
  FIELDS,
  SUBSCRIPTIONS,
} from 'frontend/transport-bookings/transport-bookings-table-helpers'

export const TransportBookingDataModelTypeConfig: IDataModelTypeConfig = {
  fields: FIELDS,
  subscriptions: SUBSCRIPTIONS,
  defaultColumns: DEFAULT_COLUMNS,
  columnConfig: COLUMNS_CONFIG,
}
