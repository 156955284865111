<script>
import {
  checkAccessToEventRoute,
  getEventOrganizationUser,
  isAdmin,
} from 'frontend/common/access-helpers'
import { useSettingsSection } from 'frontend/events/event-dashboard-item-sections/settings'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import SidebarItem from './sidebar-item.vue'

export default {
  name: 'MainSidebar',
  setup() {
    const $store = useStore()

    const slug = computed(() => {
      return useRoute().params.eventSlug
    })

    const eou = computed(() => {
      return getEventOrganizationUser($store.state?.session?.user, slug.value)
    })

    return {
      settingsSection: useSettingsSection(eou, $store.state?.session?.user),
      slug,
      eou,
    }
  },
  computed: {
    hasAccessToSettings() {
      return !this.settingsSection.isHidden && (this.settingsSection.items?.length || 0) > 0
    },
    sidebarItems() {
      const navItems = [
        {
          link: `/events/${this.slug}/dispatcher-screen`,
          iconClasses: 'bi-bar-chart-steps',
          subscript: 'D',
          title: 'Dispatcher',
          sectionName: 'Operational Modules',
        },
        {
          link: `/events/${this.slug}/shifts-manager-screen`,
          iconClasses: 'bi-columns-gap',
          subscript: 'SM',
          title: 'Shifts Manager',
          sectionName: 'Operational Modules',
        },
        {
          link: `/events/${this.slug}/live-panel`,
          iconClasses: 'fas fa-location-arrow',
          subscript: 'LP',
          title: 'Live Panel',
          sectionName: 'Operational Modules',
        },
        {
          link: `/events/${this.slug}/client-communication-messages`,
          iconClasses: 'bi-envelope',
          subscript: 'CCM',
          title: 'Client Communication Messages',
          sectionName: 'Operational Modules',
        },
        {
          link: `/events/${this.slug}/transport-bookings`,
          iconClasses: 'bi-bookmarks',
          subscript: 'TB',
          title: 'Transport Bookings',
          sectionName: 'Demand',
        },
        // {
        //   link: `/events/${this.slug}/batch-onsite-requests`,
        //   iconClasses: 'bi-bookmarks',
        //   subscript: 'BOR',
        //   title: 'Batch On-Site Requests',
        //   sectionName: 'Demand',
        // },
        {
          link: `/events/${this.slug}/all-demands`,
          iconClasses: 'bi-clipboard',
          subscript: 'AD',
          title: 'All Demands',
          sectionName: 'Demand',
        },
        {
          link: `/events/${this.slug}/shift-jobs`,
          iconClasses: 'bi-clipboard',
          subscript: 'SJ',
          title: 'Shift Jobs',
          sectionName: 'Demand',
        },
        {
          link: `/events/${this.slug}/transfer-groups`,
          iconClasses: 'bi-grid-3x3-gap',
          subscript: 'TG',
          title: 'Transfer Groups',
          sectionName: 'Demand',
        },
        {
          link: `/events/${this.slug}/shuttle-services`,
          iconClasses: 'fas fa-bus-alt',
          subscript: 'SS',
          title: 'Shuttle Services',
          sectionName: 'Demand',
        },
        {
          link: `/events/${this.slug}/shuttle-transfers`,
          iconClasses: 'fas fa-bus-alt',
          subscript: 'ST',
          title: 'Shuttle Transfers',
          sectionName: 'Demand',
        },
        {
          link: `/events/${this.slug}/vehicles`,
          iconClasses: 'bi-truck',
          subscript: 'V',
          title: 'Vehicles',
          sectionName: 'Resources',
        },
        {
          link: `/events/${this.slug}/drivers`,
          iconClasses: 'bi-person-badge',
          subscript: 'D',
          title: 'Drivers',
          sectionName: 'Resources',
        },
        {
          link: `/events/${this.slug}/clients`,
          iconClasses: 'bi-people',
          subscript: 'C',
          title: 'Clients',
          sectionName: 'Resources',
        },
        {
          link: `/events/${this.slug}/shifts`,
          iconClasses: 'bi-ui-checks',
          subscript: 'S',
          title: 'Shifts',
          sectionName: 'Resources',
        },
        {
          link: `/events/${this.slug}/clusters`,
          iconClasses: 'bi-grid',
          subscript: 'C',
          title: 'Clusters',
          sectionName: 'Places',
        },
        {
          link: `/events/${this.slug}/locations`,
          iconClasses: 'bi-grid',
          subscript: 'L',
          title: 'Locations',
          sectionName: 'Places',
        },
        {
          link: `/events/${this.slug}/transportation-points`,
          iconClasses: 'bi-grid',
          subscript: 'TP',
          title: 'Transportation Points',
          sectionName: 'Places',
        },
        {
          link: `/events/${this.slug}/staging-areas`,
          iconClasses: 'bi-grid',
          subscript: 'SA',
          title: 'Staging Areas',
          sectionName: 'Places',
        },
        {
          link: `/events/${this.slug}/depots`,
          iconClasses: 'bi-grid',
          subscript: 'D',
          title: 'Depots',
          sectionName: 'Places',
        },
      ]

      return navItems.filter(el => {
        // console.log('this.eou?.role?.enabledViewTypes', this.eou?.role?.enabledViewTypes, path, this.eou)
        return checkAccessToEventRoute(this.eou, el.link, this.slug)
      })
    },
    isAdmin() {
      return this.eou && isAdmin(this.eou)
    },
  },
  methods: {
    isFromNextSection(navItem, previousNavItem) {
      return previousNavItem && navItem.sectionName !== previousNavItem?.sectionName
    },
    marginIfNextSection(navItem, previousNavItem) {
      return this.isFromNextSection(navItem, previousNavItem) ? 'mt-3' : ''
    },
    openDashboardSettings() {
      this.$router.push({
        name: 'event-dashboard',
        query: { searchTerm: 'Settings' },
      })
    },
  },
  components: {
    SidebarItem,
  },
}
</script>

<template lang="pug">
.sidebar.d-flex.flex-column.justify-content-between
  .d-flex.flex-column.h-100
    ul.nav.d-flex.flex-column.mb-4.flex-shrink-0.flex-nowrap
      sidebar-item(alaIconTitle="EVE" link="/events" title="Events")

    template(v-if="slug && eou")
      ul.nav.d-flex.flex-column.flex-shrink-0.flex-nowrap
        sidebar-item.mb-4(
          :link="`/events/${$route.params.eventSlug}/dashboard`"
          iconClasses="bi-sliders"
          title="Dashboard"
        )

        template(v-for="(item, index) of sidebarItems" :key="index")
          sidebar-item(
            :class="`${item.class || ''} ${marginIfNextSection(item, sidebarItems[index - 1])}`"
            :iconClasses="item.iconClasses"
            :link="item.link"
            :subscript="item.subscript"
            :title="item.title"
          )

      ul.nav.d-flex.flex-column.flex-shrink-0.flex-nowrap.sidebar-settings(
        v-if="hasAccessToSettings"
        @click="openDashboardSettings"
      )
        sidebar-item(
          :link="`/events/${$route.params.eventSlug}/dashboard?searchTerm=Settings`"
          iconClasses="bi-gear"
          title="Settings"
        )
</template>
