import { defineAsyncComponent } from 'vue'

const TransportBooking = defineAsyncComponent(() => import('./transport-booking.vue'))
const TransportBookingsTable = defineAsyncComponent(() => import('./transport-bookings-table.vue'))
const TransportBookingProvidedStamp = defineAsyncComponent(() =>
  import('./transport-booking-provided-stamp.vue'),
)
const TransportBookingTime = defineAsyncComponent(() => import('./transport-booking-time.vue'))
const TransportBookingTimeRange = defineAsyncComponent(() =>
  import('./transport-booking-time-range.vue'),
)
const TransportBookingOsl = defineAsyncComponent(() => import('./transport-booking-osl.vue'))
const TransportBookingPassengersList = defineAsyncComponent(() =>
  import('./transport-booking-passengers-list.vue'),
)
const TransportBookingCoPassengersList = defineAsyncComponent(() =>
  import('./transport-booking-co-passengers-list.vue'),
)

const KINDS = [
  { value: 'onsite', label: 'On-site' },
  { value: 'arrival', label: 'Arrival' },
  { value: 'departure', label: 'Departure' },
]

const GROUPING_CAUSES = {
  batch_onsite: 'Batch Onsite Request',
  companion: 'Companion',
  custom_tb: 'Custom Request - no grouping',
  first_in_group: 'First in group',
  manual: 'Manual',
  rule: 'Grouping Rule',
}

const EXTENDED_KINDS = [
  { value: 'onsite', label: 'On-site' },
  { value: 'batch_onsite', label: 'Batch On-site' },
  { value: 'custom_batch_onsite', label: 'Custom Batch On-site' },
  { value: 'custom_onsite', label: 'Custom On-site' },
  { value: 'arrival', label: 'Arrival' },
  { value: 'departure', label: 'Departure' },
]

const EXTENDED_KINDS_BY_VALUE = EXTENDED_KINDS.reduce(
  (result, el) => ({
    ...result,
    [el.value]: el.label,
  }),
  {},
)

const PROVIDED_STAMPS = [
  {
    value: 'start_at',
    label: 'Pick-up / Arrival time',
    onsiteLabel: 'Pick-up',
    arrivalLabel: 'Arrival time',
    departureLabel: '???',
  },
  {
    value: 'end_at',
    label: 'Drop-off / Departure time',
    onsiteLabel: 'Drop-off',
    arrivalLabel: '???',
    departureLabel: 'Departure time',
  },
]

export {
  EXTENDED_KINDS,
  EXTENDED_KINDS_BY_VALUE,
  GROUPING_CAUSES,
  KINDS,
  PROVIDED_STAMPS,
  TransportBooking,
  TransportBookingCoPassengersList,
  TransportBookingOsl,
  TransportBookingPassengersList,
  TransportBookingProvidedStamp,
  TransportBookingsTable,
  TransportBookingTime,
  TransportBookingTimeRange,
}
