<script setup lang="ts">
import { sanitizer } from 'frontend/_config/sanitize-html'
import { UsedCriteriaOutlineColorType } from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaOutlineColorType'
import { computed } from 'vue'

interface Props {
  outlineColorType: UsedCriteriaOutlineColorType
  humanizedPredicate: string
  showPredicate?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  outlineColorType: UsedCriteriaOutlineColorType.Positive,
  humanizedPredicate: null,
  showPredicate: true,
})

const sanitizedPredicate = computed(() => {
  return sanitizer.process(props.humanizedPredicate)
})
</script>

<template>
  <div class="used-criteria-preview-value-wrapper" :class="`type-${props.outlineColorType}`">
    <span
      v-if="showPredicate"
      class="px-1 predicate"
      :class="`type-${props.outlineColorType}`"
      v-html="sanitizedPredicate"
    />
    <span class="value">
      <slot />
    </span>
  </div>
</template>

<style scoped lang="scss">
.used-criteria-preview-value-wrapper {
  display: flex;
  //border: 1px solid;
  //border-radius: 8px;
  //padding: 4px;
  align-items: flex-start;
  //white-space: nowrap;
  //gap: 8px;

  //&.type-negative {
  //  color: #FF881A;
  //}
  //
  //&.type-positive {
  //  color: #6FCF97;
  //}
  //
  //&.type-falsy {
  //  color: #FF7875;
  //  //color: #FF881A;
  //}
}

.predicate {
  //display: flex;
  //align-items: center;
  //border: 1px solid;
  //border-radius: 4px;
  //padding: 0;
  //width: 104px;
  //justify-content: center;
  white-space: nowrap;
  align-self: center;
  //text-transform: uppercase;
  //font-size: 12px;
  //font-weight: 600;
  //color: inherit;
}

.value {
  //min-height: 18px;
  //display: flex;
  //align-items: center;
  //gap: 4px;
  //flex-wrap: wrap;
  //max-width: 420px;
}
</style>
