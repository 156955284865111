<script>
export default {
  name: 'EaCheckbox',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    name: { type: String, required: true },
    label: { type: String, default: null },
    value: { type: [String, Number] },
    modelValue: { type: [String, Number, Array, Boolean] },
    validators: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
}
</script>

<template lang="pug">
form-control-container(
  :additional-errors="additionalErrors"
  :validators="validators"
  :value="modelValue"
)
  template(#default)
    ea-simple-checkbox(
      v-bind="$attrs"
      :disabled="disabled"
      :label="label"
      :modelValue="modelValue"
      :name="name"
      :value="value"
      @update:modelValue="$emit('update:modelValue', $event)"
    )
</template>
