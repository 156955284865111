import { sanitizeUrl } from '@braintree/sanitize-url'
import { watchDebounced } from '@vueuse/core'
import { AxiosInstance } from 'axios'
import CancelToken from 'axios/lib/cancel/CancelToken'
import consumer from 'channels/consumer'
import { camelResponse } from 'frontend/_config/case-converter'
import { sanitizer } from 'frontend/_config/sanitize-html'
import computeSchema from 'frontend/_globals/compute-schema'
import { useCancellable } from 'frontend/_hooks/use-cancellable'
import { InterfaceType } from 'frontend/_setup/setupContainer/constants/InterfaceType'
import { ToastAction } from 'frontend/_stores/toast-store'
import { Globals } from 'frontend/_types/globals'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'
import { useContainer } from 'frontend/container/composables/useContainer'
import { useCustomizableTableColumns } from 'frontend/dataModels/composables/useCustomizableTableColumns'
import { useDataModelReport } from 'frontend/dataModels/composables/useDataModelReport'
import { IDataModelTypeService } from 'frontend/dataModels/services/DataModelTypeService/types/IDataModelTypeService'
import { DataFields } from 'frontend/dataModels/types/DataFields'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import {
  camelCase,
  capitalize,
  concat,
  each,
  findIndex,
  flatten,
  intersection,
  mapValues,
  orderBy,
  snakeCase,
  uniq,
} from 'lodash'
import pluralize from 'pluralize'
import { computed, inject, isRef, nextTick, onUnmounted, provide, type Ref, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { useBlocker } from './use-blocker'

function sanityCheck({ defaultSort, fields, path }) {
  if (!defaultSort) {
    throw new Error('Please provide "defaultSort" key for resourcePaginatedList composition!')
  }
  if (!fields) {
    throw new Error('Please provide "fields" key for resourcePaginatedList composition!')
  }
  if (!path) {
    throw new Error('Please provide "path" key for resourcePaginatedList composition!')
  }
}

function resolveBlocker({ blocker, blockerItemsName }) {
  if (blocker) {
    blocker[blockerItemsName] = false
    return ref(blocker)
  } else {
    const { blocker: _blocker } = useBlocker()
    return ref(_blocker)
  }
}

export type ResourcePaginatedListParams = {
  dataModelType?: DataModelType
  defaultSort?: string | Array<string>
  componentName?: string
  prefilters?: Record<string, unknown>
  initialFilters?: Record<string, unknown>
  standalone?: boolean
  subscriptionParams?: Record<string, unknown>
  reflectRouteQuery?: boolean
  injectPrefiltersInRouteQuery?: boolean
  verboseSubscriptions?: boolean
  checkAndInjectOnCreate?: boolean
  instantDelete?: boolean
  prefix?: string
  blocker?: unknown
  appendData?: boolean
  instantCreate?: boolean
  propagateErrors?: boolean
  onCreatedMessage?: (key: string, id: string, result, list, schema) => void
  onUpdatedMessage?: (key: string, id: string, result, list, schema) => void
  onDeletedMessage?: (key: string, id: string, result, list, schema) => void
  onBeforeMessage?: (key: string, id: string, result, list, schema) => void
  page?: number
  perPage?: number
  // TODO: fix any type
  transformDefaultPreset?: (preset: any) => Record<string, unknown>
  path?: string
  fields?: DataFields
  subscriptions?: Record<string, unknown>
  availableColumns?: Record<string, unknown>
  disableCustomizableColumns?: boolean
  defaultDisplayedColumnNames?: string[]
  forceProvidedDefaultColumnsOnInit?: boolean
  exportableType?: string
}

function useResourcePaginatedList({
  dataModelType,
  defaultSort,
  componentName = null,
  prefilters = {},
  initialFilters = null,
  standalone = false,
  subscriptionParams = {},
  reflectRouteQuery = false,
  injectPrefiltersInRouteQuery = false,
  verboseSubscriptions = false,
  checkAndInjectOnCreate = false,
  instantDelete = false,
  prefix = null,
  blocker = null,
  appendData = false,
  instantCreate = false,
  propagateErrors = false,
  onCreatedMessage = null,
  onUpdatedMessage = null,
  onDeletedMessage = null,
  onBeforeMessage = null,
  page: initialPage = null,
  perPage: initialPerPage = null,
  transformDefaultPreset = v => v,
  path: providedPath = null,
  fields: providedFields,
  subscriptions: providedSubscriptions = null,
  availableColumns: providedAvailableColumns = null,
  disableCustomizableColumns = false,
  defaultDisplayedColumnNames: providedDefaultDisplayedColumnNames = null,
  forceProvidedDefaultColumnsOnInit = false,
  exportableType: providedExportableType = null,
}: ResourcePaginatedListParams) {
  const { container } = useContainer()
  const dataModelTypeService = container.get<IDataModelTypeService>(
    InterfaceType.services.DataModelTypeService,
  )

  const subscriptions =
    providedSubscriptions || dataModelTypeService.getSubscriptions(dataModelType)
  const path = providedPath || `${dataModelTypeService.getBaseUrl(dataModelType)}/index`
  const fields = providedFields || dataModelTypeService.getFields(dataModelType)

  sanityCheck({ defaultSort, fields, path })

  const subscriptionData = {}
  let firstLoad = true

  const axios = inject<AxiosInstance>('axios')
  const $error = inject<ToastAction>('$error')
  const $globals = inject<Globals>('$globals')
  const $store = useStore()
  const $router = useRouter()
  const $route = useRoute()
  const localPrefilters: Ref<Record<string, unknown>> = isRef(prefilters)
    ? (prefilters as Ref<Record<string, unknown>>)
    : ref<Record<string, unknown>>(prefilters)
  const initialPreset = ref(null)

  const requestPath = ref(path)

  const page = ref(initialPage)
  const perPage = ref(initialPerPage)
  const sort = ref<string | string[]>(defaultSort)
  const filters = ref({})
  const fetchedAll = ref(false)
  const blockerItemsName = prefix || 'items'
  const usedBlocker = resolveBlocker({ blocker, blockerItemsName })
  const search = ref('')
  const isSearchEnabled = computed(() => !!dataModelTypeService.getSearchKey(dataModelType))
  const list = ref({ result: { items: [] }, entities: {}, mappings: {} })
  const batchActions = ref(null)
  const tableScroller = ref(null)
  const total = ref(null)

  let customizableColumnsComposable: any = {}
  if (!disableCustomizableColumns && dataModelType?.length) {
    customizableColumnsComposable = useCustomizableTableColumns(dataModelType, {
      defaultDisplayedColumnNames: providedDefaultDisplayedColumnNames,
      availableColumns: providedAvailableColumns,
      forceProvidedDefaultColumnsOnInit,
      defaultSort: defaultSort,
    })
  }

  const {
    defaultDisplayedColumnNames,
    availableColumns,
    displayedColumnNames,
    areColumnPresetsLoading,
    columnPresets,
    isPresetUnsaved,
    saveCurrentColumnPreset,
    currentColumnPresetId,
    sortItems,
    isMultiSortEnabled,
    toggleSort,
    unsavedColumnPresetIds,
    saveColumnPreset,
    restoreColumnPreset,
    columnWidthsMap,
    fetchColumnPresets,
    currentPresetName,
  } = customizableColumnsComposable

  const { exportableType, generateReport } = useDataModelReport(
    dataModelType,
    displayedColumnNames,
    providedExportableType,
  )

  if (!disableCustomizableColumns && dataModelType?.length) {
    watch(sortItems, sortItems => {
      sort.value = sortItems?.length ? mapSortItemsToSort(sortItems) : defaultSort
      fetchItems()
    })
  }

  function mapSortItemsToSort(sortItems: IColumnSortItems): string | string[] {
    let items = (sortItems || []).map(sortItem => {
      if (sortItem.constructor == String) {
        return sortItem
      } else {
        let targetSortKey: any = sortItem[0]
        const foundColumn = Object.entries(availableColumns.value).find(([key]) => {
          return key === targetSortKey
        })?.[1]

        if (foundColumn?.header?.sort) {
          targetSortKey = foundColumn.header.sort
        }
        if (targetSortKey.constructor == String) {
          return `${snakeCase(targetSortKey)} ${sortItem[1]}`
        } else {
          return targetSortKey.map(el => `${snakeCase(el)} ${sortItem[1]}`)
        }
      }
    })

    items = flatten(items)

    if (!items.length) {
      return ''
    }
    if (items.length === 1) {
      return items[0]
    }
    return items
  }

  watch(filters, () => {
    page.value = 1
  })

  const previouslySearchedFor = ref('')
  watchDebounced(
    search,
    () => {
      if (previouslySearchedFor.value !== search.value) {
        page.value = 1
        doFetchItems()
        previouslySearchedFor.value = search.value
      }
    },
    {
      debounce: 1000,
    },
  )

  const cancellable = useCancellable().cancellable

  const searchPerformed = ref(false)
  provide('searchPerformed', searchPerformed)

  const schema = computed(() => {
    if (!Object.keys(list.value.mappings || {}).length) {
      return computeSchema([], {})
    } else {
      return computeSchema(fields, list.value.mappings)
    }
  })

  const eventOrganizationUser = computed(() =>
    $store.getters['session/eventOrganizationUser']($route.params.eventSlug),
  )

  const event = computed(() => $store.getters['currentContext/currentEvent'])

  const eouDefaultFiltersObject = computed(() => {
    const camelCasedComponentName = camelCase(componentName)

    const defaultFilter = transformDefaultPreset(
      eventOrganizationUser.value?.filterConfig?.[camelCasedComponentName]?.find(el => el?.default),
    )
    return defaultFilter
  })

  const eouDefaultFilters = computed(() => {
    return eouDefaultFiltersObject.value?.value
  })

  const globalDefaultFiltersObject = computed(() => {
    const camelCasedComponentName = camelCase(componentName)
    const defaultGlobalFilter = transformDefaultPreset(
      event.value?.globalFilterConfig?.[camelCasedComponentName]?.find(el => el?.default),
    )
    return defaultGlobalFilter
  })

  const globalDefaultFilters = computed(() => {
    return globalDefaultFiltersObject.value?.value
  })

  const anyFilterSet = computed(() => {
    return !!Object.values(filters.value).filter(val => !!val || val == false || val == 0).length
  })

  const isBlocked = computed(() => {
    return Object.values(usedBlocker.value).indexOf(true) != -1 || areColumnPresetsLoading?.value
  })

  const clearFilters = () => {
    filters.value = mapValues(filters.value, () => null)
    page.value = 1
    doFetchItems()
  }

  const fetchItems = ({ immediate = false, scrollToTop = false } = {}) => {
    if (immediate) {
      return doFetchItems({ scrollToTop })
    } else {
      return nextTick(() => doFetchItems({ scrollToTop }))
    }
  }

  const doFetchItems = ({ scrollToTop = false } = {}) => {
    if (columnPresets && !columnPresets.value) {
      return Promise.resolve()
    }
    if (!perPage.value) {
      console.warn('perPage not specified, omitting index fetch from', requestPath.value)
      return Promise.resolve()
    }

    if (firstLoad && initialFilters) {
      filters.value = { ...initialFilters }
      initialPreset.value = initialFilters
      firstLoad = false
    } else if (
      firstLoad &&
      standalone &&
      (!reflectRouteQuery || !Object.keys($route.query || {})?.length) &&
      (!!eouDefaultFilters.value || !!globalDefaultFilters.value)
    ) {
      const newFilters = eouDefaultFiltersObject.value || globalDefaultFiltersObject.value
      filters.value = { ...newFilters.value }
      initialPreset.value = newFilters
      firstLoad = false
    }
    if (appendData && fetchedAll.value) return
    const anyFilterSet = Object.keys(filters.value).find(k => {
      if ([String, Array].includes(filters.value[k]?.constructor)) {
        return filters.value[k].length
      } else if (filters.value[k]?.constructor == 'Boolean') {
        return [true, false].includes(filters.value[k])
      } else {
        return !!filters.value[k]
      }
    })
    searchPerformed.value = !!anyFilterSet

    updateRouteQuery()

    usedBlocker.value[blockerItemsName] = true

    const columnConfig = dataModelType?.length
      ? dataModelTypeService.getColumnConfig(dataModelType)
      : {}
    // const finalSortValue = sortItems?.value?.map(([key, direction]) => {
    //   return [snakeCase(columnConfig[key]?.header?.sort || key), direction].join(' ')
    // })

    const payload = {
      ...localPrefilters.value,
      ...filters.value,
      sorts: sort?.value?.length ? sort.value : defaultSort,
      fields,
      limit: perPage.value,
      offset: Math.max(0, page.value - 1) * perPage.value,
    }
    if (search.value) {
      payload['lower_name_cont'] = search.value
    }
    return axios({
      method: 'post',
      url: requestPath.value,
      data: payload,
      cancelToken: new CancelToken(cancel => {
        cancellable.value[requestPath.value] = cancel
      }),
    })
      .then(response => {
        if (appendData && page.value > 1) {
          handleAppendPage(response)
        } else {
          list.value.result = response.data.result
          list.value.entities = response.data.entities
          list.value.mappings = response.data.mappings
          if (scrollToTop) {
            $globals.scrollToElement(tableScroller.value?.$el)
          }
        }
        total.value = response.data.totalElements
        runSubscriptions()
        fetchedAll.value =
          appendData && total.value && total.value <= list.value.result.items.length
        return response
      })
      .catch(error => {
        if (error.cancelled) return
        console.error(error)
        $error({ message: "Can't load items" })
        if (propagateErrors) {
          throw error
        }
      })
      .finally(() => {
        usedBlocker.value[blockerItemsName] = false
      })
  }

  const updateRouteQuery = () => {
    if (reflectRouteQuery) {
      const searchParams: {
        page?: number
        perPage?: number
        sorts?: string | Array<string>
      } = {}

      if (injectPrefiltersInRouteQuery && Object.keys(localPrefilters.value || {}).length) {
        searchParams['prefilters'] = JSON.stringify(localPrefilters.value)
      }

      Object.keys(filters.value || {}).forEach(k => {
        if (filters.value[k] != null) {
          if (k === 'g' && filters.value[k]?.constructor === Array) {
            searchParams[k] = JSON.stringify(filters.value[k])
          } else {
            searchParams[k] = filters.value[k]
          }
        }
      })

      if (page.value != 1) {
        searchParams.page = page.value
      }
      if (perPage.value) {
        searchParams.perPage = perPage.value
      }
      if (sort.value != defaultSort) {
        searchParams.sorts = sort.value
      }

      $router.replace({ query: searchParams })
    }
  }

  const handleAppendPage = ({ data }) => {
    list.value.result.items = Array.from(
      new Set([...list.value.result.items, ...data.result.items]),
    )

    Object.keys(data.entities).forEach(key => {
      const oldEntities = list.value.entities[key]
      const newEntities = data.entities[key]
      Object.keys(newEntities).forEach(k => {
        oldEntities[k] = newEntities[k]
      })
    })
  }

  let shouldRefetchOnMainConnection = false

  const subscribe = ({
    payload,
    key = 'items',
    verbose = false,
    checkAndInjectOnCreate = false,
    instantDelete = false,
  }) => {
    if (!payload?.channel) {
      console.error('please provide payload=>channel key for subscription!')
      return
    }
    const customIdentifier = Math.round(Math.random() * 1000000)
    return consumer.subscriptions.create(
      { ...payload, customIdentifier },
      {
        customIdentifier,
        connected() {
          if (verbose) console.log(`%c CONNECTED TO ${payload?.channel}`, 'color: #4c74b9')
          if (shouldRefetchOnMainConnection && key == 'items') {
            shouldRefetchOnMainConnection = false
            if (verbose) {
              console.log(
                `%c WILL REFETCH MAIN RESOURCES BECAUSE OF RECONNECTION ${payload?.channel}`,
                'color: #4c74b9',
              )
            }

            if (appendData) {
              page.value = 1
              fetchedAll.value = false
            }
            doFetchItems({ scrollToTop: true })
          }
        },
        disconnected() {
          shouldRefetchOnMainConnection = true
          if (verbose) console.warn(`DISCONNECTED FROM ${payload?.channel}`)
        },
        rejected() {
          console.error(`REJECTED CONNECTION TO ${payload?.channel}`)
        },
        received: message => {
          if (verbose) console.log(`%c ${payload.channel} message came in:`, 'color: gray', message)
          onBeforeMessage?.(key, message.id, message, list, schema)

          const itemIsPresentInEntities = Object.keys(list.value.entities[key] || {}).includes(
            message.id,
          )
          if (itemIsPresentInEntities) {
            if (verbose)
              console.log(
                `%c ${payload.channel} message object is present on the proper entities list`,
                'color: gray',
                key,
                message.id,
              )
            if (message.action == 'delete') {
              if (instantDelete && key == 'items') {
                if (verbose)
                  console.log(
                    `%c ${payload.channel} message is to delete, will remove the item`,
                    'color: gray',
                  )
                const i = list.value.result.items.indexOf(message.id)
                if (i > -1) {
                  list.value.result.items.splice(i, 1)
                }
                delete list.value.entities[key][message.id]
              } else {
                if (verbose)
                  console.log(
                    `%c ${payload.channel} message is to delete, will mark as deleted`,
                    'color: gray',
                  )
                list.value.entities[key][message.id]._deleted = true
              }

              let deleteResult = null
              try {
                deleteResult = camelResponse(JSON.parse(message.obj))
              } catch (e) {
                console.error(
                  `[${componentName} - ${payload?.channel}] Unable to parse message of onDeletedMessage:`,
                  e,
                )
              }
              onDeletedMessage?.(key, message.id, deleteResult, list, schema)
              return
            }
            const result = camelResponse(JSON.parse(message.obj))
            if (verbose) {
              console.log(
                `%c ${payload.channel} message is to create/update/touch, will update the item`,
                'color: gray',
                result,
              )
            }

            if (message.action === 'create') {
              onCreatedMessage?.(key, message.id, result, list, schema)
            } else if (message.action === 'update' || message.action === 'touch') {
              onUpdatedMessage?.(key, message.id, result, list, schema)
            }

            orderBy(Object.keys(result.entities), k => (k == 'items' ? 2 : 1)).forEach(
              collectionKey => {
                const properCollectionKey = collectionKey == 'items' ? key : collectionKey
                Object.keys(result.entities[collectionKey] || {}).forEach(id => {
                  list.value.entities[properCollectionKey][id] = result.entities[collectionKey][id]
                })
              },
            )
          } else if (instantCreate && message.action == 'create') {
            const result = camelResponse(JSON.parse(message.obj))

            if (verbose)
              console.log(`%c ${payload.channel} instant creating`, 'color: gray', result)
            orderBy(Object.keys(result.entities), k => (k == 'items' ? 2 : 1)).forEach(
              collectionKey => {
                const properCollectionKey = collectionKey == 'items' ? key : collectionKey
                Object.keys(result.entities[collectionKey] || {}).forEach(id => {
                  list.value.entities[properCollectionKey][id] = result.entities[collectionKey][id]
                })
              },
            )
            list.value.result.items.push(message.id)
            onCreatedMessage?.(key, message.id, result, list, schema)
          } else if (
            checkAndInjectOnCreate &&
            ['create', 'update', 'touch'].includes(message.action) &&
            key == 'items'
          ) {
            const payload = {
              ...localPrefilters.value,
              ...filters.value,
              sorts: sort.value || defaultSort,
              id: message.id,
            }
            axios({
              method: 'post',
              url: `${requestPath.value}_ids`,
              data: payload,
              cancelToken: new CancelToken(cancel => {
                cancellable.value[`${requestPath.value}_ids`] = cancel
              }),
            })
              .then(response => {
                if (response.data?.ids?.length) {
                  // handling list entities node
                  const result = camelResponse(JSON.parse(message.obj))
                  orderBy(Object.keys(result.entities), k => (k == 'items' ? 2 : 1)).forEach(
                    collectionKey => {
                      const properCollectionKey = collectionKey == 'items' ? key : collectionKey
                      Object.keys(result.entities[collectionKey] || {}).forEach(id => {
                        list.value.entities[properCollectionKey][id] =
                          result.entities[collectionKey][id]
                      })
                    },
                  )
                  // handling list result node (ids)
                  const tmpList = [...(list.value.result.items || [])]
                  tmpList.push(message.id)
                  list.value.result.items = intersection(response.data.ids, tmpList)
                  // handling total value
                  total.value += 1
                  if (list.value.result.items?.includes(message.id)) {
                    onCreatedMessage?.(key, message.id, result, list, schema)
                  }
                }
              })
              .catch(error => {
                if (error.cancelled) return
                console.error(error)
                $error({ message: "Can't show newly created item" })
              })
          }
        },
      },
    )
  }

  const findMappingsFor = resourceName => {
    const result = []
    const snakedPluralResourceName = snakeCase(pluralize(resourceName))
    each(list.value.mappings, (v, k) => {
      if (v == snakedPluralResourceName) {
        result.push(k)
      }
    })
    return result
  }

  const findSubfieldsFor = (mappings, fieldsNode = fields) => {
    let result = []
    if (fieldsNode.filter) {
      const entityFields = fieldsNode.filter(item => item.constructor == Object)
      entityFields.forEach(item => {
        const entityKey = Object.keys(item)[0]
        if (mappings.includes(entityKey)) {
          result.push(item[entityKey])
        } else {
          const subresult = findSubfieldsFor(mappings, item[entityKey])
          if (subresult.constructor == Array && (subresult[0] || '').constructor == Array) {
            result = [...result, ...subresult]
          } else {
            result.push(subresult)
          }
        }
      })
    }
    return result
  }

  const mergeSubfields = subfields => {
    let result = []
    if (subfields.length == 1) {
      return subfields[0]
    }
    subfields.forEach(subfieldsNode => {
      const entityFields = subfieldsNode.filter(item => item.constructor == Object)
      const notEntityFields = subfieldsNode.filter(item => item.constructor != Object)
      result = uniq(concat(result, notEntityFields))
      entityFields.forEach(entityField => {
        const entityKey = Object.keys(entityField)[0]
        const i = findIndex(
          result,
          el => el.constructor == Object && Object.keys(el)[0] == entityKey,
        )
        if (i > -1) {
          const subresult = {}
          subresult[entityKey] = mergeSubfields([result[i][entityKey], entityField[entityKey]])
          result[i] = subresult
        } else {
          result.push(entityField)
        }
      })
    })

    return result
  }

  const runSubscriptions = () => {
    if (Object.keys(subscriptionData).length) return

    Object.keys(subscriptions).forEach(resourceName => {
      let resourceFields
      if (resourceName == 'items') {
        resourceFields = [...fields]
      } else {
        const mappings = findMappingsFor(resourceName).map(el => snakeCase(el))
        const subfields = findSubfieldsFor(mappings)
        resourceFields = mergeSubfields(subfields)
      }

      const camelPluralResourceName = camelCase(pluralize(resourceName))
      subscriptionData[camelPluralResourceName] = subscribe({
        payload: {
          fields: resourceFields,
          channel: subscriptions[resourceName],
          event_id: $store.state.currentContext.event?.id,
          ...subscriptionParams[resourceName],
        },
        key: camelPluralResourceName,
        verbose: verboseSubscriptions,
        checkAndInjectOnCreate: checkAndInjectOnCreate,
        instantDelete: instantDelete,
      })
    })
  }

  onUnmounted(() => {
    each(subscriptionData, (localSubscription, k) => {
      const remoteSubscription = consumer.subscriptions.subscriptions.find(
        s => s.customIdentifier == localSubscription.customIdentifier,
      )
      if (remoteSubscription) {
        if (verboseSubscriptions) {
          console.log(`%c ${k} will unsubscribe from its channel`, 'color: gray')
        }
        consumer.subscriptions.remove(remoteSubscription)
      }
    })
  })

  if (reflectRouteQuery && $route.query) {
    let criteriaSearchParams = null

    if ($route.query.g) {
      try {
        criteriaSearchParams = JSON.parse(sanitizer.process($route.query.g as string))
      } catch (e) {
        console.warn('Could not parse advanced search params', e)
      }
    }

    const searchParams = JSON.parse(sanitizer.process(JSON.stringify($route.query)))
    if (criteriaSearchParams) {
      searchParams.g = criteriaSearchParams
    } else {
      delete searchParams.g
    }

    let shouldManuallyFetchItems = false
    if (searchParams.page?.length || searchParams.page?.constructor == Number) {
      page.value = parseInt(searchParams.page.toString())
      if (page.value) {
        shouldManuallyFetchItems = true
      }
    }
    delete searchParams.page

    if (searchParams.perPage?.length || searchParams.perPage?.constructor == Number) {
      perPage.value = parseInt(searchParams.perPage.toString())
      if (page.value) {
        shouldManuallyFetchItems = true
      }
    }
    delete searchParams.perPage

    if (searchParams.sorts?.length && searchParams.sorts != defaultSort) {
      sort.value = searchParams.sorts
      if (page.value) {
        shouldManuallyFetchItems = true
      }
    }
    delete searchParams.sorts

    if ($route.query.prefilters && injectPrefiltersInRouteQuery) {
      try {
        localPrefilters.value = JSON.parse(sanitizeUrl($route.query.prefilters as string))
        shouldManuallyFetchItems = true
      } catch (e) {
        console.warn('Could not parse prefilter params', e)
      }
    }
    delete searchParams.prefilters

    if (Object.keys(searchParams)?.length) {
      filters.value = searchParams
      shouldManuallyFetchItems = true
    }
    if (shouldManuallyFetchItems) {
      page.value ||= 1
      doFetchItems()
    }
  }

  let result
  if (!prefix) {
    result = {
      page,
      perPage,
      sort,
      filters,
      list,
      batchActions,
      tableScroller,
      total,

      schema,
      anyFilterSet,
      isBlocked,

      clearFilters,
      fetchItems,
      fetchedAll,
      requestPath,
      updateRouteQuery,
      initialPreset,
      localPrefilters,
      defaultDisplayedColumnNames,
      displayedColumnNames,
      availableColumns,
      areColumnPresetsLoading,
      exportableType,
      generateReport,
      columnPresets,
      isPresetUnsaved,
      saveCurrentColumnPreset,
      currentColumnPresetId,
      dataModelType,
      sortItems,
      isMultiSortEnabled,
      toggleSort,
      unsavedColumnPresetIds,
      saveColumnPreset,
      restoreColumnPreset,
      columnWidthsMap,
      fetchColumnPresets,
      search,
      isSearchEnabled,
      currentPresetName,
    }
  } else {
    result = {}

    result[`${prefix}Page`] = page
    result[`${prefix}PerPage`] = perPage
    result[`${prefix}Sort`] = sort
    result[`${prefix}Filters`] = filters
    result[`${prefix}List`] = list
    result[`${prefix}BatchActions`] = batchActions
    result[`${prefix}TableScroller`] = tableScroller
    result[`${prefix}Total`] = total

    result[`${prefix}Schema`] = schema
    result[`${prefix}AnyFilterSet`] = anyFilterSet
    // result[`${prefix}IsBlocked`] = isBlocked

    result[`${prefix}ClearFilters`] = clearFilters
    result[`${prefix}FetchedAll`] = fetchedAll
    result[`fetch${capitalize(prefix[0])}${prefix.substring(1)}`] = fetchItems
    result[`${prefix}RequestPath`] = requestPath
    result[`${prefix}UpdateRouteQuery`] = updateRouteQuery
    result[`${prefix}InitialPreset`] = initialPreset
    result[`${prefix}LocalPrefilters`] = localPrefilters
    result[`${prefix}ColumnPresets`] = columnPresets
    result[`${prefix}IsPresetUnsaved`] = isPresetUnsaved
    result[`${prefix}SaveCurrentColumnPreset`] = saveCurrentColumnPreset
    result[`${prefix}CurrentColumnPresetId`] = currentColumnPresetId
    result[`${prefix}DataModelType`] = dataModelType
    result[`${prefix}SortItems`] = sortItems
    result[`${prefix}IsMultiSortEnabled`] = isMultiSortEnabled
    result[`${prefix}ToggleSort`] = toggleSort
    result[`${prefix}UnsavedColumnPresetIds`] = unsavedColumnPresetIds
    result[`${prefix}SaveColumnPreset`] = saveColumnPreset
    result[`${prefix}RestoreColumnPreset`] = restoreColumnPreset
    result[`${prefix}ColumnWidthsMap`] = columnWidthsMap
    result[`${prefix}FetchColumnPresets`] = fetchColumnPresets
    result[`${prefix}CurrentPresetName`] = currentPresetName
  }

  if (!blocker) {
    result.blocker = usedBlocker
  }

  return result
}

export { useResourcePaginatedList }
