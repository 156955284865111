<script>
export default {
  name: 'DataTableRemoveButton',
  inheritAttrs: false,
  emits: ['click'],
  props: {
    tooltip: { type: String, default: 'Delete' },
    iconSizeClass: { type: String, default: null },
    noMarginBefore: {
      type: Boolean,
      default: false,
    },
    rootClass: { type: String, default: 'd-inline-flex' },
  },
}
</script>

<template lang="pug">
div(:class="rootClass")
  button.btn.btn-link.py-0.px-1(
    v-bind="$attrs"
    :class="{ 'ms-2': !noMarginBefore }"
    @click="$emit('click', $event)"
    type="button"
    v-tooltip.options="{ title: tooltip, placement: 'left' }"
  )
    i.mi-outlined.mi-delete-outline.text-danger(:class="iconSizeClass")
</template>
