<script>
import latinize from 'latinize'
import { difference } from 'lodash'
import { h, inject, shallowRef } from 'vue'
import vSelect from 'vue-select'
import sortAndStringify from 'vue-select/src/utility/sortAndStringify'

import MinMaxHelper from './_min-max-helper.vue'

export default {
  name: 'EaSelect',
  inheritAttrs: false,
  emits: [
    'update:modelValue',
    'option:selecting',
    'option:selected',
    'option:deselecting',
    'option:deselected',
    'option:created',
    'search:blur',
    'search:focus',
    'open',
    'close',
  ],
  props: {
    autodirty: { type: Boolean, default: true },
    disableSelectedOptionTooltip: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    skipDirtyCheck: { type: Boolean, default: false },
    counterOnly: { type: Boolean, default: false },
    deselectFromDropdown: { type: Boolean, default: false },
    dropdownKeepWidth: { type: Boolean, default: false },
    name: {
      type: String,
      required: true,
    },
    filterBy: {
      type: Function,
      default: (option, label, term) => {
        return (
          latinize(label || '')
            .toLowerCase()
            .indexOf(latinize(term || '').toLowerCase()) != -1
        )
      },
    },
    multiple: { type: Boolean, default: false },
    label: { type: String, default: null },
    modelValue: { type: [String, Number, Object, Array, Boolean] },
    inputSize: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['sm', 'lg'].includes(val)
      },
    },
    hint: { type: String, default: null },
    inputAppend: { type: String, default: null },
    inputPrepend: { type: String, default: null },
    validators: { type: Object, default: null },
    clearButton: { type: Boolean, default: true },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
  data: function () {
    return {
      Deselect: shallowRef({
        render: () =>
          this.clearButton ? h('i', { class: { 'bi-x': true }, name: this.name }) : '',
      }),
      OpenIndicator: shallowRef({ render: () => h('i', { class: { 'bi-chevron-down': true } }) }),
    }
  },
  setup() {
    const appendToBody = inject('selectAppendToBody', false)
    return { appendToBody, currentTimestamp: dayjs().unix() }
  },
  computed: {
    selectBoundaries() {
      const result = { ...(this.$attrs || {}) }
      if (this.appendToBody && !result.calculatePosition) {
        result.calculatePosition = (dropdownList, component, { width, top, left }) => {
          dropdownList.style.top = top
          dropdownList.style.left = left
          dropdownList.style.minWidth = width
          dropdownList.style.width = 'auto'
          dropdownList.style.maxWidth = '50vw'
          if (this.multiple && !this.counterOnly) dropdownList.classList.add('within-multiple')
        }
      }
      if (![true, false].includes(result.closeOnSelect) && this.multiple) {
        result.closeOnSelect = false
      }
      return result
    },
    hasValue() {
      return !!this.modelValue && (this.modelValue?.length ?? true)
    },
    sizeClass() {
      return this.inputSize ? `select-${this.inputSize}` : ''
    },
    selectSlots() {
      return Object.keys(this.$slots).filter(slot => {
        let result = !['label-addon', 'input-append', 'input-prepend', 'list-footer'].includes(slot)
        if (result && this.multiple) {
          result = result && slot != 'selected-option'
        }
        return result
      })
    },
    passedSlots() {
      return Object.keys(this.$slots).filter(slot =>
        ['label-addon', 'input-append', 'input-prepend'].includes(slot),
      )
    },
  },
  mounted() {
    this.$refs.selectContainer.addEventListener('keydown', ev => {
      if (ev.key === 'Escape') {
        ev.stopPropagation()
      }
    })
  },
  methods: {
    onEscape() {
      this.$refs.searchInput?.onEscape()
    },
    valueForDeselect(option) {
      return (this.$attrs.taggable != null && typeof this.$attrs.reduce !== 'function') ||
        this.$attrs.reduce(option)?.label
        ? this.optionLabel(option)
        : option
    },
    optionLabel(option) {
      if (this.$attrs.getOptionLabel) {
        return this.$attrs.getOptionLabel(option)
      } else {
        if (typeof option === 'object') {
          if (!Object.keys(option).includes(this.$attrs['select-label'] || 'label')) {
            return console.warn(
              `[vue-select warn]: Label key "option.${
                this.$attrs['select-label'] || 'label'
              }" does not` +
                ` exist in options object ${JSON.stringify(option)}.\n` +
                'https://vue-select.org/api/props.html#getoptionlabel',
            )
          }
          const result = option[this.$attrs['select-label'] || 'label']
          return result
        }
        return option
      }
    },
    optionKey(option) {
      if (this.$attrs.getOptionLabel) {
        return this.$attrs.getOptionLabel(option)
      } else {
        if (typeof option !== 'object') {
          return option
        }

        try {
          return Object.keys(option).includes('id') ? option.id : sortAndStringify(option)
        } catch (e) {
          const warning =
            `[vue-select warn]: Could not stringify this option ` +
            `to generate unique key. Please provide'getOptionKey' prop ` +
            `to return a unique key for each option.\n` +
            'https://vue-select.org/api/props.html#getoptionkey'
          return console.warn(warning, option, e)
        }
      }
    },
    areAllFilteredOptionsSelected(filteredOptions) {
      if (this.multiple && this.modelValue?.constructor == Array) {
        return !difference(
          filteredOptions.map(el => this.optionKey(el)),
          this.modelValue,
        ).length
      } else {
        return false
      }
    },
  },
  components: {
    MinMaxHelper,
    vSelect,
  },
}
</script>

<template lang="pug">
.ea-select(ref="selectContainer" :class="$attrs.class")
  form-control-container(
    :additional-errors="additionalErrors"
    :autodirty="autodirty"
    :hint="hint"
    :input-size="inputSize"
    :inputAppend="inputAppend"
    :inputPrepend="inputPrepend"
    :label="label"
    :name="name"
    :skipDirtyCheck="skipDirtyCheck"
    :validators="validators"
    :value="modelValue"
  )
    template(v-for="slotName in passedSlots" #[slotName]="item")
      slot(v-bind="item" :name="slotName")
    template(v-if="multiple && clearButton && !disabled && label?.length" #label-addon)
      transition(name="fade")
        span(v-if="hasValue" :name="name")
          i.clear-btn.ms-1.bi-x-lg.text-danger.cursor-pointer.small(
            @click="$emit('update:modelValue', multiple ? [] : null)"
          )
    template(#default)
      v-select.bg-white(
        ref="searchInput"
        v-bind="selectBoundaries"
        :appendToBody="appendToBody"
        :class="{ [sizeClass]: true, multiple, 'counter-only': multiple && counterOnly, 'keep-width': dropdownKeepWidth, deselectable: deselectFromDropdown }"
        :components="{ Deselect, OpenIndicator }"
        :deselectFromDropdown="deselectFromDropdown"
        :disabled="disabled"
        :filterBy="filterBy"
        :label="$attrs['select-label']"
        :modelValue="modelValue"
        :multiple="multiple"
        @close="$emit('close', $event)"
        @open="$emit('open', $event)"
        @option:created="$emit('option:created', $event)"
        @option:deselected="$emit('option:deselected', $event)"
        @option:deselecting="$emit('option:deselecting', $event)"
        @option:selected="$emit('option:selected', $event)"
        @option:selecting="$emit('option:selecting', $event)"
        @search:blur="$emit('search:blur', $event)"
        @search:focus="$emit('search:focus', $event)"
        @update:modelValue="$emit('update:modelValue', $event)"
        dir="ltr"
      )
        template(v-for="slotName in selectSlots" #[slotName]="item")
          slot(v-bind="item" :name="slotName")

        template(#list-footer="{ search, loading, searching, filteredOptions }")
          li.vs__no-options(
            v-if="filteredOptions?.length && areAllFilteredOptionsSelected(filteredOptions) && !counterOnly"
          )
            slot(
              v-bind="{ search, loading, searching, filteredOptions }"
              name="no-more-matching-options"
            )
              span Sorry, no more matching options.
          slot(v-bind="{ search, loading, searching, filteredOptions }" name="list-footer")

        template(
          v-if="multiple"
          #selected-option-container="{ option, deselect, multiple, disabled }"
        )
          template(v-if="counterOnly")
            .d-flex.align-items-center.justify-content-start.selected-x-items(
              v-if="(option?.id || option?.value) == (modelValue || [])[0]"
            )
              slot(v-bind={ modelValue } name="selected-x-options")
                span Selected {{ modelValue?.length }} item{{ modelValue?.length > 1 ? 's' : '' }}
              i.clear-btn.ms-1.bi-x-lg.text-danger.cursor-pointer.small.position-relative(
                @click.prevent="$emit('update:modelValue', [])"
                style="z-index: 1002"
              )
            span(v-else)
          template(v-else)
            span.vs__selected(
              :class="option.wrapperClass"
              :key="optionKey(option)"
              v-tooltip="disableSelectedOptionTooltip ? null : optionLabel(option)"
            )
              slot(v-bind="option" name="selected-option")
                | {{ optionLabel(option) }}

              button.vs__deselect(
                ref="deselectButtons"
                v-if="multiple"
                :aria-label="`Deselect ${optionLabel(option)}`"
                :disabled="disabled"
                :title="`Deselect ${optionLabel(option)}`"
                @mousedown.stop="deselect(valueForDeselect(option))"
                type="button"
              )
                component(:is="Deselect")

        template(v-if="!Object.keys($slots).includes('search')" #search="{ attributes, events }")
          input.vs__search(
            v-on="events"
            v-bind="attributes"
            :autocomplete="`hello-there-dont-show-me-anything-here-${currentTimestamp}`"
            :name="name"
          )
</template>
