<!-- eslint-disable unused-imports/no-unused-imports -->
<script setup lang="ts">
import eventHub from 'frontend/_globals/event-hub'
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import EventDashboardItems from 'frontend/events/event-dashboard-items.vue'
import { useKeyboardShortcuts } from 'frontend/keyboard/composables/useKeyboardShortcuts'
import { KeyboardModifierKeyTypes } from 'frontend/keyboard/enum/KeyboardModifierKeyTypes'
import { KeyboardSpecialKeyTypes } from 'frontend/keyboard/enum/KeyboardSpecialKeyTypes'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const searchTerm = ref('')
const spotlightWidth = ref(0)
const isSpotlightOpen = ref(false)
const spotlightRef = ref(null)

useKeyboardShortcuts(
  [
    [
      [
        [KeyboardModifierKeyTypes.Control, KeyboardSpecialKeyTypes.Slash],
        [KeyboardModifierKeyTypes.Meta, KeyboardSpecialKeyTypes.Slash],
        [KeyboardModifierKeyTypes.Meta, KeyboardSpecialKeyTypes.NumpadDivide],
        [KeyboardModifierKeyTypes.Control, KeyboardSpecialKeyTypes.Slash],
        [KeyboardModifierKeyTypes.Control, KeyboardSpecialKeyTypes.NumpadDivide],
        [KeyboardModifierKeyTypes.Meta, 'k'],
      ],
      event => {
        if (route.name == 'event-dashboard') {
          document.getElementsByName('searchTerm')[0].focus()
          return
        }
        isSpotlightOpen.value = !isSpotlightOpen.value
        eventHub.$emit('spotlight-open', isSpotlightOpen.value)
        event.stopPropagation()
        event.preventDefault()
      },
    ],
  ],
  () => {
    return true
  },
  {
    isGlobal: true,
  },
)

useKeyboardShortcuts(
  [
    [
      [KeyboardSpecialKeyTypes.Escape],
      event => {
        isSpotlightOpen.value = false
        searchTerm.value = ''
        eventHub.$emit('spotlight-open', false)
        event.stopPropagation()
        event.preventDefault()
      },
    ],
  ],
  () => {
    return isSpotlightOpen.value
  },
)

onMounted(() => {
  eventHub.$on('open-spotlight', openSpotlight)
  eventHub.$on('close-spotlight', closeSpotlight)
  eventHub.$on('windowResize', updateSpotlightWidth)
  spotlightWidth.value = spotlightRef.value.clientWidth
})

onBeforeUnmount(() => {
  eventHub.$off('open-spotlight', openSpotlight)
  eventHub.$off('close-spotlight', closeSpotlight)
  eventHub.$off('windowResize', updateSpotlightWidth)
})

function openSpotlight(event) {
  isSpotlightOpen.value = true
  searchTerm.value = event || ''
}

function closeSpotlight() {
  isSpotlightOpen.value = false
}

function updateSpotlightWidth() {
  spotlightWidth.value = spotlightRef.value.clientWidth
}
</script>

<template lang="pug">
.spotlight(ref="spotlightRef")
  transition(appear name="fade-in")
    .spotlight-blur(@click="isSpotlightOpen = false" v-show="isSpotlightOpen")
      EventDashboardItems(
        :injectedSearchTerm="searchTerm"
        :isSpotlightOpen="isSpotlightOpen"
        :spotlightWidth="spotlightWidth"
        inSpotlight
      )
</template>
