import { type AxiosInstance } from 'axios'
import { dialogs } from 'frontend/_globals/dialogs'
import { type EventHub } from 'frontend/_globals/event-hub'
import { type ToastAction } from 'frontend/_stores/toast-store'
import { type Globals } from 'types/globals'
import { type InjectionKey } from 'vue'

export const $$success: InjectionKey<ToastAction> = Symbol('$success')
export const $$warning: InjectionKey<ToastAction> = Symbol('$warning')
export const $$error: InjectionKey<ToastAction> = Symbol('$error')
export const $$info: InjectionKey<ToastAction> = Symbol('$info')
export const $$axios: InjectionKey<AxiosInstance> = Symbol('axios')

export const $$mainDomain: InjectionKey<string> = Symbol('$mainDomain')
export const $$globals: InjectionKey<Globals> = Symbol('$globals')
export const $$eventHub: InjectionKey<EventHub> = Symbol('$eventHub')
export const $$dialogs: InjectionKey<typeof dialogs> = Symbol('$dialogs')
