<script setup lang="ts">
import { dialogs } from 'frontend/_globals/dialogs'
import TableMultiSortToggleComponent from 'frontend/columns/components/TableMultiSortToggleComponent.vue'
import VisibleColumnEditorComponent from 'frontend/columns/components/VisibleColumnEditorComponent.vue'
import { ColumnPresetFields } from 'frontend/columns/config/ColumnPresetFields'
import { ColumnPresetService } from 'frontend/columns/services/ColumnPresetService'
import { IColumnPresetResponseEntity } from 'frontend/columns/types/IColumnPresetResponseEntity'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'
import { getEventOrganizationUser } from 'frontend/common/access-helpers'
import { useSortItems } from 'frontend/common/composables/useSortItems'
import DismissButton from 'frontend/common/dismiss-button.vue'
import FormErrorMessageComponent from 'frontend/errors/components/FormErrorMessageComponent.vue'
import { useProvideFormErrors } from 'frontend/errors/composables/useFormErrors'
import ButtonComponent from 'frontend/roles/components/ButtonComponent.vue'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { DataModelType } from 'frontend/roles/enum/DataModelType'

interface Props {
  isPresetGlobal?: boolean
  displayedColumnNames?: string[]
  shouldMakeRequests?: boolean
  sortItems: IColumnSortItems
  sort?: string | string[]
  isPresetUnsaved?: boolean
  currentPresetName?: string
  dataModelType: DataModelType
  columnPreset?: IColumnPresetResponseEntity
}

const props = withDefaults(defineProps<Props>(), {
  shouldMakeRequests: true,
  isPresetGlobal: true,
  isPresetUnsaved: null,
})

interface Emits {
  (
    e: 'save',
    {
      name,
      closeCallback,
    }: {
      name: string
      closeCallback(): void
      errorsCallback(errors: Record<string, string[]>): void
    },
  ): void
  (e: 'afterSave', columnPresetId: string): void
  (e: 'unsavedChangesStateChange', state: boolean): void
  (e: 'delete'): void
  (e: 'restore'): void
  (e: 'update:displayedColumnNames', value: string[]): void
  (e: 'update:sort', value: string | string[]): void
  (e: 'update:sortItems', value: IColumnSortItems): void
  (e: 'update:currentPresetName', value: string): void
}
const emit = defineEmits<Emits>()

const enabledColumnKeysModel = computed({
  get() {
    if (props.displayedColumnNames) {
      return props.displayedColumnNames
    }
    return enabledColumnKeys.value
  },
  set(value: string[]) {
    if (props.displayedColumnNames) {
      emit('update:displayedColumnNames', value)
      return
    }
    enabledColumnKeys.value = value
  },
})

const { convertStoredSortToSortItems, convertSortItemsToStoredSort } = useSortItems()

const columnPresetService = new ColumnPresetService()

const temporaryName = ref(props.currentPresetName || props.columnPreset?.name || '')
const temporarySortItems = ref(
  props.sortItems || convertStoredSortToSortItems(props.columnPreset?.defaultSortColumnType),
)
const enabledColumnKeys = ref(
  props.columnPreset?.enabledColumnTypes || props.displayedColumnNames || [],
)

const sortItemsModel = computed({
  get() {
    if (props.sortItems) {
      return props.sortItems
    }
    return temporarySortItems.value
  },
  set(value) {
    temporarySortItems.value = value
    emit('update:sortItems', value)
  },
})
const currentPresetNameModel = computed({
  get() {
    if (typeof props.currentPresetName !== 'undefined') {
      return props.currentPresetName
    }
    return temporaryName.value
  },
  set(value) {
    emit('update:currentPresetName', value)
    temporaryName.value = value
  },
})

const store = useStore()
const route = useRoute()

const { setErrors } = useProvideFormErrors(
  {
    name() {
      return currentPresetNameModel.value
    },
    enabledColumnTypes() {
      return JSON.stringify(enabledColumnKeysModel.value)
    },
  },
  [['enabledColumnTypes', 'Enabled column types']],
)

async function save() {
  isSavingPreset.value = true
  if (!props.shouldMakeRequests) {
    emit('save', {
      name: currentPresetNameModel.value,
      closeCallback: () => {
        isSavingPreset.value = false
      },
      errorsCallback: errors => {
        isSavingPreset.value = false
        setErrors(errors)
      },
    })
    return
  }
  const dataToSave: {
    id?: string
    name: string
    enabledColumnTypes: string[]
    defaultSortColumnType: string
    dataModelType: string
    eventOrganizationUserId?: string
  } = {
    id: props.columnPreset?.id as string,
    name: currentPresetNameModel.value,
    enabledColumnTypes: enabledColumnKeysModel.value,
    defaultSortColumnType: convertSortItemsToStoredSort(temporarySortItems.value),
    dataModelType: props.dataModelType,
  }
  if (!props.isPresetGlobal) {
    const eventOrganizationUser = getEventOrganizationUser(
      store.state.session.user,
      route.params.eventSlug,
    )
    dataToSave.eventOrganizationUserId = eventOrganizationUser.id
  }
  try {
    const res = await columnPresetService.save({
      data: dataToSave,
      // columnPreset: dataToSave,
      fields: ColumnPresetFields,
    })
    emit('afterSave', res.data.id)
  } catch (e) {
    setErrors(e.response.data.errors)
  } finally {
    isSavingPreset.value = false
  }
}

const isSavingPreset = ref(false)

const hasUnsavedChanges = computed(() => {
  if (typeof props.isPresetUnsaved === 'boolean') {
    return props.isPresetUnsaved
  }
  return (
    temporaryName.value !== props.columnPreset.name ||
    enabledColumnKeysModel.value.join(',') !== props.columnPreset?.enabledColumnTypes.join(',') ||
    convertSortItemsToStoredSort(
      convertStoredSortToSortItems(props.columnPreset.defaultSortColumnType),
    ) !== convertSortItemsToStoredSort(temporarySortItems.value)
  )
})

watch(
  hasUnsavedChanges,
  () => {
    emit('unsavedChangesStateChange', hasUnsavedChanges.value)
  },
  {
    immediate: true,
  },
)

function resetValues() {
  temporaryName.value = props.columnPreset?.name || ''
  temporarySortItems.value =
    props.sortItems || convertStoredSortToSortItems(props.columnPreset?.defaultSortColumnType)
  enabledColumnKeysModel.value =
    props.displayedColumnNames || props.columnPreset?.enabledColumnTypes || []
  emit('restore')
}

const nameRef = ref<HTMLInputElement>(null)

function focusName() {
  nameRef.value.focus()
}

async function onDelete() {
  await dialogs.confirm('Do you really want to delete this column preset?')
  emit('delete')
}

const isMultiSortEnabled = ref((sortItemsModel?.value?.length || 1) > 1)
</script>

<template>
  <div class="column-preset-editor">
    <!--    <div class="name-with-error-wrapper">-->
    <!--      -->
    <!--    </div>-->
    <div class="name-wrapper">
      <input
        ref="nameRef"
        v-model="currentPresetNameModel"
        placeholder="Enter preset name"
        class="name"
      />
      <ButtonComponent
        v-tooltip="'Rename preset'"
        class="edit-icon name-icon"
        icon-class="fas fa-pen"
        type="secondary"
        @click="focusName"
      />
      <ButtonComponent
        v-tooltip="'Delete this preset'"
        class="delete-icon name-icon"
        icon-class="fas fa-trash"
        type="danger"
        @click="onDelete"
      />
      <FormErrorMessageComponent attribute-key="name" />
    </div>
    <TableMultiSortToggleComponent v-model:is-multi-sort-enabled="isMultiSortEnabled" />
    <VisibleColumnEditorComponent
      v-model:enabled-column-keys="enabledColumnKeysModel"
      v-model:sortItems="sortItemsModel"
      :data-model-type="dataModelType"
      :is-multi-sort-enabled="isMultiSortEnabled"
    >
      <template #afterEmptyEnabledColumns>
        <FormErrorMessageComponent attribute-key="enabledColumnTypes" />
      </template>
    </VisibleColumnEditorComponent>

    <div v-if="hasUnsavedChanges" class="footer">
      <DismissButton @click="resetValues">Discard</DismissButton>
      <ButtonComponent icon-class="fas fa-check" :is-loading="isSavingPreset" @click="save">
        Save
      </ButtonComponent>
    </div>
  </div>
</template>

<style scoped lang="scss">
.name-wrapper {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 4px;
}

.name {
  outline: none;
  font-size: 22px;
  font-weight: 700;
  border: none;
  padding: 0;
  background: transparent;
  grid-area: 1 / 1 / 2 / 4;
  padding-right: calc(36px * 2 + 4px * 2);
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    background: hsl(0deg, 0%, 94%);
    padding: 4px;
  }
}

.name-icon {
  width: 36px;
  height: 36px;
  padding: 0;

  .name:focus ~ & {
    display: none;
  }
}

.edit-icon {
  grid-area: 1 / 2 / 2 / 2;
}

.delete-icon {
  grid-area: 1 / 3 / 2 / 3;
}

.column-preset-editor {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  gap: 16px;
  user-select: none;
}

.footer {
  justify-content: flex-end;
  pointer-events: all;
  display: flex;
  gap: 4px;
  // border-top: 1.4px solid hsl(200deg, 5%, 89%);
  // padding: 0 0 16px 0;
}
</style>
