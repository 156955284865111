<script setup lang="ts">
import ColumnPresetEditorComponent from 'frontend/columns/components/ColumnPresetEditorComponent.vue'
import ColumnPresetItemComponent from 'frontend/columns/components/ColumnPresetItemComponent.vue'
import { ColumnPresetService } from 'frontend/columns/services/ColumnPresetService'
import { IColumnPresetResponseEntity } from 'frontend/columns/types/IColumnPresetResponseEntity'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'
import Modal from 'frontend/common/modal.vue'
import FormErrorMessageComponent from 'frontend/errors/components/FormErrorMessageComponent.vue'
import { useProvideFormErrors } from 'frontend/errors/composables/useFormErrors'
import ButtonComponent from 'frontend/roles/components/ButtonComponent.vue'
import ContainerHeaderIconTitleComponent from 'frontend/roles/components/ContainerHeaderIconTitleComponent.vue'
import EmptyListIndicatorComponent from 'frontend/roles/components/EmptyListIndicatorComponent.vue'
import EmptyRolesPlaceholderComponent from 'frontend/roles/components/EmptyRolesPlaceholderComponent.vue'
import { DataModelTypeItems } from 'frontend/roles/config/DataModelTypeItems'
import InputComponent from 'frontend/ui/components/InputComponent.vue'
import LoadingComponent from 'frontend/ui/components/LoadingComponent.vue'
import { useSmoothVisualLoadingDebouncer } from 'frontend/ui/composables/useSmoothVisualLoadingDebouncer'
import { computed, ref } from 'vue'
import Draggable from 'vuedraggable'

import { DataModelType } from '../../roles/enum/DataModelType'

interface Props {
  dataModelType: DataModelType
  // modelValue: string[]
  columnPresets: IColumnPresetResponseEntity[]
  unsavedColumnPresetIds: (string | true)[]
  currentColumnPresetId: string | true
  isPresetUnsaved: boolean
  displayedColumnNames?: string[]
  currentPresetName: string
  sortItems: IColumnSortItems
  sort?: string | string[]
}
const props = defineProps<Props>()

interface Emits {
  (e: 'exit'): void
  (e: 'restore'): void
  (e: 'update:modelValue', value: string[]): void
  (e: 'fetchColumnPresets'): void
  (e: 'update:currentColumnPresetId', value: string): void
  (e: 'update:displayedColumnNames', value: string[]): void
  (
    e: 'save',
    {
      name,
      closeCallback,
    }: {
      name: string
      closeCallback(): void
      errorsCallback(errors: Record<string, string[]>): void
    },
  ): void
  (e: 'update:sort', value: unknown): void
  (e: 'update:sortItems', value: IColumnSortItems): void
  (e: 'update:currentPresetName', value: string): void
}
const emit = defineEmits<Emits>()

// const { columnPresetList, fetchItems } = useColumnPresets()
const { setErrors } = useProvideFormErrors({}, [['base', '']])

const columnPresetService = new ColumnPresetService()

const currentColumnPresetIdModel = computed({
  get() {
    return props.currentColumnPresetId
  },
  set(value: string) {
    emit('update:currentColumnPresetId', value)
  },
})
const displayedColumnNamesModel = computed({
  get() {
    return props.displayedColumnNames
  },
  set(value) {
    emit('update:displayedColumnNames', value)
  },
})
const sortModel = computed({
  get() {
    return props.sort
  },
  set(value) {
    emit('update:sort', value)
  },
})
const sortItemsModel = computed<IColumnSortItems>({
  get() {
    return props.sortItems
  },
  set(value) {
    emit('update:sortItems', value)
  },
})
const currentPresetNameModel = computed({
  get() {
    return props.currentPresetName
  },
  set(value) {
    emit('update:currentPresetName', value)
  },
})

const isPresetInEditMode = ref(false)
const localSearch = ref('')
const globalSearch = ref('')

// const valueModel = computed({
//   get() {
//     return props.modelValue
//   },
//   set(value) {
//     emit('update:modelValue', value)
//   },
// })

const mappedColumnPresets = computed(() => {
  if (!props.columnPresets) {
    return null
  }
  return props.columnPresets.map(columnPreset => {
    return {
      name: columnPreset.name,
      id: columnPreset.id,
      isGlobal: columnPreset.isGlobal,
      isActive: columnPreset.id === currentColumnPresetIdModel.value,
      isUnsaved: props.unsavedColumnPresetIds.includes(columnPreset.id),
    }
  })
})

const localColumnPresets = computed(() => {
  return mappedColumnPresets.value
    ?.filter(columnPreset => {
      return columnPreset.isGlobal === false
    })
    .filter(columnPreset => {
      return columnPreset.name.toLowerCase().includes(localSearch.value.toLowerCase())
    })
})

const globalColumnPresets = computed(() => {
  return mappedColumnPresets.value
    ?.filter(columnPreset => {
      return columnPreset.isGlobal === true
    })
    .filter(columnPreset => {
      return columnPreset.name.toLowerCase().includes(globalSearch.value.toLowerCase())
    })
})

async function saveColumnPresetOrder(columnPresetIds: string[]) {
  await columnPresetService.saveOrder(columnPresetIds)
  emit('fetchColumnPresets')
}

const localColumnPresetIds = computed({
  get() {
    return localColumnPresets.value?.map(item => item.id)
  },
  set(value: string[]) {
    saveColumnPresetOrder(value)
  },
})

async function createNew() {
  currentColumnPresetIdModel.value = null
}

const dataModelTypeItem = computed(() => {
  return DataModelTypeItems.find(item => item.dataModelType === props.dataModelType)
})

const currentlyEditedColumnPreset = computed(() => {
  if (currentColumnPresetIdModel.value === null) {
    return {
      name: '',
      id: null,
      dataModelType: props.dataModelType,
      defaultSortColumnType: null,
      enabledColumnTypes: [],
      defaultSortDirection: null,
    }
  }
  return props.columnPresets.find(item => item.id === currentColumnPresetIdModel.value)
})

// function save(columnPresetId: string) {
// isPresetInEditMode.value = false
// currentColumnPresetIdModel.value = columnPresetId
// emit('fetchColumnPresets')
// }

// watch(existingActiveColumnPresetIds, value => {
//   valueModel.value = value
// })

const isDragging = ref(false)

async function onDelete() {
  // if (currentColumnPresetIdModel.value === true) {
  //   return
  // }
  // try {
  //   await columnPresetService.delete(currentColumnPresetIdModel.value)
  //   currentColumnPresetIdModel.value = null
  //   emit('fetchColumnPresets')
  // } catch (e) {
  //   setErrors(e.response.data.errors)
  // }
}

async function changePreset(columnPresetId: string) {
  currentColumnPresetIdModel.value = columnPresetId
}

// async function beforeModalClose() {
//   if (hasPresetUnsavedChanges.value) {
//     await dialogs.confirm('You have some unsaved changes. Are you sure you want to discard them?')
//   }
// }
const { hasLoadedWithDebounce } = useSmoothVisualLoadingDebouncer(() => {
  return !!mappedColumnPresets.value
})
</script>

<template>
  <Modal hide-footer @closed="emit('exit')">
    <template #header>
      <ContainerHeaderIconTitleComponent icon-class="fas fa-table-columns">
        <span class="casual">Choose column presets for</span>
        <span>&nbsp;</span>
        <span class="underline">{{ dataModelTypeItem.name }}</span>
      </ContainerHeaderIconTitleComponent>
    </template>
    <div class="column-modal-content">
      <div
        v-if="hasLoadedWithDebounce"
        class="grid"
        :class="{ 'are-presets-visible': mappedColumnPresets.length }"
      >
        <div
          v-if="mappedColumnPresets.length"
          class="presets"
          :class="{ 'is-any-preset-in-edit-mode': isPresetInEditMode }"
        >
          <ContainerHeaderIconTitleComponent icon-class="fas fa-bookmark">
            Saved presets
          </ContainerHeaderIconTitleComponent>
          <div class="preset-group has-search">
            <div class="title">My presets</div>
            <div class="search-wrapper">
              <InputComponent v-model="localSearch" placeholder="Search for preset" />
              <ButtonComponent
                icon-class="fas fa-plus"
                class="create-preset-square-button"
                @click="createNew"
              />
            </div>
            <div class="preset-items-wrapper" :class="{ 'is-dragging': isDragging }">
              <Draggable
                v-model="localColumnPresetIds"
                :animation="200"
                :disabled="false"
                ghost-class="ghost"
                item-key=""
                class="preset-items"
                group="column-preset-items"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <template #item="{ index }">
                  <ColumnPresetItemComponent
                    :key="localColumnPresets[index].id"
                    :can-delete="true"
                    :name="localColumnPresets[index].name"
                    :class="{ 'is-current': localColumnPresets[index].isActive }"
                    can-reorder
                    disable-toggle
                    @edit-click="changePreset(localColumnPresets[index].id)"
                    :isUnsaved="localColumnPresets[index].isUnsaved"
                  />
                </template>
                <template #footer>
                  <div class="empty">There are no available presets here</div>
                </template>
              </Draggable>
            </div>
          </div>
          <div class="preset-group has-search">
            <div class="title">Global presets</div>
            <div class="search-wrapper">
              <InputComponent v-model="globalSearch" placeholder="Search for preset" />
            </div>
            <div class="preset-items-wrapper" :class="{ 'is-dragging': isDragging }">
              <div class="preset-items" v-if="globalColumnPresets?.length">
                <ColumnPresetItemComponent
                  v-for="columnPreset in globalColumnPresets"
                  :key="columnPreset.id"
                  :can-delete="true"
                  :name="columnPreset.name"
                  :class="{ 'is-current': columnPreset.isActive }"
                  disable-toggle
                  @edit-click="changePreset(columnPreset.id)"
                  :isUnsaved="columnPreset.isUnsaved"
                />
              </div>
              <div class="empty" v-else>There are no available presets here</div>
            </div>
          </div>
          <FormErrorMessageComponent attribute-key="base" :disappear="5000" />
        </div>
        <div class="content">
          <ColumnPresetEditorComponent
            v-if="currentlyEditedColumnPreset || true"
            :key="currentColumnPresetId as string"
            v-model:isInEditMode="isPresetInEditMode"
            v-model:sort-items="sortItemsModel"
            v-model:sort="sortModel"
            v-model:currentPresetName="currentPresetNameModel"
            v-model:displayedColumnNames="displayedColumnNamesModel"
            :is-preset-global="false"
            :shouldMakeRequests="false"
            :isPresetUnsaved="isPresetUnsaved"
            @save="emit('save', $event)"
            @delete="onDelete"
            @restore="emit('restore')"
            :dataModelType="props.dataModelType"
          />
          <div v-else class="empty-list-indicator-wrapper">
            <EmptyListIndicatorComponent>
              <template #before>
                <EmptyRolesPlaceholderComponent />
              </template>
              <template #title>
                {{ mappedColumnPresets.length ? 'Configure presets' : 'Create your first preset' }}
              </template>
              <template #description>
                Presets are sets of columns along with their<br />order and a default column for
                sorting.
              </template>
              <template v-if="!mappedColumnPresets.length" #after>
                <ButtonComponent icon-class="far fa-plus" @click="createNew">
                  Create preset
                </ButtonComponent>
              </template>
            </EmptyListIndicatorComponent>
          </div>
        </div>
      </div>
      <div v-else class="loading-wrapper">
        <LoadingComponent />
      </div>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.column-modal-content {
  min-height: 340px;
  min-width: 800px;
  box-sizing: border-box;
  display: grid;
  padding-bottom: 16px;
}

.casual {
  font-weight: 500;
}

.preset-items-wrapper {
  overflow-y: auto;
  position: relative;
  border-radius: 8px;
}

.preset-items {
  display: grid;
  gap: 2px;
  align-content: flex-start;
  min-height: 100%;
}

.empty {
  color: hsl(200deg, 5%, 50%);
  position: absolute;
  pointer-events: none;
  transition: all 0.25s cubic-bezier(0, 0.6, 0.4, 1);
  opacity: 1;
  height: 100%;
  border-radius: 8px;

  .preset-items:has(.column-preset-item) & {
    opacity: 0;
  }

  border: 2px dashed transparent;

  .is-dragging & {
    border: 2px dashed hsl(200deg, 5%, 85%);
    padding: 8px;
    box-sizing: border-box;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'content';
  align-content: flex-start;

  &.are-presets-visible {
    grid-template-columns: 240px 1fr;
    grid-template-areas: 'presets content';
  }
}

.empty-list-indicator-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  gap: 8px;

  .create-preset-square-button {
    padding: 0;
    width: 2.2rem;
  }
}

.presets {
  display: grid;
  grid-template-rows: auto minmax(210px, 300px) minmax(210px, 300px);
  grid-area: presets;
  border-right: 1.4px solid hsl(200deg, 5%, 89%);
  padding: 0 16px;
  gap: 8px;
  align-content: flex-start;
  user-select: none;

  &.is-any-preset-in-edit-mode {
    opacity: 0.5;
    pointer-events: none;
  }
}

.preset-group {
  display: grid;
  gap: 8px;
  align-content: flex-start;
  grid-template-rows: auto minmax(128px, 1fr);

  &.has-search {
    grid-template-rows: auto auto minmax(128px, 1fr);
  }
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container-header {
  grid-area: header;
  border-bottom: 1.4px solid hsla(200, 5%, 89%, 1);
  grid-template-columns: auto 1fr auto;
  padding: 16px;
  padding-bottom: 16px;
  align-items: center;

  .button.back {
    padding: 0;
    width: 2.2rem;
  }
}

.filter-list {
  display: grid;
}

.filter-item {
  cursor: pointer;
  padding: 4px 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-radius: 8px;

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    display: none;
    gap: 4px;

    i {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover .actions {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: #edf4fa;
  }

  &.is-active {
    background: #edf4fa;
    font-weight: 600;
    color: hsl(211deg, 50%, 39%);
  }
}
.saved-filters {
  padding: 16px;
  border-right: 1.4px solid hsla(200, 5%, 89%, 1);
  display: grid;
  gap: 4px;
  align-content: flex-start;
}

.content {
  display: grid;
  padding: 0 16px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.builder-grid {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 16px;
}

.multi-button {
  display: flex;
  gap: 2px;

  .button.save-and-use {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .button.more-options {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 0;
    width: 2.4em;
  }
}

.underline {
  text-decoration: underline;
}
</style>
