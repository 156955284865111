import { ColumnSortDirection } from 'frontend/columns/enum/ColumnSortDirection'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'
import { type Ref, watch } from 'vue'

const ONLY_LAST_MULTISORT_ITEM_CAN_BE_REMOVED = true

export function useToggleMultiSort(
  sortItems: Ref<IColumnSortItems>,
  isMultiSortEnabled: Ref<boolean>,
) {
  function toggleMultiSort(columnKey: string) {
    if (!isMultiSortEnabled.value) {
      return toggleSimpleSort(columnKey)
    }

    const foundSortItem = sortItems.value.find(([key]) => key === columnKey)
    const foundSortItemIndex = sortItems.value.findIndex(([key]) => key === columnKey)
    if (foundSortItem) {
      let stateToChange =
        foundSortItem[1] === ColumnSortDirection.Ascending ? ColumnSortDirection.Descending : null
      if (
        ONLY_LAST_MULTISORT_ITEM_CAN_BE_REMOVED &&
        foundSortItemIndex !== sortItems.value.length - 1
      ) {
        stateToChange =
          foundSortItem[1] === ColumnSortDirection.Ascending
            ? ColumnSortDirection.Descending
            : ColumnSortDirection.Ascending
      }
      if (stateToChange === null) {
        sortItems.value = sortItems.value.filter(([key]) => key !== columnKey)
      } else {
        sortItems.value = sortItems.value.map(([key, direction]) => {
          if (key === columnKey) {
            return [key, stateToChange]
          }
          return [key, direction]
        })
      }
    } else {
      sortItems.value = [...sortItems.value, [columnKey, ColumnSortDirection.Ascending]]
    }
  }

  function toggleSimpleSort(columnKey: string) {
    if (!sortItems.value.length) {
      return (sortItems.value = [[columnKey, ColumnSortDirection.Ascending]])
    }
    const sortItem = sortItems.value.slice(0, 1)[0]
    if (sortItem[0] === columnKey) {
      if (sortItem[1] === ColumnSortDirection.Ascending) {
        return (sortItems.value = [[columnKey, ColumnSortDirection.Descending]])
      }
      return (sortItems.value = [])
    } else {
      return (sortItems.value = [[columnKey, ColumnSortDirection.Ascending]])
    }
  }

  watch(isMultiSortEnabled, newValue => {
    if (!newValue && sortItems.value.length > 1) {
      sortItems.value = [sortItems.value[0]]
    }
  })
  return {
    toggleMultiSort,
    toggleSimpleSort,
  }
}
