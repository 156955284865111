export function transferGroupPassengersCount(tg): number | undefined {
  const re = tg.transportBookings?.reduce((acc, tb) => {
    if (tb.client || tb.clientId) {
      return acc + 1
    } else if (tb.clientGroup || tb.clientGroupId) {
      return acc + (tb.numberOfClients || 0)
    } else {
      // legacy support for unnamed clients
      return acc + (tb.numberOfClients || 1)
    }
  }, 0)

  return re
}
