<script lang="ts">
import { useModals } from 'frontend/common/modals'

export default {
  name: 'Osl',
  props: {
    osl: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
  },
  setup() {
    return { ...useModals() }
  },
}
</script>

<template lang="pug">
span.text-black-15(v-if="hide") empty
span.text-warning.bold(v-else-if="!osl") No service
template(v-else-if="!osl.id")
  erebus-label(:item="osl")
modal-button(v-else :disabled="disabled" :item="osl" :modal="previewModals.Osl")
  a
    erebus-label(:item="osl")
</template>
