<script>
import { ref } from 'vue'

import { TIME_VARIABLES } from './_search-criterion-predicate-config.js'

export default {
  name: 'DateTimeWithVariablesInput',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    skipDirtyCheck: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      datetimeChosenVariables: ref({}),
      showPicker: ref(
        props.modelValue?.length && !Object.keys(TIME_VARIABLES).includes(props.modelValue),
      ),
    }
  },
  computed: {
    chosenVariable: {
      get() {
        if (Object.keys(TIME_VARIABLES).includes(this.modelValue)) {
          return this.modelValue
        } else if (this.modelValue?.length || this.showPicker) {
          return 'specific'
        } else {
          return null
        }
      },
      set(newVal) {
        if (newVal == 'specific') {
          this.showPicker = true
          this.$emit('update:modelValue', null)
        } else {
          if (!newVal?.length) {
            this.showPicker = false
          }
          this.$emit('update:modelValue', newVal)
        }
      },
    },
    chosenTimestamp: {
      get() {
        console.log('have model value', this.modelValue)
        if (Object.keys(TIME_VARIABLES).includes(this.modelValue) || !this.showPicker) {
          return null
        } else {
          return this.modelValue
        }
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal)
      },
    },
    variablesSelectorOptions() {
      const result = Object.keys(TIME_VARIABLES).map(k => ({ value: k, label: TIME_VARIABLES[k] }))
      result.push({ value: 'specific', label: 'Specific value' })
      return result
    },
  },
  methods: {
    onSelectSpecificValue() {
      this.chosenVariable = 'specific'
      this.$nextTick(() => {
        this.$refs.datetimePicker?.focus?.()
      })
    },
  },
}
</script>

<template lang="pug">
ea-select.select-nowrap(
  :label="label"
  :name="`${name}.variablesSelector`"
  :options="variablesSelectorOptions"
  :reduce="item => item.value"
  :skipDirtyCheck="skipDirtyCheck"
  v-model="chosenVariable"
  input-size="sm"
  select-label="label"
)
  template(v-if="chosenVariable != 'specific'" #input-prepend)
    a.d-flex.align-items-center.justify-content-center.p-2(v-tooltip="'Specific value'")
      i.fa.fa-calendar.text-primary.cursor-pointer(@click="onSelectSpecificValue")

separated-datetimepicker(
  ref="datetimePicker"
  v-if="chosenVariable == 'specific'"
  :name="name"
  :skipDirtyCheck="skipDirtyCheck"
  :timeZone="$store.state.session.timeZone"
  v-model="chosenTimestamp"
  disableLabel
  input-size="sm"
)
</template>
