import { createGlobalState } from '@vueuse/core'
import { useResourcePaginatedList } from 'frontend/common/useResourcePaginatedList'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import { VariablesFields } from 'frontend/variables/config/VariablesFields'
import { denormalize } from 'normalizr'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export const useVariables = createGlobalState(() => {
  // path: `variables/index`,
  const { fetchItems, list, schema } = useResourcePaginatedList({
    dataModelType: DataModelType.Variable,
    path: `events/${useRoute().params.eventSlug}/variables/index`,
    defaultSort: `name asc`,
    reflectRouteQuery: null,
    prefilters: {},
    verboseSubscriptions: true,
    componentName: 'variables',
    standalone: null,
    disableCustomizableColumns: true,
    checkAndInjectOnCreate: true,
    instantDelete: true,
    fields: VariablesFields,
    subscriptions: {
      items: 'VariablesChannel',
    },
    perPage: 10000,
  })

  const variableList = computed(() => {
    return denormalize(list.value.result, schema.value, list.value.entities).items
    // return list.value.result?.items?.map(itemId => {
    //   return denormalize(itemId, (schema.value?.items || [])[0] || schema.value, list.value.entities)
    // })
  })

  const variableByIdMap = computed(() => {
    const map = new Map()
    for (const item of variableList.value) {
      map.set(item.id, item)
    }
    return map
  })

  return {
    variableList,
    variableByIdMap,
    fetchItems,
  }
})
