//**  Super simple and naive in memory cache to avoid refetching of validations
// Lives until page refresh
//
// Used for caching getValidations requests
// */

import { Dayjs } from 'dayjs'

export class CacheLayerSingleton {
  _cache = new Map<string | symbol, any>()
  static #instance: CacheLayerSingleton

  static defaultExpire = 300 // 5 mins
  static runCleanup = true // 5 mins
  static cleanupInterval = 100 // 100 seconds

  #cacheCleanup

  constructor() {
    if (CacheLayerSingleton.#instance) {
      return CacheLayerSingleton.#instance
    }

    if (CacheLayerSingleton.runCleanup) {
      this.#cacheCleanup = setInterval(() => {
        this._cache.forEach((value, key) => {
          if (value.expire < dayjs().unix()) {
            this.delete(key)
          }
        })
      }, CacheLayerSingleton.cleanupInterval * 1000)
    }

    CacheLayerSingleton.#instance = this
  }

  get(key: string) {
    const cacheHit = this._cache.get(key)
    if (cacheHit) {
      if (cacheHit.expire && cacheHit.expire < dayjs().unix()) {
        this.delete(key)
        return undefined
      }
    }
    return cacheHit
  }

  set(
    key: string,
    value: any,
    {
      expireAt,
      expireAfterSeconds,
    }: { expireAt?: Dayjs | DateIsoString; expireAfterSeconds?: number } = {},
  ): number {
    let expire
    if (expireAt) {
      expireAt ? dayjs(expireAt).unix() : undefined
    } else if (expireAfterSeconds > 0) {
      expire = dayjs().add(expireAfterSeconds, 'seconds').unix()
    } else {
      expire = dayjs().add(CacheLayerSingleton.defaultExpire, 'seconds').unix()
    }

    const cachedAt = dayjs().unix()
    this._cache.set(key, { value, cachedAt, expire })

    return cachedAt
  }

  delete(key: string | symbol) {
    this._cache.delete(key)
  }

  has(key: string) {
    const cacheHit = this.get(key)
    if (cacheHit) {
      if (cacheHit.expire && cacheHit.expire < dayjs().unix()) {
        this.delete(key)
        return false
      }
      return true
    }
  }

  clear() {
    this._cache.clear()
  }

  dispose() {
    this.clear()
    this.#cacheCleanup.clearInterval()
  }
}
