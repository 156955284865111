export const extractTransferGroupClients = item => {
  const { clients, tbsWithClientGroup, unnamed } = item.transportBookings.reduce(
    (acc, tb) => {
      if (tb.client) {
        acc.clients.push(tb.client)
      } else if (tb?.clientGroup) {
        acc.tbsWithClientGroup.push(tb)
      } else {
        acc.unnamed++
      }

      return acc
    },
    { clients: [], tbsWithClientGroup: [], unnamed: 0 },
  )

  return [
    ...clients,
    ...tbsWithClientGroup.map(tb => ({
      name: tb.clientGroup.name,
      surname: `${tb.numberOfClients} x`,
    })),
    ...(unnamed > 0 ? [{ name: 'unnamed', surname: `${unnamed} x` }] : []),
  ]
}
