<script setup lang="ts">
import { sanitizer } from 'frontend/_config/sanitize-html'
import computeSchema from 'frontend/_globals/compute-schema'
import { UsedCriteriaOutlineColorType } from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaOutlineColorType'
import UsedCriteriaPreviewValueWrapperComponent from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaPreviewValueWrapperComponent.vue'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { useVariables } from 'frontend/variables/composables/useVariables'
import { denormalize } from 'normalizr'
import { computed, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'

interface Props {
  modelValue: string | string[]
  criterion: ISearchCriteriaNode
  predicate: string
}
const props = defineProps<Props>()

const store = useStore()
const { variableByIdMap } = useVariables()
const apiProvider = inject('axios')

const currentEvent = computed(() => {
  return store.getters['currentContext/currentEvent']
})

const fetchedHumanizedValues = ref(null)

const isNegative = computed(() => {
  return (
    (props.predicate?.startsWith('not_') && !props.predicate?.startsWith('not_null')) ||
    props.predicate?.startsWith('null') ||
    props.predicate?.startsWith('blank')
  )
})
const outlineColorType = computed(() => {
  return isNegative.value
    ? UsedCriteriaOutlineColorType.Negative
    : UsedCriteriaOutlineColorType.Positive
})

const filterValuesArray = computed(() => {
  return Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]
})

const filterValuesExceptVars = computed(() => {
  return filterValuesArray.value.filter(value => {
    return !value.startsWith('var:')
  })
})

const varFilterValues = computed(() => {
  return filterValuesArray.value.filter(value => {
    return value.startsWith('var:')
  })
})

const humanizedValues = computed(() => {
  if (!props.criterion?.fetchUrl) {
    return (
      filterValuesArray.value?.map(element => {
        return {
          isHtml: !!element.labelHtml,
          label: element.labelHtml || element.label,
        }
      }) || []
    )
  }
  if (!fetchedHumanizedValues.value) {
    return null
  }
  return fetchedHumanizedValues.value
    .map(element => {
      return {
        isHtml: !!element.labelHtml,
        label: element.labelHtml || element.label,
        originalValue: element.id,
      }
    })
    .concat(
      varFilterValues.value.map(value => {
        const [, idOfVariable] = value.split('var:')
        const variable = variableByIdMap.get(idOfVariable)
        const variableName = variable ? variable.name || 'Untitled variable' : 'Variable removed'
        return {
          isHtml: false,
          isVariable: true,
          label: `${variableName}`,
          originalValue: value,
        }
      }),
    )
})

async function fetchHumanizedFilterValue() {
  const properUrl = props.criterion.fetchUrl
    .replace('{{eventId}}', currentEvent.value?.id)
    .replace('{{eventSlug}}', currentEvent.value?.slug)
  const payload = {
    sorts: 'lower_name',
    eventId: currentEvent.value?.id,
    idIn: filterValuesExceptVars.value,
  }
  try {
    const response = await apiProvider.post(properUrl, payload)
    const schema = computeSchema([], response.data.mappings)
    fetchedHumanizedValues.value = denormalize(
      response.data.result,
      schema,
      response.data.entities,
    ).items
  } catch (error) {
    console.error('error', error)
  }
}

watch(
  () => {
    return props.modelValue
  },
  () => {
    if (!filterValuesExceptVars.value.length) {
      fetchedHumanizedValues.value = null
    }
    if (props.criterion?.fetchUrl && filterValuesExceptVars.value.length) {
      fetchedHumanizedValues.value = null
      fetchHumanizedFilterValue()
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <UsedCriteriaPreviewValueWrapperComponent :outline-color-type="outlineColorType">
    <span v-if="humanizedValues" class="items">
      <span v-for="(value, i) in humanizedValues" :key="i" class="item">
        <span>{{ i > 0 ? '; ' : '' }}</span>
        <span v-if="value.isHtml" v-html="sanitizer.process(value.label)"></span>
        <span v-else>{{ value.label }}</span>
      </span>
    </span>
    <div v-else class="loading-wrapper">
      <!-- <LoadingComponent /> -->
      ...
    </div>
  </UsedCriteriaPreviewValueWrapperComponent>
</template>
