<script>
export default {
  name: 'LimitedComponentsList',
  props: {
    items: { type: Array, default: () => [] },
    limit: { type: Number, default: 3 },
    allowWrap: { type: Boolean, default: false },
    component: { type: [Object, String] },
    componentFactory: { type: Function },
    componentProps: { type: Function, default: () => el => ({ item: el }) },
  },
}
</script>

<template lang="pug">
.small(
  v-for="(item, index) of (items || []).slice(0,limit)"
  :class="{ 'text-nowrap': !allowWrap }"
  :key="index"
)
  template(v-if="componentFactory")
    component(v-bind="componentProps(item)" :is="componentFactory(item)")
  template(v-else)
    component(v-bind="componentProps(item)" :is="component")
.small.text-black-50(v-if="(items || []).length > limit")
  .text-nowrap and {{ (items || []).length - limit }} more...
  .text-nowrap (click row details to see all)
.small.text-black-15(v-else-if="!(items || []).length") empty
</template>
