<script setup lang="ts">
import dayjs from 'dayjs'
import { sanitizer } from 'frontend/_config/sanitize-html'
import { UsedCriteriaOutlineColorType } from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaOutlineColorType'
import UsedCriteriaPreviewValueWrapperComponent from 'frontend/common/data-tables/search-criteria/components/UsedCriteriaPreviewValueWrapperComponent.vue'
import { TIME_VARIABLES } from 'frontend/common/search-criterion/_search-criterion-predicate-config'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import LoadingComponent from 'frontend/ui/components/LoadingComponent.vue'
import { capitalize } from 'lodash'
import { computed } from 'vue'
import { useStore } from 'vuex'

interface Props {
  modelValue: string | string[] | boolean | number
  criterion: ISearchCriteriaNode
  predicate: string | null
}
const props = defineProps<Props>()

const store = useStore()

const isNegative = computed(() => {
  return (
    (props.predicate?.startsWith('not_') && !props.predicate?.startsWith('not_null')) ||
    props.predicate?.startsWith('null') ||
    props.predicate?.startsWith('blank')
  )
})
const outlineColorType = computed(() => {
  return isNegative.value
    ? UsedCriteriaOutlineColorType.Negative
    : UsedCriteriaOutlineColorType.Positive
})

const humanizedValues = computed(() => {
  const result = props.modelValue
  if (
    result.constructor == String &&
    (result.search(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/gi) == 0 ||
      result.search(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/gi) == 0 ||
      dayjs.isDayjs(result))
  ) {
    if (props.criterion?.format) {
      return [
        {
          isHtml: false,
          label: dayjs(result).tz(store.state.session.timeZone).format(props.criterion?.format),
        },
      ]
    } else {
      return [
        {
          isHtml: false,
          label: dayjs(result).tz(store.state.session.timeZone).format('DD/MM/YYYY HH:mm'),
        },
      ]
    }
  }
  if (result.constructor == String && Object.keys(TIME_VARIABLES).includes(result)) {
    return [
      {
        isHtml: false,
        label: TIME_VARIABLES[result],
      },
    ]
  }
  if (result.constructor == String && ['yes', 'no'].includes(result)) {
    return [
      {
        isHtml: false,
        label: capitalize(result),
      },
    ]
  }
  if (result.constructor == Array && props.criterion?.collection) {
    return props.criterion?.collection
      .filter(el => result.includes(el.value))
      .map(el => {
        return {
          isHtml: false,
          label: el.label,
        }
      })
  }
  if (result.constructor == String && props.criterion?.collection) {
    const potentialResult = props.criterion?.collection.find(el => result == el.value)?.label
    if (potentialResult?.length) {
      return [potentialResult]
    }
  }
  if (result.constructor == Array) {
    return result.map(el => {
      return {
        isHtml: false,
        label: el,
      }
    })
  }
  if (result.constructor == Boolean) {
    const trueLabel = 'YES'
    const falseLabel = 'NO'
    return [
      {
        isHtml: false,
        label: result ? trueLabel : falseLabel,
      },
    ]
  }
  if (result.constructor == String && ['true', 'false'].includes(result)) {
    return [
      {
        isHtml: false,
        label: result == 'true' ? 'YES' : 'NO',
      },
    ]
  }
  return [
    {
      isHtml: false,
      label: result,
    },
  ]
})
</script>

<template>
  <UsedCriteriaPreviewValueWrapperComponent :outline-color-type="outlineColorType">
    <span v-if="humanizedValues" class="items">
      <span v-for="(value, i) in humanizedValues" :key="i" class="item">
        <span>{{ i > 0 ? '; ' : '' }}</span>
        <span v-if="value.isHtml" v-html="sanitizer.process(value.label)"></span>
        <span v-else>{{ value.label }}</span>
      </span>
    </span>
    <div v-else class="loading-wrapper">
      <LoadingComponent />
    </div>
  </UsedCriteriaPreviewValueWrapperComponent>
</template>

<style scoped lang="scss"></style>
