<script>
import mapbox from 'mapbox-gl'
import { computed, inject, onMounted, onUnmounted, watch } from 'vue'

import LocationMarker from './location-marker/location-marker.vue'

export default {
  name: 'Route',
  inheritAttrs: false,
  emits: ['route:removed', 'route:added'],
  props: {
    layerId: {
      type: String,
      default: () =>
        `route-${Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')}`,
    },
    routeGeometry: {
      type: Object,
    },
    geojson: {
      type: Object,
    },
    properties: { type: Object },
    focusOnShow: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const map = inject('map')

    onMounted(() => {
      addRoute(routeGeojson.value)
    })

    onUnmounted(() => {
      removeRoute()
    })

    const routeGeojson = computed(() => {
      return (
        props.geojson || {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: props.routeGeometry,
          },
        }
      )
    })

    watch(routeGeojson, value => {
      if (value) {
        updateRoute(value)
      } else {
        removeRoute()
      }
    })

    const routeCoordinates = computed(() => routeGeojson.value?.data?.geometry?.coordinates || [])
    const firstPoint = computed(() => routeCoordinates.value[0])
    const lastPoint = computed(() => routeCoordinates.value[routeCoordinates.value.length - 1])

    const markerOptions = computed(() => routeGeojson.value?.data?.properties?.markerOptions || {})

    function fitRouteToView(coordinates) {
      if (!coordinates?.length) return

      const bounds = new mapbox.LngLatBounds(coordinates[0], coordinates[0])

      for (const coord of coordinates) {
        bounds.extend(coord)
      }

      map.value?.fitBounds(bounds, {
        padding: 20,
        duration: 600,
      })
    }

    function updateRoute(geojson) {
      const source = map.value.getSource(props.layerId)
      if (source) {
        source.setData(geojson.data)
      } else {
        addRoute(geojson)
      }
    }

    function removeRoute() {
      if (map.value.getLayer(props.layerId)) map.value.removeLayer(props.layerId)
      if (map.value.getLayer(`${props.layerId}:outline`))
        map.value.removeLayer(`${props.layerId}:outline`)
      if (map.value.getSource(props.layerId)) map.value.removeSource(props.layerId)

      emit('route:removed')
    }

    function addRoute(geojson) {
      if (!geojson) return

      map.value.addSource(props.layerId, geojson)

      map.value.addLayer({
        id: `${props.layerId}:route`,
        type: 'line',
        source: props.layerId,
        layout: {},
        paint: {
          'line-color': ['coalesce', ['get', 'line-color'], '#749BEF'],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            ['literal', 4],
            15,
            ['literal', 5],
            17,
            ['literal', 6],
            18,
            ['literal', 8],
          ],
        },
      })

      if (props.focusOnShow) {
        fitRouteToView(geojson.data.geometry.coordinates)
      }

      emit('route:added')
    }

    return {
      firstPoint,
      lastPoint,
      markerOptions,
    }
  },
  components: {
    LocationMarker,
  },
}
</script>

<template lang="pug">
slot
  location-marker(
    v-if="firstPoint"
    :coordinates="firstPoint"
    :options="{ label: 'A', scale: 0.75, ...markerOptions }"
  )
  location-marker(
    v-if="lastPoint"
    :coordinates="lastPoint"
    :options="{ label: 'B', scale: 0.75, ...markerOptions }"
  )
</template>
