import axios from 'frontend/_config/axios'
import store from 'frontend/_stores'

import { CacheLayerSingleton } from './cache-layer'

export class ValidationService {
  constructor(
    { apiProvider = axios, baseUrl, blocker, validationUrl = `${baseUrl}/validations` },
    cacheEnabled = true,
  ) {
    this.baseUrl = baseUrl
    this.apiProvider = apiProvider
    this.blocker = blocker
    this.validationUrl = validationUrl
    if (cacheEnabled) {
      this.cacheLayer = new CacheLayerSingleton()
    }
  }

  getValidations({ validationUrl = this.validationUrl }) {
    if (this.cacheLayer?.has(validationUrl)) {
      return Promise.resolve(this.cacheLayer.get(validationUrl).value)
    }

    if (this.blocker) {
      this.blocker.validation = true
    }

    return this.apiProvider
      .get(validationUrl)
      .then(({ data }) => {
        this.cacheLayer.set(validationUrl, data)

        return data
      })
      .catch(error => {
        console.error('validation service error', error)
        store.dispatch('toast/error', { message: 'Could not fetch validations' })
      })
      .finally(() => {
        if (this.blocker) {
          delete this.blocker.validation
        }
      })
  }
}
