<script setup lang="ts">
import { InterfaceType } from 'frontend/_setup/setupContainer/constants/InterfaceType'
import ColumnItemComponent from 'frontend/columns/components/ColumnItemComponent.vue'
import { ColumnSortDirection } from 'frontend/columns/enum/ColumnSortDirection'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'
import { useToggleMultiSort } from 'frontend/common/composables/useToggleMultiSort'
import { useContainer } from 'frontend/container/composables/useContainer'
import { IDataModelTypeService } from 'frontend/dataModels/services/DataModelTypeService/types/IDataModelTypeService'
import InputWithIconComponent from 'frontend/roles/components/InputWithIconComponent.vue'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import InputComponent from 'frontend/ui/components/InputComponent.vue'
import { computed, ref, watch } from 'vue'
import Draggable from 'vuedraggable'

interface Props {
  // availableColumns?: unknown
  enabledColumnKeys: string[]
  dataModelType: DataModelType
  sortItems: IColumnSortItems
  columnItemsWrapperStyle?: Record<string, string>
  isMultiSortEnabled?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  columnItemsWrapperStyle() {
    return {}
  },
  isMultiSortEnabled: false,
})

interface Emits {
  (e: 'update:enabledColumnKeys', value: string[]): void
  (e: 'update:sortItems', value: IColumnSortItems): void
}
const emit = defineEmits<Emits>()

const { container } = useContainer()
const dataModelTypeService = container.get<IDataModelTypeService>(
  InterfaceType.services.DataModelTypeService,
)

const enabledColumnKeysModel = computed<string[]>({
  get() {
    return props.enabledColumnKeys
  },
  set(value: string[]) {
    emit('update:enabledColumnKeys', value)
  },
})
const sortItemsModel = computed<IColumnSortItems>({
  get() {
    return props.sortItems
  },
  set(value: IColumnSortItems) {
    emit('update:sortItems', value)
  },
})

const isMultiSortEnabled = computed(() => {
  return props.isMultiSortEnabled
})

const { toggleMultiSort } = useToggleMultiSort(sortItemsModel, isMultiSortEnabled)

const isDragging = ref(false)
const search = ref('')

const availableColumns = computed(() => {
  // const result = props.availableColumns?.length
  //   ? ref(props.availableColumns)
  //   : dataModelTypeService.getAvailableColumnItems(props.dataModelType)
  const result = dataModelTypeService.getAvailableColumnItems(props.dataModelType)
  return result
})

const columnConfig = computed(() => {
  return dataModelTypeService.getColumnConfig(props.dataModelType)
})

const enabledColumns = computed(() => {
  return enabledColumnKeysModel.value.map(key => {
    return {
      key,
      ...availableColumns.value.find(column => column.key === key),
      sortDirection: sortItemsModel.value.find(([sortKey]) => sortKey === key)?.[1] || null,
      sortIndex:
        sortItemsModel.value.length > 1
          ? sortItemsModel.value.findIndex(([sortKey]) => sortKey === key)
          : -1,
      canSort: !!columnConfig.value[key]?.header?.sort,
    }
  })
})

const disabledColumnKeys = computed(() => {
  return availableColumns.value
    .filter(column => !enabledColumnKeysModel.value.includes(column.key))
    .filter(column => {
      return (
        column.name.toLowerCase().includes(search.value.toLowerCase()) ||
        column.key.toLowerCase().includes(search.value.toLowerCase())
      )
    })
    .map(column => column.key)
})
const disabledColumns = computed(() => {
  return disabledColumnKeys.value.map(key =>
    availableColumns.value.find(column => column.key === key),
  )
})

watch(enabledColumnKeysModel, () => {
  if (enabledColumnKeysModel.value.length === 0) {
    sortItemsModel.value = []
  } else {
    const filteredSortItems = sortItemsModel.value?.filter(([columnKey]) => {
      return enabledColumnKeysModel.value.includes(columnKey)
    })
    if (!filteredSortItems?.length) {
      sortItemsModel.value = [[enabledColumnKeysModel.value[0], ColumnSortDirection.Ascending]]
    } else if (filteredSortItems.length !== sortItemsModel.value.length) {
      sortItemsModel.value = filteredSortItems
    }
  }
})

function toggleActiveColumn(key: string) {
  if (enabledColumnKeysModel.value.includes(key)) {
    enabledColumnKeysModel.value = enabledColumnKeysModel.value.filter(item => item !== key)
  } else {
    enabledColumnKeysModel.value = [...enabledColumnKeysModel.value, key]
  }
}
</script>

<template>
  <div class="visible-column-editor">
    <div class="column-group">
      <div class="title">Columns currently <span class="highlight">in use</span></div>
      <div
        class="column-items-wrapper"
        :class="{ 'is-dragging': isDragging }"
        :style="columnItemsWrapperStyle"
      >
        <Draggable
          v-model="enabledColumnKeysModel"
          :animation="200"
          :disabled="false"
          ghost-class="ghost"
          item-key=""
          class="column-items"
          group="enabled-columns"
          @start="isDragging = true"
          @end="isDragging = false"
        >
          <template #item="{ index }">
            <ColumnItemComponent
              :name="enabledColumns[index].name"
              is-active
              should-show-sort
              :should-enable-sort="enabledColumns[index].canSort"
              :sort-direction="enabledColumns[index].sortDirection"
              :sort-index="enabledColumns[index].sortIndex"
              can-reorder
              @toggle-click="toggleActiveColumn(enabledColumns[index].key)"
              @toggle-sort="toggleMultiSort(enabledColumns[index].key)"
            />
          </template>
          <template #footer>
            <div class="empty">
              Enable columns by dragging them there
              <slot name="afterEmptyEnabledColumns" />
            </div>
          </template>
        </Draggable>
      </div>
    </div>
    <div class="column-group has-search">
      <div class="title">Columns <span class="highlight">available</span> to use</div>
      <InputWithIconComponent>
        <InputComponent v-model="search" placeholder="Search for columns" />
      </InputWithIconComponent>

      <div
        class="column-items-wrapper"
        :class="{ 'is-dragging': isDragging }"
        :style="columnItemsWrapperStyle"
      >
        <Draggable
          :animation="200"
          :disabled="false"
          :model-value="disabledColumnKeys"
          ghost-class="ghost"
          item-key=""
          class="column-items"
          group="enabled-columns"
          :sort="false"
          @start="isDragging = true"
          @end="isDragging = false"
        >
          <template #item="{ index }">
            <ColumnItemComponent
              :name="disabledColumns[index].name"
              can-reorder
              @toggle-click="toggleActiveColumn(disabledColumns[index].key)"
            />
          </template>
          <template #footer>
            <div class="empty">
              You have already enabled all columns, that's why it's so quiet here.
            </div>
          </template>
        </Draggable>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.visible-column-editor {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.column-items-wrapper {
  max-height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
}

.column-items {
  display: grid;
  gap: 2px;
  min-height: 100%;
  align-content: flex-start;
  position: relative;
}

.column-group {
  display: grid;
  gap: 4px;
  align-content: flex-start;
  grid-template-rows: auto 1fr;

  &.has-search {
    grid-template-rows: auto auto 1fr;
  }

  .title {
    font-weight: 600;
  }
}

.empty {
  color: hsl(200deg, 5%, 50%);
  position: absolute;
  pointer-events: none;
  transition: all 0.25s cubic-bezier(0, 0.6, 0.4, 1);
  opacity: 1;
  height: 100%;
  border-radius: 8px;

  .column-items:has(.column-item) & {
    opacity: 0;
  }

  border: 2px dashed transparent;

  .is-dragging & {
    border: 2px dashed hsl(200deg, 5%, 85%);
    padding: 8px;
    box-sizing: border-box;
  }
}
</style>
