<script setup lang="ts">
import { ClientGroup } from 'frontend/client-groups'
import { Client } from 'frontend/clients'
import { computed } from 'vue'

interface Props {
  transportBooking: any
}

const props = defineProps<Props>()

const hasUnnamedPassengers = computed(() => {
  const tb = props.transportBooking
  return !tb.client && !tb.clientGroup
})
</script>

<template lang="pug">
.d-flex.align-items-start.justify-content-start
  .flex-1
    slot(:client="transportBooking.client" name="clients")
      template(v-if="transportBooking.client")
        client(:client="transportBooking.client" :disabled="!transportBooking.client?.id")
    slot(:clientGroup="transportBooking.clientGroup" name="clientGroups")
      template(v-if="transportBooking.clientGroup")
        .text-nowrap
          span.small.bold {{ transportBooking.numberOfClients }} x&nbsp;
          client-group(:clientGroup="transportBooking.clientGroup")
    slot(name="unnamed")
      .text-nowrap(v-if="hasUnnamedPassengers")
        span.me-1 {{ transportBooking.numberOfClients || 1 }}
        span(v-if="transportBooking.numberOfClients > 1") unnamed clients
        span(v-else) unnamed client
</template>
