import { checkAccessToPath, isAdmin } from 'frontend/common/access-helpers'
import { computed, type ComputedRef, provide } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export type CanType<T = boolean> = (actionOrPath: string, dataModelType?: string) => T
export type CanSeePathType<T = boolean> = (path: string) => T

export interface IUseEventAccess {
  currentEou: ComputedRef
  can: CanType
  canSeePath: CanSeePathType
}

export function useEventAccess(): IUseEventAccess {
  const route = useRoute()
  const store = useStore()

  const currentEou = computed(() => {
    const user = store.state.session.user
    if (user?.impersonatedCurrentOrganizationUser) {
      return (user?.impersonatedCurrentOrganizationUser?.activeEventOrganizationUsers || []).find(
        eou => eou.event?.slug == route.params.eventSlug,
      )
    } else {
      return (user?.currentOrganizationUser?.activeEventOrganizationUsers || []).find(
        eou => eou.event?.slug == route.params.eventSlug,
      )
    }
    // return (user?.currentOrganizationUser?.activeEventOrganizationUsers || []).find(
    //   eou => eou.event?.slug == route.params.eventSlug,
    // )
  })

  const computedIsAdmin = computed(() => {
    return isAdmin(currentEou.value)
  })

  const canSeePath: CanSeePathType = path => {
    return checkAccessToPath(path, currentEou)
  }

  const can: CanType = (actionOrPath, dataModelType = null) => {
    if (computedIsAdmin.value) {
      return true
    } else if (!dataModelType) {
      return checkAccessToPath(currentEou.value, actionOrPath)
    } else {
      return !![
        ...(currentEou.value?.role?.roleScopes || []),
        ...(currentEou.value?.additionalRoleScopes || []),
      ]?.find(
        el => el.dataModelType == dataModelType && (el.actionTypes || []).includes(actionOrPath),
      )
    }
  }

  provide('isAdmin', computedIsAdmin)
  provide('can', can)
  provide('canSeePath', canSeePath)

  return {
    currentEou,
    can,
    canSeePath,
  }
}
