import {
  ClientCommunicationMessagesDeliverAt,
  ClientCommunicationMessagesSentAt,
  KINDS,
  STATES,
} from 'frontend/client-communication-messages'
import EffectiveChannelDisplay from 'frontend/client-communication-messages/effective-channel-display.vue'
import { ClientCommunicationRule, KINDS as RULE_KINDS } from 'frontend/client-communication-rules'
import { Client } from 'frontend/clients'
import FormattedTime from 'frontend/common/formatted-time.vue'
import Text from 'frontend/common/text.vue'
import TrueFalseLabel from 'frontend/common/true-false-label.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { Location } from 'frontend/locations'
import { EXTENDED_KINDS, TransportBooking } from 'frontend/transport-bookings'
import { TransportationPoint } from 'frontend/transportation-points'
import { markRaw } from 'vue'

const humanizedRuleKind = k => {
  return RULE_KINDS.find(el => el.value == k)?.label
}

const humanizedKind = k => {
  return KINDS.find(el => el.value == k)?.label
}

const humanizedState = k => {
  return STATES.find(el => el.value == k)?.label
}

const humanizedTbKind = ccm =>
  EXTENDED_KINDS.find(el => el.value == ccm.transportBooking.kind)?.label

export const ClientCommunicationMessageDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'aasm_state',
    'body',
    'deliver_at',
    'real_deliver_at',
    'kind',
    'sent_at',
    'original_sent_at',
    'sent_counter',
    'sending_counter',
    'title',
    'resend_cause',
    'updated_at',
    'created_at',
    'sent_to_recipient',
    { client_communication_rule: ['id', 'eid', 'kind', 'name'] },
    {
      transport_booking: [
        'id',
        'eid',
        'end_at',
        'start_at',
        'kind',
        'flight_number',
        'is_flight_private',
        'flight_class',
        { shift_job: ['id', 'start_at', 'end_at'] },
        {
          start_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
        },
        {
          client: [
            'id',
            'name',
            'surname',
            'archived_at',
            'email',
            'phone',
            'event_phone',
            'personal_assistant_email',
            { client_groups: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
          ],
        },
        {
          end_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
        },
        { start_transportation_point: ['id', 'name'] },
        { end_transportation_point: ['id', 'name'] },
      ],
    },
  ],
  subscriptions: {
    items: 'ClientCommunicationMessagesChannel',
    clientCommunicationRules: 'ClientCommunicationRulesSimpleChannel',
    transportBookings: 'TransportBookingsWithLocationsChannel',
    locations: 'LocationsSimpleChannel',
    transportationPoints: 'TransportationPointsSimpleChannel',
    shiftJobs: 'ShiftJobsFlatChannel',
    clients: 'ClientsSimpleChannel',
  },
  defaultColumns: [
    'aasmState',
    'transportBookingEid',
    'transportBooking',
    'transportBookingKind',
    'client',
    'clientEmail',
    'personalAssistantEmail',
    'clientPhone',
    'eventPhone',
    'channel',
    'rule',
    'type',
    'toBeDeliveredAt',
    'sentAt',
    'sentCounter',
    'sendingCounter',
    'resendCause',
  ],
  columnConfig: {
    aasmState: {
      label: 'State',
      header: {
        sort: 'aasmStateForSort',
      },
      cell: {
        kind: 'function',
        fn: ccm => humanizedState(ccm.aasmState),
      },
    },
    transportBookingEid: {
      label: 'TB EID',
      header: {
        sort: 'transportBookingEid',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.eid',
      },
    },
    transportBooking: {
      label: 'Transport Booking',
      header: {
        sort: 'transportBookingEid',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportBooking),
        componentProps: ccm => ({
          transportBooking: ccm.transportBooking,
          noClient: true,
        }),
      },
    },
    transportBookingKind: {
      label: 'Transport Booking Kind',
      header: {
        sort: 'transportBookingKind',
      },
      cell: {
        kind: 'function',
        fn: humanizedTbKind,
      },
    },
    transportBookingStartLocation: {
      label: 'Pick-up Location',
      header: {
        sort: 'transportBookingStartLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: ccm => ({
          location: ccm.transportBooking.startLocation,
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },
    transportBookingStartTransportationPoint: {
      label: 'Pick-up TP',
      header: {
        sort: 'transportBookingStartTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: ccm => ({
          transportationPoint: ccm.transportBooking.startTransportationPoint,
          kindIn: "['onsite', 'arrival', 'departure']",
          nowrap: true,
        }),
      },
    },
    transportBookingEndLocation: {
      label: 'Drop-off Location',
      header: {
        sort: 'transportBookingEndLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: ccm => ({
          location: ccm.transportBooking.endLocation,
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },
    transportBookingEndTransportationPoint: {
      label: 'Drop-off TP',
      header: {
        sort: 'transportBookingEndTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: ccm => ({
          transportationPoint: ccm.transportBooking.endTransportationPoint,
          kindIn: "['onsite', 'arrival', 'departure']",
          nowrap: true,
        }),
      },
    },
    transportBookingFlightNumber: {
      label: 'Travel No.',
      header: {
        sort: 'transportBookingFlightNumber',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.flightNumber',
      },
    },

    transportBookingPrivateJet: {
      label: 'Private Jet',
      header: {
        sort: 'transportBookingIsFlightPrivate',
      },
      cell: {
        kind: 'component',
        component: TrueFalseLabel,
        componentProps: item => ({ item: item.transportBooking.isFlightPrivate }),
      },
    },

    transportBookingFlightClass: {
      label: 'Flight class',
      header: {
        sort: 'transportBookingFlightClass',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.flightClass',
      },
    },
    client: {
      label: 'Client',
      header: {
        sort: 'transportBookingClientFullName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Client),
        componentProps: ccm => ({
          client: ccm.transportBooking.client,
        }),
      },
    },
    clientName: {
      label: 'Client name',
      header: {
        sort: 'transportBookingClientName',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.client.name',
      },
    },
    clientSurname: {
      label: 'Client surname',
      header: {
        sort: 'transportBookingClientSurname',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.client.surname',
      },
    },
    clientPhone: {
      label: 'Client phone',
      header: {
        sort: 'transportBookingClientPhone',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.client.phone',
      },
    },
    eventPhone: {
      label: 'Event phone',
      header: {
        sort: 'transportBookingClientEventPhone',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.client.eventPhone',
      },
    },
    clientEmail: {
      label: 'Client e-mail',
      header: {
        sort: 'transportBookingClientEmail',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.client.email',
      },
    },
    personalAssistantEmail: {
      label: 'PA e-mail',
      tooltip: 'Personal Assistant Email',
      header: {
        sort: 'transportBookingClientPersonalAssistantEmail',
      },
      cell: {
        kind: 'simple',
        path: 'transportBooking.client.personalAssistantEmail',
      },
    },
    channel: {
      label: 'Channel',
      header: {
        sort: 'clientCommunicationRuleKind',
      },
      cell: {
        kind: 'function',
        fn: ccm => humanizedRuleKind(ccm.clientCommunicationRule.kind),
      },
    },
    effectiveChannel: {
      label: 'Effective channel',
      header: {
        sort: 'sentToRecipient',
      },
      cell: {
        kind: 'component',
        component: markRaw(EffectiveChannelDisplay),
        componentProps: ccm => ({
          clientCommunicationMessage: ccm,
        }),
      },
    },
    rule: {
      label: 'Rule',
      header: {
        sort: 'clientCommunicationRuleName',
      },
      cell: {
        kind: 'component',
        component: markRaw(ClientCommunicationRule),
        componentProps: ccm => ({
          clientCommunicationRule: ccm.clientCommunicationRule,
        }),
      },
    },
    type: {
      label: 'Type',
      header: {
        sort: 'kind',
      },
      cell: {
        kind: 'function',
        fn: ccm => humanizedKind(ccm.kind),
      },
    },
    toBeDeliveredAt: {
      label: 'To be delivered at',
      header: {
        sort: ['computed_deliver_at', 'created_at'],
      },
      cell: {
        kind: 'component',
        component: markRaw(ClientCommunicationMessagesDeliverAt),
        componentProps: ccm => ({
          realDeliverAt: ccm.realDeliverAt,
          deliverAt: ccm.deliverAt,
        }),
      },
    },
    sentAt: {
      label: 'Sent at',
      header: {
        sort: 'sentAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(ClientCommunicationMessagesSentAt),
        componentProps: ccm => ({ ccm }),
      },
    },
    sentCounter: {
      label: 'Performed deliveries',
      header: {
        sort: 'sentCounter',
      },
      cell: {
        kind: 'simple',
        path: 'sentCounter',
      },
    },

    sendingCounter: {
      label: 'All delivery attempts',
      header: {
        sort: 'sendingCounter',
      },
      cell: {
        kind: 'simple',
        path: 'sendingCounter',
      },
    },

    resendCause: {
      label: 'Resend cause',
      header: {
        sort: 'resendCause',
      },
      cell: {
        kind: 'component',
        component: markRaw(Text),
        componentProps: ccm => ({ text: ccm.resendCause }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({ t: el.createdAt }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({
          t: el.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: el => ({
          t: el.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    messagePreview: {
      label: 'Simple message preview',
      cell: {
        class: 'text-pre-wrap w-400',
        kind: 'function',
        fn: ccm => {
          let result = ''
          if (!['sending', 'sent'].includes(ccm.aasmState)) {
            return 'not sent yet'
          }
          if (ccm.clientCommunicationRule?.kind == 'email') {
            result += `\nTitle: ${ccm.title}\nBody:\n${ccm.body}`
          } else {
            result += `${ccm.title}`
          }
          return result
        },
      },
    },
    updatedDatetime: {
      label: 'Updated Datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
        }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: markRaw(FormattedTime),
        componentProps: entry => ({
          t: entry.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  },
}
