import { useKeyboardShortcutStore } from 'frontend/keyboard/composables/useKeyboardShortcutStore'
import { IKeyboardShortcut } from 'frontend/keyboard/types/IKeyboardShortcut'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'

export function useKeyboardShortcuts(
  shortcuts: [IKeyboardShortcut['keys'], IKeyboardShortcut['callback']][],
  rule?: () => boolean,
  options?: {
    isGlobal?: boolean
  },
) {
  const targetOptions = {
    isGlobal: false,
    ...options,
  }
  const scope = Symbol()
  const keyboardShortcutStore = useKeyboardShortcutStore()
  const shortcutIds = ref<symbol[]>([])

  onMounted(() => {
    if (!rule || rule()) {
      registerShortcuts()
    }
  })

  onBeforeUnmount(() => {
    unregisterShortcuts()
  })

  const registerShortcuts = () => {
    keyboardShortcutStore.createScope(scope)
    shortcutIds.value = keyboardShortcutStore.registerShortcuts(
      shortcuts.map(shortcut => {
        return {
          keys: shortcut[0],
          callback: shortcut[1],
        }
      }),
      scope,
      targetOptions.isGlobal,
    )
  }

  const unregisterShortcuts = () => {
    keyboardShortcutStore.deleteShortcutsByIds(shortcutIds.value)
    keyboardShortcutStore.deleteScope(scope)
    shortcutIds.value = []
  }

  if (rule) {
    watch(rule, value => {
      if (value) {
        unregisterShortcuts()
        registerShortcuts()
      } else {
        unregisterShortcuts()
      }
    })
  }

  return {
    registerShortcuts,
    unregisterShortcuts,
  }
}
