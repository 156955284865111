<script>
import { ref } from 'vue'
// eslint-disable-next-line import/no-unresolved
// import { useRegisterSW } from 'virtual:pwa-register/vue'

export default {
  name: 'ServiceWorkerHandler',
  setup() {
    const needRefresh = ref(false)
    const showSpinner = ref(false)
    let serviceWorkerToUpdate = null
    let reloadOnUpdate = false

    if (!window?.navigator?.serviceWorker) return
    // removing other SWs
    navigator.serviceWorker.getRegistrations().then(registeredSWs => {
      registeredSWs.forEach(reg => {
        const oldSwPath = new URL(reg.scope).pathname.replace(/\//gi, '')
        if (oldSwPath.length > 0) {
          console.log('old service worker is being removed', reg.scope, oldSwPath)
          reg.unregister()
        }
      })
    })

    // registering global SW
    navigator.serviceWorker
      .register('/sw.js')
      .then(reg => {
        console.debug('%c SW REGISTERED!', 'color: #4d9375')

        if (!reg) return

        // activating SW if it is installed right after page load
        if (reg.waiting) {
          console.log(
            '%c SW registration is still waiting, we are installing immediately',
            'color: #d1b000',
          )
          serviceWorkerToUpdate = reg.waiting
          updateServiceWorker()
        }

        reg.addEventListener('updatefound', () => {
          if (reg.installing) {
            reg.installing.addEventListener('statechange', () => {
              if (reg.waiting) {
                serviceWorkerToUpdate = reg.waiting
                // if there's an existing controller (previous Service Worker), show the prompt
                if (navigator.serviceWorker.controller && reloadOnUpdate) {
                  needRefresh.value = true

                  // otherwise it's the first install, nothing to do
                } else {
                  console.log('Service Worker initialized for the first time or on page load')
                  updateServiceWorker()
                }
              }
            })
          }
        })

        // checking for update SW periodically
        setInterval(async () => {
          reloadOnUpdate = true
          await reg.update()
        }, 30 * 1000)
      })
      .catch(err => {
        console.warn('SW REGISTRATION ERROR!', err)
      })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (reloadOnUpdate) window.location.reload()
      if (showSpinner.value) showSpinner.value = false
      // if (needRefresh.value) needRefresh.value = false
    })

    const updateServiceWorker = () => {
      if (reloadOnUpdate) {
        showSpinner.value = true
      }
      serviceWorkerToUpdate.postMessage({ type: 'SKIP_WAITING' })
    }

    // const { needRefresh, updateServiceWorker } = useRegisterSW({
    //   immediate: true,
    //   onRegistered(reg) {
    //     console.log('%c SW REGISTERED!', 'color: #4d9375')
    //     if (reg?.waiting) {
    //       console.log('SW registration is still waiting, we are installing immediately')
    //       reg.waiting.postMessage({ type: 'SKIP_WAITING' })
    //     }
    //     reg &&
    //       setInterval(async () => {
    //         reloadOnUpdate = true
    //         await reg.update()
    //       }, 30 * 1000)
    //   },
    //   onRegisterError(err) {
    //     console.warn('SW REGISTRATION ERROR!', err)
    //   },
    //   // onNeedRefresh() {
    //   //   console.log('%c NEW APP VERSION CAME IN, REFRESH IS NEEDED!', 'color: #a26f10')
    //   // },
    // })
    const close = async () => {
      needRefresh.value = false
    }

    return {
      needRefresh,
      updateServiceWorker,
      close,
      showSpinner,
    }
  },
}
</script>

<template lang="pug">
.service-worker-container
  .sw-toast(v-if="needRefresh" role="alert")
    ea-spinner(v-if="showSpinner" :spinner-size="30" matchParent)
    .bold.mb-1
      | New version of TMM app is here
    .mb-1
      | Finish your task and hit the reload button or just refresh the page for the best experience.
    .d-flex.align-items-center.justify-content-end
      //- button.btn.btn-sm.btn-outline-warning.me-1(@click="close" type="button") Close
      button.btn.btn-sm.btn-primary(@click="updateServiceWorker(true)" type="button") Reload
</template>

<style scoped lang="scss">
.sw-toast {
  position: fixed;
  right: 1.1rem;
  bottom: 1.6rem;
  padding: 0.8rem;
  border: 2px solid var(--custom-primary);
  width: 400px;
  background: #fff;
  border-radius: 4px;
  z-index: 2;
  text-align: left;
  box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, 0.5);
}
</style>
