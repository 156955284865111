import { ColumnSortDirection } from 'frontend/columns/enum/ColumnSortDirection'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'

export function useSortItems() {
  function convertStoredSortToSortItems(sort: string): IColumnSortItems {
    if (!sort) {
      return []
    }
    return sort.split(',').map(sortItem => {
      const splittedSortItem = sortItem.split(':')
      const sortColumnKey = splittedSortItem[0]
      const sortDirection = splittedSortItem[1] as ColumnSortDirection

      return [sortColumnKey, sortDirection || ColumnSortDirection.Ascending]
    })
  }

  function convertSortItemsToStoredSort(sortItems: IColumnSortItems): string {
    try {
      if (sortItems && sortItems.length == 2 && sortItems[0].constructor == String) {
        return sortItems.join(':')
      } else {
        return (sortItems || [])
          .map(sortItem => {
            return sortItem.join(':')
          })
          .join(',')
      }
    } catch (e) {
      console.error('Error converting sort items to stored sort', e)
      return ''
    }
  }

  return {
    convertStoredSortToSortItems,
    convertSortItemsToStoredSort,
  }
}
