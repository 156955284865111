import axios from 'frontend/_config/axios'
import { MapProvidersService } from 'frontend/map-providers/map-providers-service'
import { computed, inject, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import { useBlocker } from '../use-blocker'

export const MapProviders = {
  GOOGLE_MAPS: 'GOOGLE_MAPS',
  FIFA_GIS: 'FIFA_GIS',
  MAPBOX: 'MAPBOX',
}

export const MAP_PROVIDERS = Object.values(MapProviders)

export function useMapProviders({ initialProvider, only = [], additionalProviders = [] } = {}) {
  const { blocker, isBlocked } = useBlocker()
  const availableMapProviders = ref(null)
  const providerChanged = ref(false)
  const localAxios = inject('axios', axios)
  const defaultMapProvider = useStore().getters['currentContext/defaultMapProvider']
  const mapProvider = ref(defaultMapProvider?.provider)
  const mapProvidersService = new MapProvidersService({ blocker, apiProvider: localAxios })
  const providers = MapProviders
  const $error = inject('$error')

  const setMapProvider = provider => {
    providerChanged.value = true
    mapProvider.value = provider
  }

  const fetchMapProviders = async (filters = {}) => {
    if (only?.length > 0) {
      filters.providerIn = only
    }

    try {
      const response = await mapProvidersService.index({
        data: {
          normalized: false,
          fields: ['id', 'name', 'provider', 'api_key', 'provider_config'],
          with_map_support: true,
          ...filters,
        },
      })
      availableMapProviders.value = [...additionalProviders, ...response.contents]

      if (initialProvider) {
        const provider = availableMapProviders.value.find(p => p.provider == initialProvider)
        if (provider) {
          mapProvider.value = initialProvider
        }
      } else if (!mapProvider.value && availableMapProviders.value?.length) {
        mapProvider.value = availableMapProviders.value[0].provider
      }
    } catch (e) {
      console.error(e)
      $error({ message: 'Error while resolving map providers' })
    }
  }

  const mapProviderConfig = computed(() => {
    return (
      availableMapProviders.value?.find(mp => mp.provider === mapProvider.value) ||
      defaultMapProvider
    )
  })

  function getProviderConfig(provider) {
    return availableMapProviders.value?.find(mp => mp.provider === provider)
  }

  onBeforeMount(() => fetchMapProviders())

  return {
    availableMapProviders,
    fetchMapProviders,
    isBlocked,
    mapProvider,
    mapProviderConfig,
    mapProvidersService,
    providers,
    providerChanged,
    setMapProvider,
    getProviderConfig,
  }
}
