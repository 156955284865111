<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'EventDashboardItem',
  emits: ['toggle-favorite'],
  props: {
    id: String,
    title: String,
    isDisabled: Boolean,
    isModal: Boolean,
    modalComponent: String,
    path: String,
    icon: String,
    activeItem: Object,
    firstItem: Object,
    inSpotlight: Boolean,
    favorites: Array,
    action: Function,
  },
  data() {
    return {
      modalFormItem: null,
    }
  },
  computed: {
    ...mapGetters('currentContext', ['currentEvent']),
  },
  watch: {
    activeItem: {
      handler(activeItem) {
        if (activeItem?.id == this.id) {
          this.$refs.dashboardItem.focus()
        } else {
          this.$refs.dashboardItem.blur()
        }
      },
    },
  },
  mounted() {
    this.$eventHub.$on('dashboardItemActivated', this.enterHit)
  },
  unmounted() {
    this.$eventHub.$off('dashboardItemActivated', this.enterHit)
  },
  methods: {
    enterHit(item) {
      if (item.id == this.id) {
        this.dashboardItemClicked()
      }
    },
    formModalClosed() {
      this.modalFormItem = null
    },
    favoriteClicked(event) {
      event.stopPropagation()
      event.preventDefault()
      this.$emit('toggle-favorite', this.path)
    },
    dashboardItemClicked(event = null) {
      if (event) {
        event.stopPropagation()
        event.preventDefault()
      }
      if (this.isDisabled) return

      if (typeof this.action === 'function') {
        this.action(this.currentEvent)
        this.$eventHub.$emit('close-spotlight')
      } else if (this.isModal) {
        this.$eventHub.$emit('close-spotlight')
        this.modalFormItem = {}
      } else {
        const currentPath = this.$route.path.split('/')

        if ((currentPath?.length || 0) > 4) {
          const eventBasePath = currentPath
          eventBasePath.length = 3
          this.$router.push(`${eventBasePath.join('/')}/${this.path}`)
          this.$eventHub.$emit('close-spotlight')
        } else {
          this.$router.push(this.path)
          this.$eventHub.$emit('close-spotlight')
        }
      }
    },
  },
  components: {
    ClientCategoriesModal: defineAsyncComponent(() =>
      import('frontend/client-categories/client-categories-modal.vue'),
    ),
    LocationFormModal: defineAsyncComponent(() =>
      import('frontend/locations/location-form-modal.vue'),
    ),
    ShiftFormModal: defineAsyncComponent(() => import('frontend/shifts/shift-form-modal.vue')),
    DepotFormModal: defineAsyncComponent(() => import('frontend/depots/depot-form-modal.vue')),
    ClientFunctionRemarksModal: defineAsyncComponent(() =>
      import('frontend/client-function-remarks/client-function-remarks-modal.vue'),
    ),
    ClientFunctionsModal: defineAsyncComponent(() =>
      import('frontend/client-functions/client-functions-modal.vue'),
    ),
    DriverImportFormModal: defineAsyncComponent(() =>
      import('frontend/driver-imports/driver-import-form-modal.vue'),
    ),
    VehicleImportFormModal: defineAsyncComponent(() =>
      import('frontend/vehicle-imports/vehicle-import-form-modal.vue'),
    ),
    OperationalPeriodsWizzardModal: defineAsyncComponent(() =>
      import(
        'frontend/calendar-entries/cluster-calendar-entries/operational-periods-wizzard-modal.vue'
      ),
    ),
    ClientGroupFormModal: defineAsyncComponent(() =>
      import('frontend/client-groups/client-group-form-modal.vue'),
    ),
    ClusterFormModal: defineAsyncComponent(() =>
      import('frontend/clusters/cluster-form-modal.vue'),
    ),
    DayLabelsModal: defineAsyncComponent(() => import('frontend/day-labels/day-labels-modal.vue')),
    DriverSourceFormModal: defineAsyncComponent(() =>
      import('frontend/driver-sources/driver-source-form-modal.vue'),
    ),
    DriverCategoriesModal: defineAsyncComponent(() =>
      import('frontend/driver-categories/driver-categories-modal.vue'),
    ),
    LocationTypesModal: defineAsyncComponent(() =>
      import('frontend/location-types/location-types-modal.vue'),
    ),
    OrganizationUsersModal: defineAsyncComponent(() =>
      import('frontend/organization-users/organization-users-modal.vue'),
    ),
    OslTbRuleFormModal: defineAsyncComponent(() =>
      import('frontend/osls/tb-rules/osl-tb-rule-form-modal.vue'),
    ),
    OslVRuleFormModal: defineAsyncComponent(() =>
      import('frontend/osls/v-rules/osl-v-rule-form-modal.vue'),
    ),
    OslDRuleFormModal: defineAsyncComponent(() =>
      import('frontend/osls/d-rules/osl-d-rule-form-modal.vue'),
    ),
    SubfleetWizzardModal: defineAsyncComponent(() =>
      import('frontend/calendar-entries/staging-area-calendar-entries/subfleet-wizzard-modal.vue'),
    ),
    TransportBookingFormModal: defineAsyncComponent(() =>
      import('frontend/transport-bookings/transport-booking-form-modal.vue'),
    ),
    BatchOnsiteRequest: defineAsyncComponent(() =>
      import('frontend/batch-onsite-requests/batch-onsite-request-form-modal.vue'),
    ),
    TransportBookingGroupingRuleFormModal: defineAsyncComponent(() =>
      import(
        'frontend/transport-booking-grouping-rules/transport-booking-grouping-rule-form-modal.vue'
      ),
    ),
    VehicleBrandsModal: defineAsyncComponent(() =>
      import('frontend/vehicle-brands/vehicle-brands-modal.vue'),
    ),
    VehicleCategoriesModal: defineAsyncComponent(() =>
      import('frontend/vehicle-categories/vehicle-categories-modal.vue'),
    ),
    VehicleModelsModal: defineAsyncComponent(() =>
      import('frontend/vehicle-models/vehicle-models-modal.vue'),
    ),
    VehicleSourceFormModal: defineAsyncComponent(() =>
      import('frontend/vehicle-sources/vehicle-source-form-modal.vue'),
    ),
    VehicleTypesModal: defineAsyncComponent(() =>
      import('frontend/vehicle-types/vehicle-types-modal.vue'),
    ),
    TransportationPointFormModal: defineAsyncComponent(() =>
      import('frontend/transportation-points/transportation-point-form-modal.vue'),
    ),
  },
}
</script>

<template lang="pug">
.dashboard-item-wrapper
  button.btn.btn-sm.btn-outline-primary.btn-light.dashboard-item.p-3.ripple(
    ref="dashboardItem"
    :class="{ active: activeItem?.id === id || (firstItem?.id === id && !activeItem?.id) }"
    :disabled="!!isDisabled"
    :name="modalComponent"
    @click="dashboardItemClicked($event)"
    @keydown.enter.stop.prevent
  )
    a.favorite(
      :class="{ active: activeItem?.id === id || (firstItem?.id === id && !activeItem?.id) }"
      @click="favoriteClicked($event)"
      v-tooltip="(favorites || []).includes(path) ? 'Remove from favorites' : 'Add to favorites'"
    )
      i.fa-star(:class="(favorites || []).includes(path) ? 'fas' : 'far'")

    .dashboard-item-icon
      i(:class="icon")
    span {{ title }}

template(v-if="modalFormItem")
  component(:is="modalComponent" @closed="formModalClosed($event)")
</template>
