<script setup lang="ts">
import CreateColumnPresetModalComponent from 'frontend/columns/components/CreateColumnPresetModalComponent.vue'
import TableColumnConfigModalComponent from 'frontend/columns/components/TableColumnConfigModalComponent.vue'
import { IColumnPresetResponseEntity } from 'frontend/columns/types/IColumnPresetResponseEntity'
import { IColumnSortItems } from 'frontend/columns/types/IColumnSortItems'
import { useModals } from 'frontend/common/modals'
import ButtonComponent from 'frontend/roles/components/ButtonComponent.vue'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import DropdownComponent from 'frontend/ui/components/DropdownComponent.vue'
import { computed, ref } from 'vue'

interface Props {
  displayedColumnNames: string[]
  defaultDisplayedColumnNames: string[]
  availableColumns: Record<string, string>
  hiddenColumns: string[]
  name?: string
  smallerIcon?: boolean
  isPresetUnsaved: boolean
  columnPresets: IColumnPresetResponseEntity[] | null
  currentColumnPresetId: string | true
  dataModelType: DataModelType
  unsavedColumnPresetIds: (string | true)[]
  sortItems: IColumnSortItems
  sort?: string | string[]
  currentPresetName: string
}
const props = withDefaults(defineProps<Props>(), {
  displayedColumnNames: () => [],
  defaultDisplayedColumnNames: () => [],
  availableColumns: () => ({}),
  hiddenColumns: () => [],
  name: '',
  smallerIcon: false,
  isPresetUnsaved: false,
  currentColumnPresetId: null,
  unsavedColumnPresetIds: () => [],
})

interface Emits {
  (e: 'update:displayedColumnNames', value: string[]): void
  (e: 'saveCurrentColumnPreset'): void
  (e: 'update:currentColumnPresetId', value: string | true): void
  (
    e: 'saveColumnPreset',
    payload: {
      columnPresetId: string | null
      name?: string
      onSuccessCallback?(): void
      onErrorCallback?(errors: Record<string, string[]>): void
    },
  ): void
  (e: 'restoreColumnPreset', columnPresetId: string | true): void
  (e: 'fetchColumnPresets'): void
  (e: 'update:sort', value: unknown): void
  (e: 'update:sortItems', value: IColumnSortItems): void
  (e: 'update:currentPresetName', value: string): void
}
const emit = defineEmits<Emits>()

useModals()

// TODO: Change to useModel
const currentColumnPresetIdModel = computed<string | true>({
  get() {
    return props.currentColumnPresetId
  },
  set(value: string | true) {
    emit('update:currentColumnPresetId', value)
  },
})
const displayedColumnNamesModel = computed({
  get() {
    return props.displayedColumnNames
  },
  set(value: string[]) {
    emit('update:displayedColumnNames', value)
  },
})
const sortModel = computed({
  get() {
    return props.sort
  },
  set(value) {
    emit('update:sort', value)
  },
})
const sortItemsModel = computed<IColumnSortItems>({
  get() {
    return props.sortItems
  },
  set(value) {
    emit('update:sortItems', value)
  },
})
const currentPresetNameModel = computed<string>({
  get() {
    return props.currentPresetName || ''
  },
  set(value) {
    emit('update:currentPresetName', value)
  },
})

function mapColumnPreset(columnPreset: IColumnPresetResponseEntity) {
  return {
    id: columnPreset.id,
    name: columnPreset.name,
    isActive: columnPreset.id === props.currentColumnPresetId,
    onClick() {
      currentColumnPresetIdModel.value = columnPreset.id
    },
  }
}
const mappedColumnPresets = computed(() => {
  return props.columnPresets.map(columnPreset => {
    return columnPreset
  })
})
const presetGroups = computed(() => {
  return [
    {
      title: 'My presets',
      items: mappedColumnPresets.value
        .filter(columnPreset => {
          return columnPreset.isGlobal === false
        })
        .map(mapColumnPreset),
    },
    {
      title: 'Global presets',
      items: mappedColumnPresets.value
        .filter(columnPreset => {
          return columnPreset.isGlobal === true
        })
        .map(mapColumnPreset)
        .concat([
          {
            id: true,
            name: 'System defaults',
            isActive: props.currentColumnPresetId === true,
            onClick() {
              currentColumnPresetIdModel.value = true
            },
          },
        ]),
    },
  ]
})

const isModalOpen = ref(false)
const currentlySavedColumnPresetId = ref(null)

function onSave(params: {
  name: string
  closeCallback(): void
  errorsCallback(errors: Record<string, string[]>): void
}) {
  const persistedCurrentlySavedColumnPresetId =
    currentlySavedColumnPresetId.value || currentColumnPresetIdModel.value
  emit('saveColumnPreset', {
    columnPresetId: persistedCurrentlySavedColumnPresetId,
    name: params.name,
    onSuccessCallback: () => {
      if (params.closeCallback) {
        params.closeCallback()
      }
    },
    onErrorCallback: (errors: Record<string, string[]>) => {
      if (params.errorsCallback) {
        params.errorsCallback(errors)
      }
    },
  })
}

function triggerSave(columnPresetId: string | true) {
  if (columnPresetId === true) {
    currentlySavedColumnPresetId.value = true
    return
  }
  const columnPreset = props.columnPresets.find(preset => preset.id === columnPresetId)
  if (columnPreset.isGlobal) {
    currentlySavedColumnPresetId.value = columnPresetId
  } else {
    emit('saveColumnPreset', {
      columnPresetId: columnPresetId,
    })
  }
}
</script>

<template>
  <TableColumnConfigModalComponent
    v-if="isModalOpen"
    :data-model-type="dataModelType"
    :column-presets="columnPresets"
    @fetch-column-presets="emit('fetchColumnPresets')"
    @exit="isModalOpen = false"
    :unsavedColumnPresetIds="unsavedColumnPresetIds"
    v-model:currentColumnPresetId="currentColumnPresetIdModel"
    v-model:displayedColumnNames="displayedColumnNamesModel"
    :isPresetUnsaved="isPresetUnsaved"
    @save="onSave"
    v-model:sort="sortModel"
    v-model:sortItems="sortItemsModel"
    v-model:currentPresetName="currentPresetNameModel"
    @restore="emit('restoreColumnPreset', currentColumnPresetIdModel)"
  />
  <CreateColumnPresetModalComponent
    v-if="currentlySavedColumnPresetId"
    @save="onSave"
    @exit="currentlySavedColumnPresetId = null"
  />
  <DropdownComponent placement="bottom-end" placement-strategy="flip">
    <template #default>
      <div class="dropdown-trigger-button btn btn-md btn-text with-icon" :name="name">
        <i class="mi-outlined mi-view-week" />
        Manage columns
        <div v-if="unsavedColumnPresetIds.length" class="unsaved-changes-indicator" />
      </div>
    </template>
    <template #dropdown="{ maxHeight, close }">
      <div class="dropdown-content">
        <div class="title">Column presets</div>
        <div class="preset-groups" :style="{ maxHeight: `${maxHeight - 100}px` }">
          <div v-for="(presetGroup, index) in presetGroups" :key="index" class="preset-group">
            <div class="preset-group-title">{{ presetGroup.title }}</div>
            <div class="preset-items">
              <div
                v-for="presetItem in presetGroup.items"
                :key="presetItem.id"
                class="preset-item"
                :class="{
                  'is-active': presetItem.isActive,
                  'has-unsaved-changes': unsavedColumnPresetIds.includes(presetItem.id),
                }"
              >
                <div class="preset-name" @click="presetItem.onClick">
                  <div v-if="unsavedColumnPresetIds.includes(presetItem.id)" class="dot" />
                  {{ presetItem.name }}
                </div>
                <div
                  v-if="unsavedColumnPresetIds.includes(presetItem.id)"
                  class="unsaved-changes-wrapper"
                >
                  <div class="button undo" @click="emit('restoreColumnPreset', presetItem.id)">
                    <i class="fas fa-undo" />
                  </div>
                  <div class="button save" @click="triggerSave(presetItem.id)">
                    <i class="fas fa-save" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <ButtonComponent
            type="secondary"
            icon-class="fas fa-pen"
            @click=";(isModalOpen = true), close()"
          >
            Manage presets
          </ButtonComponent>
        </div>
      </div>
    </template>
  </DropdownComponent>
</template>

<style scoped lang="scss">
.dropdown-content {
  padding: 12px;
  display: grid;
  gap: 8px;
  width: 280px;
}

.preset-group {
  display: grid;
  grid-template-rows: auto auto;
  gap: 4px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}
.preset-group {
  padding-left: 8px;
}
.preset-groups {
  display: grid;
  gap: 8px;
  overflow-y: auto;
}
.preset-group-title {
  font-size: 13px;
  font-weight: 600;
  color: hsl(0 0% 50%);
  text-transform: uppercase;
  font-size: 13px;
}

.current-config-indicator {
  color: var(--custom-primary);
  font-size: 13px;
  margin-right: 4px;
}

.save-button {
  padding: 0;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 14px;
  }
}

.dropdown-trigger-button {
  position: relative;
}

.unsaved-changes-indicator {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 12px;
  height: 12px;
  background: var(--custom-primary);
  border-radius: 100px;
}

.buttons {
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-auto-columns: auto;
}

.save-current-preset-button {
  width: 36px;
  padding: 0;
}

.preset-item {
  border-radius: 8px;
  display: grid;
  height: 28px;
  cursor: pointer;
  gap: 2px;
  overflow: hidden;
  justify-content: flex-start;
  grid-template-columns: 1fr;
  user-select: none;

  &.has-unsaved-changes {
    grid-template-columns: 1fr auto;
  }
}

.button.save,
.button.undo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 28px;

  .preset-item:hover &,
  .preset-item.is-active & {
    background: hsl(208deg, 57%, 95%);
    color: hsl(206deg, 58%, 55%);
  }

  &:hover {
    background: var(--custom-primary) !important;
    color: white !important;
  }
}

.preset-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding: 0 12px;

  .preset-item:hover &,
  .preset-item.is-active & {
    background: hsl(208deg, 57%, 95%);
    color: hsl(206deg, 58%, 55%);
  }
}

.unsaved-changes-wrapper {
  display: flex;
  transition: all 0.15s cubic-bezier(0.55, 0, 0.1, 1), pointer-events 0s ease 2s;
  gap: 2px;
}

.dot {
  border-radius: 100px;
  width: 8px;
  height: 8px;
  background: var(--custom-primary);
  margin-right: 4px;
}
</style>
