<script lang="ts">
import axios from 'frontend/_config/axios'
import { sanitizer } from 'frontend/_config/sanitize-html'
import computeSchema from 'frontend/_globals/compute-schema'
import store from 'frontend/_stores'
import { EACH_LIMIT, useInfiniteScroll, useResourceSelect } from 'frontend/common'
import { isUuid } from 'frontend/common/access-helpers'
import { ResourceDataModelType } from 'frontend/dataModels/enum/ResourceDataModelType'
import CreateVariableModalComponent from 'frontend/variables/components/CreateVariableModalComponent.vue'
import { useFilterScope } from 'frontend/variables/composables/useFilterScope'
import { useVariables } from 'frontend/variables/composables/useVariables'
import { uniq } from 'lodash'
import { denormalize } from 'normalizr'
import { computed, ref, toRefs, watch } from 'vue'

export default {
  name: 'ResourceSelectInput',
  emits: ['update:modelValue'],
  props: {
    config: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      // required: true,
    },
    modelValue: {
      type: Object,
    },
    skipDirtyCheck: {
      type: Boolean,
      default: false,
    },
    validators: {
      type: Object,
      default: null,
    },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
  setup(props) {
    // const resourceOptions = ref([])
    const currentEvent = computed(() => store.getters['currentContext/currentEvent'])

    const currentQuerySelector = ref(
      Object.keys(props.config.optionalQuerySelectors || {})[0] || null,
    )
    const { variableList, fetchItems } = useVariables()
    const filterScope = useFilterScope()
    const isCreateVariableModalVisible = ref(false)
    const currentQuerySelectionCounter = ref(null)

    const querySelectorOptions = computed(() => {
      if (props.config.optionalQuerySelectors) {
        const result = [
          ...Object.keys(props.config.optionalQuerySelectors).map(key => ({
            value: key,
            label:
              currentQuerySelector.value == key && currentQuerySelectionCounter.value
                ? `${props.config.optionalQuerySelectors[key]} (${currentQuerySelectionCounter.value})`
                : props.config.optionalQuerySelectors[key],
            activeClass: 'bg-success border-success',
          })),
        ]
        if (!result.find(el => el.label.startsWith('All'))) {
          result.push({
            value: 'all',
            label:
              currentQuerySelector.value == 'all' && currentQuerySelectionCounter.value
                ? `All (${currentQuerySelectionCounter.value})`
                : 'All',
            activeClass: 'bg-success border-success',
          })
        }
        return result
      } else {
        return null
      }
    })

    const fetchResourceOptions = async () => {
      if (props.config.fetchUrl) {
        const properUrl = props.config.fetchUrl
          .replace('{{eventId}}', currentEvent.value?.id)
          .replace('{{eventSlug}}', currentEvent.value?.slug)
        const payload = {
          sorts: [
            'label asc',
            'fleet_number_prefix asc',
            'fleet_number_int asc',
            'full_name asc',
            'lower_name asc',
            'name asc',
            'start_at asc',
          ],
          eventId: currentEvent.value?.id,
        }
        if (currentQuerySelector.value && currentQuerySelector.value != 'all') {
          payload[currentQuerySelector.value] = props.modelValue?.length ? props.modelValue : true
        } else if (querySelectorOptions.value?.length == 1) {
          payload[querySelectorOptions.value[0].value] = props.modelValue?.length
            ? props.modelValue
            : true
        }
        const response = await axios.post(properUrl, payload)
        currentQuerySelectionCounter.value = response.data.totalElements
        const schema = computeSchema([], response.data.mappings)
        const denormalized = denormalize(response.data.result, schema, response.data.entities).items
        let result = []

        if (Object.keys(denormalized[0] || {}).includes('selectionGroupName')) {
          uniq(denormalized.map(el => el.selectionGroupName))
            .sort()
            .forEach(groupName => {
              result = [
                ...result,
                { id: groupName, label: groupName, isOptgroup: true },
                ...denormalized.filter(el => el.selectionGroupName == groupName),
              ]
            })
        } else {
          result = denormalized
        }
        result.forEach(el => {
          if (!el.label && el.labelHtml) {
            el.label = el.labelHtml.replace(/(<([^>]+)>)/gi, ' ').replace(/\s{2,}/g, ' ')
          }
        })
        if (props.config.additionalCollection) {
          result = [...result, ...props.config.additionalCollection]
        }
        return result
      }
    }

    const { options, search, selectedItems, fetchAndSet } = useResourceSelect(
      {
        ...toRefs(props),
        collection: ref(false),
        optionsFilter: ref(null),
        reduce: el => el.id,
        labelFactory: el => el.label,
      },
      fetchResourceOptions,
      {
        withFilter: true,
        propNamesToPass: [],
      },
    )

    const resourceScope = computed(() => {
      return filterScope?.resourceDataModelType || ResourceDataModelType.Event
    })

    const shouldShowVariables = computed(() => {
      return resourceScope.value === ResourceDataModelType.Organization
    })

    watch(
      shouldShowVariables,
      value => {
        if (value) {
          fetchItems()
        }
      },
      {
        immediate: true,
      },
    )

    return {
      ...useInfiniteScroll({
        // options: resourceOptions,
        options,
        props,
      }),
      isUuid,
      currentEvent,
      search,
      selectedItems,
      fetchAndSet,
      variableList,
      currentQuerySelector,
      // resourceOptions,
      querySelectorOptions,
      isCreateVariableModalVisible,
      resourceScope,
      currentQuerySelectionCounter,
      shouldShowVariables,
      sanitizer,
    }
  },
  computed: {
    // ...mapGetters('currentContext', ['currentEvent']),
    allOptions() {
      let output = []
      if (this.shouldShowVariables) {
        const variablesByDataModelType = this.variableList?.filter(variableItem => {
          return variableItem.dataModelType === this.config.resource
        })
        output = output.concat([
          {
            id: 'variables',
            label: 'Variables',
            isOptgroup: true,
            iconClass: 'fas fa-plus',
            iconAction: () => {
              this.isCreateVariableModalVisible = true
            },
          },
        ])
        if (variablesByDataModelType.length) {
          output = output.concat([
            ...variablesByDataModelType.map(variable => ({
              id: `var:${variable.id}`,
              label: variable.name,
            })),
          ])
        }
      }

      if (this.canShowCurrentValues) {
        if (this.shouldShowVariables) {
          output = output.concat([
            {
              id: 'existing-values',
              label: 'Existing values',
              isOptgroup: true,
            },
            ...this.paginatedOptions,
          ])
        } else {
          output = output.concat([...this.paginatedOptions])
        }
      }
      return output
    },
    canShowCurrentValues() {
      return (
        this.config.resourceScope === ResourceDataModelType.Organization ||
        this.resourceScope === ResourceDataModelType.Event
      )
    },
  },
  // created() {
  //   this.fetchResourceOptions()
  // },
  methods: {
    onVariableCreated(variableId: string) {
      this.valueChanged([...(this.modelValue || []), `var:${variableId}`])
      this.valueChanged([`var:${variableId}`])
    },
    setCurrentQuerySelector(newVal) {
      this.currentQuerySelector = newVal
      this.currentQuerySelectionCounter = ''
      // this.fetchResourceOptions()
      this.fetchAndSet()
      if (this.$refs.bottomListEl.offsetParent) {
        this.$refs.bottomListEl.offsetParent.scrollTop = 0
      }
      this.limit = this.selectedValueLength + EACH_LIMIT
    },
    isResourceOptionSelectable(opt) {
      return !opt.isOptgroup && !opt.disabled
    },
    valueChanged(newVal) {
      const usedVariables = newVal.filter(el => el.startsWith('var:'))
      if (usedVariables.length) {
        newVal = [newVal[newVal.length - 1]]
      }
      this.$emit('update:modelValue', newVal)
    },
  },
  components: { CreateVariableModalComponent },
}
</script>

<template lang="pug">
CreateVariableModalComponent(
  v-if="isCreateVariableModalVisible"
  :dataModelType="config.resource"
  @exit="isCreateVariableModalVisible = false"
  @saveVariable="onVariableCreated"
)
ea-select.multiple(
  v-bind="selectBindings"
  :additionalErrors="additionalErrors"
  :filterable="false"
  :label="label"
  :modelValue="modelValue"
  :multiple="true"
  :name="name"
  :options="allOptions"
  :reduce="item => item.id"
  :select-label="'label'"
  :selectable="isResourceOptionSelectable"
  :skipDirtyCheck="skipDirtyCheck"
  :validators="validators"
  @search="event => (search = event)"
  @update:modelValue="valueChanged($event)"
)
  template(#list-footer)
    li.text-center.bold.muted(ref="bottomListEl" v-show="hasNextPage && canShowCurrentValues") loading more options...
  //- :options="resourceOptions"
  template(#option="option")
    div(:style="[option.isOptgroup ? 'margin-left: -0.5rem' : '']")
      span.no-margin(v-if="option.labelHtml?.length")
        span.variable-indicator.has-margin-top(v-if="option.id?.startsWith('var:')") var
        span(v-html="sanitizer.process(option.labelHtml)")
      .option-group(v-else)
        .option-item
          span.variable-indicator.has-margin-top(v-if="option.id?.startsWith('var:')") var
          span {{ option.label }}
        i.icon(v-if="option.iconClass" :class="option.iconClass" @click="option.iconAction")
  template(#selected-option="option")
    span.variable-indicator(v-if="option.id?.startsWith('var:')") var
    span(
      v-if="option.selectedLabelHtml?.length"
      v-html="sanitizer.process(option.selectedLabelHtml)"
    )
    span(v-else-if="option.selectedLabel") {{ option.selectedLabel }}
    span(v-else-if="option.labelHtml?.length" v-html="sanitizer.process(option.labelHtml)")
    span(v-else-if="!isUuid(option.label)") {{ option.label }}
    span(v-else)
      i.fa.fa-ellipsis-h.text-black-30.fa-bounce(style="--fa-bounce-height: -4px")
      //- .dot-falling

  template(#list-header)
    ea-chooser.sticky-top.bg-white.px-1.chooser-in-list-header(
      v-if="querySelectorOptions && querySelectorOptions.length > 1"
      :modelValue="currentQuerySelector"
      :nowrap="false"
      :options="querySelectorOptions"
      @update:modelValue="setCurrentQuerySelector($event)"
      name="filtersOptions"
      slim
      stretch
    )
    //- :nowrap="!Object.keys(config.optionalQuerySelectors).includes('havingVehiclesOrExplicitIds')"
</template>

<style scoped lang="scss">
.option-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: black;
  }
}

.no-margin {
  margin: 0;
}

.variable-indicator {
  background: black;
  color: white;
  display: flex;
  padding: 0 4px;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 4px;
  font-weight: 600;
  align-items: center;
  height: 15px;

  &.has-margin-top {
    margin-top: 2px;
  }
}

.option-item {
  display: flex;
}
</style>
