<script>
export default {
  name: 'DataTableEditButton',
  emits: ['click'],
  props: {
    name: { type: String },
    tooltip: { type: String, default: 'Edit' },
    iconSizeClass: { type: String, default: null },
  },
}
</script>

<template lang="pug">
button.btn.btn-link.py-0.px-1(
  :name="name"
  @click="$emit('click', $event)"
  type="button"
  v-tooltip.options="{ title: tooltip, placement: 'left' }"
)
  i.mi-outlined.mi-edit-note.text-primary(:class="iconSizeClass")
</template>
