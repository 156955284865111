import { computed, provide, reactive } from 'vue'

interface IBlocker {
  [key: string]: boolean
}

export function useBlocker() {
  const blocker: IBlocker = reactive({})

  provide('blocker', blocker)

  const isBlocked = computed<boolean>(() => {
    return Object.values(blocker).reduce((prev, next) => prev || next, false)
  })

  provide('isBlocked', isBlocked)

  return { blocker, isBlocked }
}
