import { Asl } from 'frontend/asls'
import { BatchOnsiteRequest } from 'frontend/batch-onsite-requests'
import { ClientCategory } from 'frontend/client-categories'
import { ClientFunctionRemark } from 'frontend/client-function-remarks'
import { ClientFunction } from 'frontend/client-functions'
import { ClientGroup } from 'frontend/client-groups'
import { Client } from 'frontend/clients'
import { Cluster } from 'frontend/clusters'
// eslint-disable-next-line import/default
import DurationWithOverride from 'frontend/common/duration-with-override.vue'
import TrueFalseLabel from 'frontend/common/true-false-label.vue'
import { DriverCategory } from 'frontend/driver-categories'
import { DriverSource } from 'frontend/driver-sources'
import { Driver } from 'frontend/drivers'
import { Location, LocationWithTp } from 'frontend/locations'
import { Shift } from 'frontend/shifts'
import { TransferGroup } from 'frontend/transfer-groups'
import { TransportationPoint } from 'frontend/transportation-points'
import { Tsl } from 'frontend/tsls'
import { User } from 'frontend/users'
import { VehicleCategory } from 'frontend/vehicle-categories'
import { VehicleModel } from 'frontend/vehicle-models'
import { VehicleSource } from 'frontend/vehicle-sources'
import { VehicleType } from 'frontend/vehicle-types'
import { Vehicle } from 'frontend/vehicles'
import { camelCase } from 'lodash'
import { markRaw } from 'vue'

import { EXTENDED_KINDS, TransportBooking } from './index'
import TBChanged from './transport-booking-changed.vue'
// eslint-disable-next-line import/default
import TransportBookingCoPassengersList from './transport-booking-co-passengers-list.vue'
import GroupingCause from './transport-booking-grouping-cause.vue'
import TBOsl from './transport-booking-osl.vue'
// eslint-disable-next-line import/default
import TransportBookingPassengersList from './transport-booking-passengers-list.vue'
import ProvidedStamp from './transport-booking-provided-stamp.vue'
import TBTime from './transport-booking-time.vue'

export const FIELDS = [
  'id',
  'eid',
  'kind',
  'extended_kind',
  'remote_id',
  'start_at',
  'updated_at',
  'created_at',
  'end_at',
  'overridden_lead_load_time',
  'requested_time',
  'provided_stamp_fixed',
  'provided_stamp',
  'locked_osl_assignment',
  'flight_at',
  'flight_number',
  'terminal_number',
  'comment',
  'tpt_comment',
  'ready_for_osl',
  'original_attributes_change_seen',
  'original_attributes_changed',
  'original_provided_stamp',
  'original_requested_time',
  'opposite_route_booking_id',
  'opposite_route_booking_eid',
  'grouping_cause',
  'is_flight_private',
  'flight_class',
  'calculated_arrival_buffer',
  'calculated_departure_buffer',
  'calculated_driver_on_spot_buffer',
  'calculated_load_buffer',
  'calculated_safety_buffer',
  'overridden_arrival_buffer',
  'overridden_departure_buffer',
  'overridden_driver_on_spot_buffer',
  'overridden_load_buffer',
  'overridden_safety_buffer',
  'resulting_arrival_buffer',
  'resulting_departure_buffer',
  'resulting_driver_on_spot_buffer',
  'resulting_load_buffer',
  'resulting_safety_buffer',
  'technical_state',
  'driver_on_spot_at',
  'travel_start_at',
  'safety_end_at',
  'number_of_clients',
  'has_police_escort',
  {
    transport_booking_grouping_rule: ['id', 'eid', 'name'],
  },
  {
    original_start_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
  },
  {
    original_end_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
  },
  {
    day_labels: ['id', 'name', 'font_color', 'background_color', 'border_color'],
  },
  { asl: ['id', 'name'] },
  {
    client: [
      'id',
      'name',
      'surname',
      'remote_id',
      'phone',
      'archived_at',
      { tsl: ['id', 'name'] },
      { asl: ['id', 'name'] },
      { client_function_remark: ['id', 'name'] },
      { client_categories: ['id', 'name', 'group_path'] },
      { client_functions: ['id', 'name'] },
      {
        client_groups: [
          'id',
          'background_color',
          'border_color',
          'description',
          'font_color',
          'name',
        ],
      },
    ],
  },
  {
    client_group: ['id', 'background_color', 'border_color', 'description', 'font_color', 'name'],
  },
  { start_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
  { end_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
  { start_transportation_point: ['id', 'name'] },
  { end_transportation_point: ['id', 'name'] },
  {
    operational_service_level: [
      'id',
      'name',
      'font_color',
      'background_color',
      'border_color',
      'description',
    ],
  },
  {
    created_by: ['id', 'name', 'surname', 'email'],
  },
  {
    batch_onsite_request: ['id', 'clients', 'eid', 'additional_clients'],
  },
  {
    shift_job: [
      'id',
      'clients_count',
      'aasm_state',
      'start_at',
      'end_at',
      'duration',
      'additional_passengers',
      'driver_comment',
      'passengers',
      'client_group_passengers',
      {
        shift: [
          'id',
          'kind',
          'eid',
          {
            staging_area: [
              'id',
              {
                location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
              },
            ],
          },
          {
            driver: [
              'id',
              'name',
              'surname',
              'assigned_phone',
              'personal_phone',
              'primary_phone',
              { cluster: ['id', 'name', 'time_zone'] },
              {
                driver_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
              },
              {
                driver_source: ['id', 'name'],
              },
            ],
          },
          {
            vehicle: [
              'id',
              'fleet_number',
              'registration_plate',
              { cluster: ['id', 'name', 'time_zone'] },
              { vehicle_model: ['id', 'name', { vehicle_brand: ['id', 'name'] }] },
              { vehicle_type: ['id', 'name'] },
              { vehicle_source: ['id', 'name', 'billable', 'maintainable'] },
              {
                vehicle_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
              },
            ],
          },
        ],
      },
    ],
  },
]

export const SUBSCRIPTIONS = {
  items: 'TransportBookingsChannel',
  clients: 'ClientsDetailedChannel',
  locations: 'LocationsSimpleChannel',
  operationalServiceLevels: 'OperationalServiceLevelsSimpleChannel',
  shiftJobs: 'ShiftJobsSimpleChannel',
  shifts: 'ShiftsSimpleChannel',
  clusters: 'ClustersSimpleChannel',
  drivers: 'DriversDetailedChannel',
  driverCategories: 'DriverCategoriesSimpleChannel',
  vehicles: 'VehiclesDetailedChannel',
  vehicleTypes: 'VehicleTypesSimpleChannel',
  vehicleCategories: 'VehicleCategoriesSimpleChannel',
  vehicleModels: 'VehicleModelsSimpleChannel',
  vehicleBrands: 'VehicleBrandsSimpleChannel',
  dayLabels: 'DayLabelsSimpleChannel',
  transportationPoints: 'TransportationPointsSimpleChannel',
  batchOnsiteRequests: 'BatchOnsiteRequestsSimpleChannel',
}

export const DEFAULT_COLUMNS = [
  'eid',
  'kind',
  'requestedDatetime',
  'clientDetails',
  'shiftJobStartAt',
  'pu',
  'shiftJobEndAt',
  'do',
  'osl',
  'shiftDetails',
  'comment',
  'tptComment',
  'remoteId',
  'flightNumber',
  'createdBy',
]

const humanizedKind = tb => EXTENDED_KINDS.find(el => el.value == tb.extendedKind)?.label

const buildClientFromTb = tb => {
  let result = tb.client
  if (!result && tb.batchOnsiteRequest) {
    result = {
      surname: `${tb.numberOfClients || 1} x`,
      name: 'client',
    }
  }
  return result
}

export const COLUMNS_CONFIG = {
  eid: {
    label: 'EID',
    header: {
      sort: 'eid',
    },
    cell: {
      kind: 'simple',
      path: 'eid',
    },
  },

  kind: {
    label: 'Type',
    header: {
      sort: 'extendedKind',
    },
    cell: {
      class: 'text-nowrap',
      kind: 'function',
      fn: humanizedKind,
    },
  },

  transferGroupDetails: {
    label: 'Transfer Group Details',
    header: {
      sort: 'shiftJobAasmStateForSort',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransferGroup),
      componentProps: tb => ({ transferGroup: tb.shiftJob }),
    },
  },

  transferGroup: {
    label: 'Transfer Group',
    header: {
      sort: 'shiftJobId',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransferGroup),
      componentProps: tb => ({ transferGroup: tb.shiftJob, onlyGrouping: true }),
    },
  },

  transferGroupState: {
    label: 'Transfer Group Status',
    header: {
      sort: 'shiftJobAasmStateForSort',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransferGroup),
      componentProps: tb => ({ transferGroup: tb.shiftJob, onlyState: true }),
    },
  },

  shift: {
    label: 'Assigned Shift',
    header: {
      sort: 'shiftJobShiftStagingAreaLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Shift),
      componentProps: tb => ({
        shift: tb.shiftJob?.shift,
        detailed: true,
      }),
    },
  },

  driver: {
    label: 'Driver',
    header: {
      sort: 'shiftJobShiftDriverReverseFullName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Driver),
      componentProps: tb => ({
        driver: tb.shiftJob?.shift?.driver,
        detailed: true,
      }),
    },
  },

  driverName: {
    label: 'Driver name',
    header: {
      sort: 'shiftJobShiftDriverName',
    },
    cell: {
      kind: 'simple',
      path: 'shiftJob.shift.driver.name',
    },
  },

  driverSurname: {
    label: 'Driver surname',
    header: {
      sort: 'shiftJobShiftDriverSurname',
    },
    cell: {
      kind: 'simple',
      path: 'shiftJob.shift.driver.surname',
    },
  },

  driverPhoneNumber: {
    label: 'Driver phone number',
    header: {
      sort: 'shiftJobShiftDriverMainPhone',
    },
    cell: {
      kind: 'component',
      component: 'phone-number',
      componentProps: tb => ({
        phoneNumber:
          tb.shiftJob?.shift?.driver?.[camelCase(tb.shiftJob?.shift?.driver?.primaryPhone || '')],
      }),
    },
  },

  driverCategory: {
    label: 'Driver category',
    header: {
      sort: 'shiftJobShiftDriverDriverCategoryName',
    },
    cell: {
      kind: 'component',
      component: markRaw(DriverCategory),
      componentProps: tb => ({
        driverCategory: tb.shiftJob?.shift?.driver?.driverCategory,
      }),
    },
  },

  driverSource: {
    label: 'Driver source',
    header: {
      sort: 'shiftJobShiftDriverDriverSourceName',
    },
    cell: {
      kind: 'component',
      component: markRaw(DriverSource),
      componentProps: tb => ({
        driverSource: tb.shiftJob?.shift?.driver?.driverSource,
      }),
    },
  },

  vehicle: {
    label: 'Vehicle',
    header: {
      sort: ['shiftJobShiftVehicleFleetNumberPrefix', 'shiftJobShiftVehicleFleetNumberInt'],
    },
    cell: {
      kind: 'component',
      component: markRaw(Vehicle),
      componentProps: tb => ({
        vehicle: tb.shiftJob?.shift?.vehicle,
        detailed: true,
      }),
    },
  },

  vehicleFleetNumber: {
    label: 'Vehicle fleet number',
    header: {
      sort: ['shiftJobShiftVehicleFleetNumberPrefix', 'shiftJobShiftVehicleFleetNumberInt'],
    },
    cell: {
      kind: 'simple',
      path: 'shiftJob.shift.vehicle.fleetNumber',
    },
  },

  vehicleRegistrationPlate: {
    label: 'Vehicle reg. plate',
    header: {
      sort: 'shiftJobShiftVehicleRegistrationPlate',
    },
    cell: {
      kind: 'simple',
      path: 'shiftJob.shift.vehicle.registrationPlate',
    },
  },

  vehicleCluster: {
    label: 'Vehicle cluster',
    header: {
      sort: 'shiftJobShiftVehicleVehicleClusterName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Cluster),
      componentProps: tb => ({
        cluster: tb.shiftJob?.shift?.vehicle?.cluster,
      }),
    },
  },

  vehicleCategory: {
    label: 'Vehicle category',
    header: {
      sort: 'shiftJobShiftVehicleVehicleCategoryName',
    },
    cell: {
      kind: 'component',
      component: markRaw(VehicleCategory),
      componentProps: tb => ({
        vehicleCategory: tb.shiftJob?.shift?.vehicle?.vehicleCategory,
      }),
    },
  },

  vehicleType: {
    label: 'Vehicle type',
    header: {
      sort: 'shiftJobShiftVehicleVehicleTypeName',
    },
    cell: {
      kind: 'component',
      component: markRaw(VehicleType),
      componentProps: tb => ({
        vehicleType: tb.shiftJob?.shift?.vehicle?.vehicleType,
      }),
    },
  },

  vehicleSource: {
    label: 'Vehicle source',
    header: {
      sort: 'shiftJobShiftVehicleVehicleSourceName',
    },
    cell: {
      kind: 'component',
      component: markRaw(VehicleSource),
      componentProps: tb => ({
        vehicleSource: tb.shiftJob?.shift?.vehicle?.vehicleSource,
      }),
    },
  },

  vehicleModel: {
    label: 'Vehicle model',
    header: {
      sort: 'shiftJobShiftVehicleVehicleModelName',
    },
    cell: {
      kind: 'component',
      component: markRaw(VehicleModel),
      componentProps: tb => ({
        vehicleModel: tb.shiftJob?.shift?.vehicle?.vehicleModel,
      }),
    },
  },

  shiftDetails: {
    label: 'Assigned Shift Details',
    header: {
      sort: 'shiftJobShiftStagingAreaLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Shift),
      componentProps: tb => ({
        shift: tb.shiftJob?.shift,
        detailed: true,
        withDriver: true,
        withVehicle: true,
      }),
    },
  },

  client: {
    label: 'Client full name',
    header: {
      sort: 'clientFullName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Client),
      componentProps: tb => ({
        client: buildClientFromTb(tb),
        nowrap: true,
        disabled: !tb.client?.id,
        batchOnsiteRequest: !tb.client?.id ? tb.batchOnsiteRequest : null,
      }),
    },
  },

  clientName: {
    label: 'Client name',
    header: {
      sort: 'clientName',
    },
    cell: {
      kind: 'simple',
      path: 'client.name',
    },
  },

  clientSurname: {
    label: 'Client surname',
    header: {
      sort: 'clientSurname',
    },
    cell: {
      kind: 'simple',
      path: 'client.surname',
    },
  },

  clientPhone: {
    label: 'Client phone number',
    header: {
      sort: 'clientPhone',
    },
    cell: {
      kind: 'component',
      component: 'phone-number',
      componentProps: tb => ({
        phoneNumber: tb.client?.phone,
      }),
    },
  },

  clientRemoteId: {
    label: 'Client remote ID',
    header: {
      sort: 'clientRemoteId',
    },
    cell: {
      kind: 'simple',
      path: 'client.remoteId',
    },
  },

  clientDetails: {
    label: 'Client',
    header: {
      sort: 'clientFullName',
    },
    cell: {
      kind: 'componentFactory',
      componentFactory: tb => {
        if (tb.client?.id) {
          return markRaw(Client)
        } else {
          return markRaw(TransportBookingPassengersList)
        }
      },
      componentProps: tb => {
        if (tb.client?.id) {
          return {
            client: buildClientFromTb(tb),
            nowrap: true,
            disabled: !tb.client?.id,
            batchOnsiteRequest: !tb.client?.id ? tb.batchOnsiteRequest : null,
            detailed: true,
          }
        } else {
          return {
            transportBooking: tb,
          }
        }
      },
    },
  },

  coPassengers: {
    label: 'Co-passengers',
    header: {
      // sort: 'clientFullName',
    },
    cell: {
      kind: 'component',
      component: TransportBookingCoPassengersList,
      componentProps: tb => ({
        transportBooking: tb,
        limit: 5,
        showTotal: true,
      }),
    },
  },

  clientsCount: {
    label: 'Total passengers',
    tooltip: 'Total number of passengers in Transfer Group',
    header: {
      sort: 'shiftJobClientsCount',
    },
    cell: {
      kind: 'simple',
      path: 'shiftJob.clientsCount',
    },
  },

  clientCategories: {
    label: 'Group paths',
    header: {
      sort: 'clientClientCategoriesName',
    },
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tb => ({
        items: tb.client?.clientCategories,
        limit: 5,
        component: markRaw(ClientCategory),
        componentProps: c => ({ clientCategory: c }),
      }),
    },
  },

  clientGroups: {
    label: 'Client Groups',
    header: {
      sort: 'clientClientGroupsName',
    },
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tb => ({
        items: [...(tb.client?.clientGroups || []), tb.clientGroup].filter(Boolean),
        limit: 5,
        component: markRaw(ClientGroup),
        componentProps: c => ({ clientGroup: c }),
      }),
    },
  },

  clientFunctions: {
    label: 'Functions',
    header: {
      sort: 'clientClientFunctionsName',
    },
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tb => ({
        items: tb.client?.clientFunctions,
        limit: 5,
        component: markRaw(ClientFunction),
        componentProps: c => ({ clientFunction: c }),
      }),
    },
  },

  clientFunctionRemark: {
    label: 'Function Remark',
    header: {
      sort: 'clientClientFunctionRemarkName',
    },
    cell: {
      kind: 'component',
      component: markRaw(ClientFunctionRemark),
      componentProps: tb => ({
        clientFunctionRemark: tb.client?.clientFunctionRemark,
      }),
    },
  },

  tsl: {
    label: 'TSL',
    header: {
      sort: 'clientTslName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Tsl),
      componentProps: tb => ({
        tsl: tb.client?.tsl,
      }),
    },
  },

  clientAsl: {
    label: 'Client ASL',
    header: {
      sort: 'clientAslName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Asl),
      componentProps: tb => ({
        asl: tb.client?.asl,
      }),
    },
  },

  asl: {
    label: 'TB ASL',
    header: {
      sort: 'aslName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Asl),
      componentProps: tb => ({
        asl: tb.asl,
      }),
    },
  },

  startCluster: {
    label: 'Pick-up Cluster',
    header: {
      sort: 'startLocationClusterName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Cluster),
      componentProps: tb => ({
        cluster: tb.startLocation?.cluster,
      }),
    },
  },

  startLocation: {
    label: 'Pick-up Location',
    header: {
      sort: 'startLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Location),
      componentProps: tb => ({
        location: tb.startLocation,
        // nowrap: true,
        minWidth: 250,
        previousLocation:
          tb.originalStartLocation && tb.originalStartLocation?.id != tb.startLocation?.id
            ? tb.originalStartLocation
            : null,
      }),
    },
  },

  startTransportationPoint: {
    label: 'Pick-up TP',
    header: {
      sort: 'startTransportationPointName',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransportationPoint),
      componentProps: tb => ({
        transportationPoint: tb.startTransportationPoint,
        kindIn: "['onsite', 'arrival', 'departure']",
        nowrap: true,
      }),
    },
  },

  pu: {
    label: 'Pick-up',
    header: {
      sort: 'startLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(LocationWithTp),
      componentProps: tb => ({
        item: tb,
        kind: 'start',
        minWidth: 250,
        // nowrap: true,
      }),
    },
  },

  endCluster: {
    label: 'Drop-off Cluster',
    header: {
      sort: 'endLocationClusterName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Cluster),
      componentProps: tb => ({
        cluster: tb.endLocation?.cluster,
      }),
    },
  },

  endLocation: {
    label: 'Drop-off Location',
    header: {
      sort: 'endLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Location),
      componentProps: tb => ({
        location: tb.endLocation,
        // nowrap: true,
        minWidth: 250,
        previousLocation:
          tb.originalEndLocation && tb.originalEndLocation?.id != tb.startLocation?.id
            ? tb.originalEndLocation
            : null,
      }),
    },
  },

  endTransportationPoint: {
    label: 'Drop-off TP',
    header: {
      sort: 'endTransportationPointName',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransportationPoint),
      componentProps: tb => ({
        transportationPoint: tb.endTransportationPoint,
        kindIn: "['onsite', 'arrival', 'departure']",
        nowrap: true,
      }),
    },
  },

  do: {
    label: 'Drop-off',
    header: {
      sort: 'endLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(LocationWithTp),
      componentProps: tb => ({
        item: tb,
        kind: 'end',
        // nowrap: true,
        minWidth: 250,
      }),
    },
  },

  requestedDatetime: {
    label: 'Req. datetime',
    header: {
      sort: 'requestedTime',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'requestedTime',
        tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
        showAnchor: !!item.providedStampFixed,
        originalLookup: true,
        showOverriddenLeadLoadTime: true,
      }),
    },
  },

  requestedTime: {
    label: 'Req. time',
    header: {
      sort: 'requestedTime',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'requestedTime',
        tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
        showAnchor: !!item.providedStampFixed,
        format: 'timeOnly',
        showOverriddenLeadLoadTime: true,
        originalLookup: true,
      }),
    },
  },

  arrivalResultingBuffer: {
    label: 'Arrival buffer',
    header: {
      sort: 'arrivalResultingBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration:
          item.overriddenArrivalBuffer != null
            ? item.overriddenArrivalBuffer
            : item.calculatedArrivalBuffer,
        override: item.resultingArrivalBuffer,
      }),
    },
  },

  resultingLoadBuffer: {
    label: 'Load buffer',
    header: {
      sort: 'resultingLoadBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration:
          item.overriddenLoadBuffer != null ? item.overriddenLoadBuffer : item.calculatedLoadBuffer,
        override: item.resultingLoadBuffer,
      }),
    },
  },

  resultingDriverOnSpotBuffer: {
    label: 'Driver on spot buffer',
    header: {
      sort: 'resultingDriverOnSpotBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration:
          item.overriddenDriverOnSpotBuffer != null
            ? item.overriddenDriverOnSpotBuffer
            : item.calculatedDriverOnSpotBuffer,
        override: item.resultingDriverOnSpotBuffer,
      }),
    },
  },

  resultingSafetyBuffer: {
    label: 'Safety buffer',
    header: {
      sort: 'resultingSafetyBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration:
          item.overriddenSafetyBuffer != null
            ? item.overriddenSafetyBuffer
            : item.calculatedSafetyBuffer,
        override: item.resultingSafetyBuffer,
      }),
    },
  },

  resultingDepartureBuffer: {
    label: 'Departure buffer',
    header: {
      sort: 'resultingDepartureBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration:
          item.overriddenDepartureBuffer != null
            ? item.overriddenDepartureBuffer
            : item.calculatedDepartureBuffer,
        override: item.resultingDepartureBuffer,
      }),
    },
  },

  driverOnSpotAt: {
    label: 'Driver on spot at',
    header: {
      sort: 'driverOnSpotAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.driverOnSpotAt }),
    },
  },

  travelStartAt: {
    label: 'Travel start at',
    header: {
      sort: 'travelStartAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.travelStartAt }),
    },
  },

  safetyEndAt: {
    label: 'Safety end at',
    header: {
      sort: 'safetyEndAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.safetyEndAt }),
    },
  },

  overriddenLeadLoadTime: {
    label: 'Overridden lead/load time',
    header: {
      sort: 'overriddenLeadLoadTime',
    },
    cell: {
      kind: 'component',
      component: 'humanized-duration',
      componentProps: item => ({ minutes: item.overriddenLeadLoadTime }),
    },
  },

  requestedDate: {
    label: 'Req. date',
    header: {
      sort: 'requestedTime',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'requestedTime',
        tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
        showAnchor: !!item.providedStampFixed,
        format: 'dateOnly',
        showOverriddenLeadLoadTime: true,
        originalLookup: true,
      }),
    },
  },

  providedStamp: {
    label: 'Req. time kind',
    header: {
      sort: 'providedStamp',
    },
    cell: {
      kind: 'component',
      component: markRaw(ProvidedStamp),
      componentProps: item => ({ transportBooking: item }),
    },
  },

  startAt: {
    label: 'Req. pick-up',
    header: {
      sort: 'startAt',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'startAt',
        tz: item.startLocation?.timeZone,
        showAnchor: !!item.providedStampFixed && item.providedStamp == 'start_at',
      }),
    },
  },

  startAtTime: {
    label: 'Req. pick-up time',
    header: {
      sort: 'startAt',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'startAt',
        tz: item.startLocation?.timeZone,
        showAnchor: !!item.providedStampFixed && item.providedStamp == 'start_at',
        format: 'timeOnly',
      }),
    },
  },

  startAtDate: {
    label: 'Req. pick-up date',
    header: {
      sort: 'startAt',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'startAt',
        tz: item.startLocation?.timeZone,
        showAnchor: !!item.providedStampFixed && item.providedStamp == 'start_at',
        format: 'dateOnly',
      }),
    },
  },

  shiftJobDuration: {
    label: 'Comp. duration',
    header: {
      sort: 'shiftJobDuration',
    },
    cell: {
      kind: 'component',
      component: 'humanized-duration',
      componentProps: item => ({ minutes: item.shiftJob?.duration }),
    },
  },

  shiftJobStartAt: {
    label: 'Comp. pick-up',
    header: {
      sort: 'shiftJobStartAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.shiftJob?.startAt, tz: item.startLocation?.timeZone }),
    },
  },

  shiftJobStartAtTime: {
    label: 'Comp. pick-up time',
    header: {
      sort: 'shiftJobStartAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.shiftJob?.startAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },

  shiftJobStartAtDate: {
    label: 'Comp. pick-up date',
    header: {
      sort: 'shiftJobStartAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.shiftJob?.startAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },

  endAt: {
    label: 'Req. drop-off',
    header: {
      sort: 'endAt',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'endAt',
        tz: item.endLocation?.timeZone,
        showAnchor: !!item.providedStampFixed && item.providedStamp == 'end_at',
      }),
    },
  },

  endAtTime: {
    label: 'Req. drop-off time',
    header: {
      sort: 'endAt',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'endAt',
        tz: item.endLocation?.timeZone,
        showAnchor: !!item.providedStampFixed && item.providedStamp == 'end_at',
        format: 'timeOnly',
      }),
    },
  },

  endAtDate: {
    label: 'Req. drop-off date',
    header: {
      sort: 'endAt',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBTime),
      componentProps: item => ({
        transportBooking: item,
        attr: 'endAt',
        tz: item.endLocation?.timeZone,
        showAnchor: !!item.providedStampFixed && item.providedStamp == 'end_at',
        format: 'dateOnly',
      }),
    },
  },

  shiftJobEndAt: {
    label: 'Comp. drop-off',
    header: {
      sort: 'shiftJobEndAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.shiftJob?.endAt, tz: item.endLocation?.timeZone }),
    },
  },

  shiftJobEndAtTime: {
    label: 'Comp. drop-off time',
    header: {
      sort: 'shiftJobEndAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.shiftJob?.endAt,
        format: 'timeOnly',
        tz: item.endLocation?.timeZone,
      }),
    },
  },

  shiftJobEndAtDate: {
    label: 'Comp. drop-off date',
    header: {
      sort: 'shiftJobEndAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.shiftJob?.endAt,
        format: 'dateOnly',
        tz: item.endLocation?.timeZone,
      }),
    },
  },

  dayLabels: {
    label: 'Occasion',
    header: {},
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tb => ({
        items: tb.dayLabels,
        limit: 5,
        component: 'erebus-label',
        componentProps: c => ({ item: c }),
      }),
    },
  },

  osl: {
    label: 'OSL',
    header: {
      sort: 'operationalServiceLevelPosition',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBOsl),
      componentProps: item => ({
        transportBooking: item,
      }),
    },
  },
  groupingCause: {
    label: 'Grouping Cause',
    header: {
      sort: 'grouping_cause',
    },
    cell: {
      kind: 'component',
      component: markRaw(GroupingCause),
      componentProps: item => ({
        transportBooking: item,
      }),
    },
  },

  hasPoliceEscort: {
    label: 'Has police escort',
    header: {
      sort: 'hasPoliceEscort',
    },
    cell: {
      kind: 'component',
      component: TrueFalseLabel,
      componentProps: item => ({ item: item.hasPoliceEscort }),
    },
  },

  flightAtDatetime: {
    label: 'Travel arrival/departure datetime',
    header: {
      sort: 'flightAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.flightAt,
        tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
      }),
    },
  },

  flightAtTime: {
    label: 'Travel arrival/departure time',
    header: {
      sort: 'flightAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.flightAt,
        tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
        format: 'timeOnly',
      }),
    },
  },

  flightAtDate: {
    label: 'Travel arrival/departure date',
    header: {
      sort: 'flightAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.flightAt,
        tz: item[`${(item.providedStamp || 'start_at').replace('_at', '')}Location`]?.timeZone,
        format: 'dateOnly',
      }),
    },
  },

  flightNumber: {
    label: 'Travel No.',
    header: {
      sort: 'flightNumber',
    },
    cell: {
      kind: 'simple',
      path: 'flightNumber',
    },
  },

  privateJet: {
    label: 'Private Jet',
    header: {
      sort: 'isFlightPrivate',
    },
    cell: {
      kind: 'component',
      component: TrueFalseLabel,
      componentProps: item => ({ item: item.isFlightPrivate }),
    },
  },

  flightClass: {
    label: 'Flight class',
    header: {
      sort: 'flightClass',
    },
    cell: {
      kind: 'simple',
      path: 'flightClass',
    },
  },

  terminalNumber: {
    label: 'Terminal No.',
    header: {
      sort: 'terminalNumber',
    },
    cell: {
      kind: 'simple',
      path: 'terminalNumber',
    },
  },

  technicalState: {
    label: 'Travel No.',
    header: {
      sort: 'technicalState',
    },
    cell: {
      kind: 'simple',
      path: 'technicalState',
    },
  },

  comment: {
    label: 'Comment',
    header: {
      sort: 'comment',
    },
    cell: {
      kind: 'component',
      component: 'ellipsis-with-tooltip',
      componentProps: item => ({
        text: item.comment,
        limit: 250,
      }),
    },
  },

  tptComment: {
    label: 'TPT Comment',
    tooltip: 'Transportation Team Comment',
    header: {
      sort: 'tpt_comment',
    },
    cell: {
      kind: 'component',
      component: 'ellipsis-with-tooltip',
      componentProps: item => ({
        text: item.tptComment,
        limit: 250,
      }),
    },
  },

  driverComment: {
    label: 'Driver comment',
    header: {
      sort: 'shift_job_driver_comment',
    },
    cell: {
      kind: 'component',
      component: 'ellipsis-with-tooltip',
      componentProps: item => ({
        text: item.shiftJob?.driverComment,
        limit: 250,
      }),
    },
  },

  remoteId: {
    label: 'Remote ID',
    header: {
      sort: 'remoteId',
    },
    cell: {
      kind: 'simple',
      path: 'remoteId',
    },
  },

  batchOnsiteRequest: {
    label: 'Batch On-site Request',
    header: {
      sort: 'batchOnsiteRequestId',
    },
    cell: {
      kind: 'component',
      component: markRaw(BatchOnsiteRequest),
      componentProps: item => ({
        batchOnsiteRequest: item.batchOnsiteRequest,
      }),
    },
  },

  oppositeRouteBooking: {
    label: 'Opposite route TB',
    header: {
      sort: 'oppositeRouteBookingId',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransportBooking),
      componentProps: item => ({
        transportBooking: item.oppositeRouteBookingId
          ? { id: item.oppositeRouteBookingId, eid: item.oppositeRouteBookingEid }
          : null,
        eidOnly: true,
      }),
    },
  },

  createdBy: {
    label: 'Created by',
    header: {
      sort: 'createdByUsername',
    },
    cell: {
      kind: 'component',
      component: markRaw(User),
      componentProps: item => ({
        user: item.createdBy,
      }),
    },
  },

  createdAt: {
    label: 'Created Datetime',
    header: {
      sort: 'createdAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.createdAt }),
    },
  },

  createdAtTime: {
    label: 'Created Time',
    header: {
      sort: 'createdAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.createdAt,
        format: 'timeOnly',
      }),
    },
  },

  createdAtDate: {
    label: 'Created Date',
    header: {
      sort: 'createdAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.createdAt,
        format: 'dateOnly',
      }),
    },
  },

  updatedAt: {
    label: 'Updated Datetime',
    header: {
      sort: 'updatedAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.updatedAt }),
    },
  },

  updatedAtTime: {
    label: 'Updated Time',
    header: {
      sort: 'updatedAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.updatedAt,
        format: 'timeOnly',
      }),
    },
  },

  updatedAtDate: {
    label: 'Updated Date',
    header: {
      sort: 'updatedAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.updatedAt,
        format: 'dateOnly',
      }),
    },
  },

  changed: {
    label: 'Changed',
    header: {
      sort: 'unseenOriginalChanges',
    },
    cell: {
      kind: 'component',
      component: markRaw(TBChanged),
      componentProps: item => ({
        transportBooking: item,
      }),
    },
  },
}
