export enum ViewType {
  DispatcherScreen = 'dispatcher-screen',
  ShiftsManager = 'shifts-manager-screen',
  LivePanel = 'live-panel',
  ClientCommunicationMessages = 'client-communication-messages',
  SystemNotifications = 'system-notifications',
  TransportBookings = 'transport-bookings',
  BatchOnsiteRequests = 'batch-onsite-requests',
  AllDemands = 'all-demands',
  ShiftJobs = 'shift-jobs',
  TransferGroups = 'transfer-groups',
  ShuttleServices = 'shuttle-services',
  ShuttleTransfers = 'shuttle-transfers',
  Vehicles = 'vehicles',
  Drivers = 'drivers',
  Clients = 'clients',
  Shifts = 'shifts',
  Reports = 'reports',
  Clusters = 'clusters',
  Locations = 'locations',
  TransportationPoints = 'transportation-points',
  TransportBuffers = 'transport-buffers',
  StagingAreas = 'staging-areas',
  Depots = 'depots',
  OriginXDestinationMatrix = 'origin-x-destination-matrix',
  OperationalPeriods = 'operational-periods',
  Occasions = 'occasions',
  WelcomeDeskWorkingHours = 'welcome-desk-working-hours',
  MinimumVehicleAllotment = 'minimum-vehicle-allotment',
  Subfleet = 'subfleet',
  DriverAvailabilities = 'driver-availabilities',
  DriverUnavailabilities = 'driver-unavailabilities',
  OSLTBRules = 'osl-tb-rules',
  OSLVRulesMatrix = 'osl-v-rules-matrix',
  OSLDRulesMatrix = 'osl-d-rules-matrix',
  TBGroupingRules = 'transport-booking-grouping-rules',
  ClientCommunicationRules = 'client-communication-rules',
  PushNotificationTemplates = 'notification-templates',
  ClientGroupPaths = 'client-categories',
  ClientGroups = 'client-groups',
  VehicleSources = 'vehicle-sources',
  VehicleSourcePricing = 'vehicle-source-pricings',
  DriverSources = 'driver-sources',
  TrackerDevices = 'tracker-devices',
  TrackingServices = 'tracking-services',
  DeletedResources = 'deleted-resources',
  ImportVehicles = 'vehicle-imports',
  ImportClients = 'client-imports',
  ImportDrivers = 'driver-imports',
  ImportTransportBookings = 'transport-booking-imports',
  ImportShuttleServices = 'shuttle-service-imports',
  ImportTransportationPoints = 'transportation-point-imports',
  ImportUserAccesses = 'user-access-imports',
  ClientFunctions = 'client-functions',
  ClientFunctionRemarks = 'client-function-remarks',
  DayLabels = 'day-labels',
  Roles = 'roles',
  OperationalServiceLevels = 'operational-service-levels',
  DriverCategories = 'driver-categories',
  LocationTypes = 'location-types',
  VehicleBrands = 'vehicle-brands',
  VehicleModels = 'vehicle-models',
  VehicleCategories = 'vehicle-categories',
  VehicleTypes = 'vehicle-types',
  RoutePreview = 'route-preview',
  UserAccesses = 'user-accesses',
  EventManagement = 'events',
  DriverRegistrationQrTokens = 'driver-registration-qr-tokens',
  DriverRegistrationRequests = 'driver-registration-requests',
  GisRules = 'gis-rules',
}
