export enum CriteriaValueType {
  Boolean = 'boolean',
  ResourceList = 'resource-list',
  Input = 'input',
  Default = 'default',
  TaggableSelect = 'taggable-select',
  DateTimeWithVariables = 'datetime-with-variables',
  DateTime = 'datetime',
  DateMulti = 'date-multi',
  Selection = 'selection',
  CustomContains = 'custom-contains',
}
