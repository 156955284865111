import { CRITERIA_CONFIG_FLAT } from 'frontend/client-groups/criteria-config'
import FILTER_KINDS from 'frontend/common/ea-filter/_ea-filter-kind-data'
import DatatableQueryPreview from 'frontend/common/ea-query-preview/datatable-query-preview.vue'
import ErebusLabel from 'frontend/common/erebus-label.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { EventOrganizationUser } from 'frontend/event-organization-users'

export const ClientGroupDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'name',
    'description',
    'border_color',
    'font_color',
    'background_color',
    'updated_at',
    'created_at',
    'criteria',
    { event_organization_users: [{ user: ['id', 'email', 'name', 'surname'] }] },
  ],
  subscriptions: {
    items: 'ClientGroupsChannel',
    eventOrganizationUsers: 'EventOrganizationUsersSimpleChannel',
  },
  defaultColumns: ['eid', 'name', 'label', 'description', 'managers', 'criteria'],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    label: {
      label: 'Label',
      header: {},
      cell: {
        kind: 'component',
        component: ErebusLabel,
        componentProps: clientGroup => ({ item: clientGroup }),
      },
    },
    name: {
      label: 'Name',
      header: {
        sort: 'lowerName',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    description: {
      label: 'Description',
      header: {},
      cell: {
        kind: 'simple',
        path: 'description',
      },
    },
    managers: {
      label: 'Managers',
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: clientGroup => ({
          items: clientGroup.eventOrganizationUsers,
          limit: 3,
          component: EventOrganizationUser,
          componentProps: c => ({ eventOrganizationUser: c }),
        }),
      },
    },
    criteria: {
      label: 'Criteria',
      cell: {
        kind: 'component',
        component: DatatableQueryPreview,
        componentProps: el => ({
          query: el.criteria,
          config: CRITERIA_CONFIG_FLAT,
          filterKinds: FILTER_KINDS,
        }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroup => ({ t: clientGroup.createdAt }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroup => ({ t: clientGroup.updatedAt }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroup => ({
          t: clientGroup.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroup => ({
          t: clientGroup.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  },
}
