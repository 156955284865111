<script setup lang="ts">
import { Modal } from 'bootstrap'
import eventHub from 'frontend/_globals/event-hub'
import { useKeyboardShortcuts } from 'frontend/keyboard/composables/useKeyboardShortcuts'
import { KeyboardSpecialKeyTypes } from 'frontend/keyboard/enum/KeyboardSpecialKeyTypes'
import {
  computed,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  useSlots,
  type VNodeNormalizedChildren,
  watch,
} from 'vue'
import { useStore } from 'vuex'

const MODAL_Z_INDEX = 1060
const BACKDROP_Z_INDEX = 1059
const LAYER_Z_INDEX_STEP = 10

interface Emits {
  (e: 'closed'): void
}
const emit = defineEmits<Emits>()

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number
  blocker?: boolean
  withoutTimeout?: boolean
  nonDraggable?: boolean
  name?: string
  scrollableContent?: boolean
  modalBodyClass?: string
  hideFooter?: boolean
  beforeClose?: () => Promise<void>
  zIndex?: number
  noBodyPadding?: boolean
  hideBottomLine?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  size: 'lg',
  blocker: false,
  withoutTimeout: false,
  nonDraggable: false,
  name: '',
  scrollableContent: false,
  modalBodyClass: '',
  hideFooter: false,
  beforeClose: null,
  zIndex: null,
  noBodyPadding: false,
  hideBottomLine: false,
})

const store = useStore()
const slots = useSlots()
useKeyboardShortcuts([
  [
    [[KeyboardSpecialKeyTypes.Escape]],
    () => {
      close()
    },
  ],
])

const moving = ref(false)
const currentModalStackPosition = ref(0)
const isModalXl = ref(props.size == 'xl' ? true : false)
const mousePositionX = ref(0)
const mousePositionY = ref(0)
const modalRef = ref(null)
const modalDialogRef = ref(null)
const modal = ref(null)
const isModalFullScreen = ref(false)

let previousModalTop: number | null = null
let previousModalLeft: number | null = null

provide('modalEl', modalRef)
provide('inModal', ref(true))
provide('isModalFullScreen', isModalFullScreen)

onMounted(() => {
  modal.value = new Modal(modalRef.value, { backdrop: 'static', keyboard: false })
  modal.value.show()
  setZIndexes()
  modalRef.value.focus() // prevent closing parent modal with esc
  modalRef.value.addEventListener('hidden.bs.modal', () => {
    store.commit('modals/close')
    emit('closed')
  })
})

const modalSizeClass = computed(() => {
  if (isModalFullScreen.value) {
    return 'modal-fullscreen'
  } else if (isModalXl.value) {
    return 'modal-xl'
  } else {
    return `modal-${props.size}`
  }
})

const scrollableContentClass = computed(() => {
  return props.scrollableContent ? 'modal-dialog-scrollable' : ''
})

const modalClasses = computed(() => {
  return [modalSizeClass.value, scrollableContentClass.value]
})

const modalZIndex = computed(() => {
  if (props.zIndex) {
    return props.zIndex
  }
  return MODAL_Z_INDEX + currentModalStackPosition.value * LAYER_Z_INDEX_STEP
})

const backdropZIndex = computed(() => {
  return BACKDROP_Z_INDEX + currentModalStackPosition.value * LAYER_Z_INDEX_STEP
})

const modalCounter = computed(() => {
  return store.getters['modals/modalCounter']
})

watch(modalCounter, value => {
  if (value === currentModalStackPosition.value + 1) {
    modalRef.value?.focus() // allows closing multiple modals with esc
  }
})

onBeforeUnmount(() => {
  modal?.value?.hide()
})

async function close() {
  if (props.beforeClose) {
    await props.beforeClose()
  }
  modal.value?.hide()
}

function resize() {
  isModalXl.value = !isModalXl.value
}

function fullScreen() {
  if (isModalFullScreen.value) {
    isModalFullScreen.value = false
    modalDialogRef.value.style.left = previousModalLeft || 0
    modalDialogRef.value.style.top = previousModalTop || 0
  } else {
    previousModalLeft = modalDialogRef.value.style.left
    previousModalTop = modalDialogRef.value.style.top
    modalDialogRef.value.style.left = '0'
    modalDialogRef.value.style.top = '0'
    isModalFullScreen.value = true
  }
}

function onResizeEnd() {
  eventHub.$emit('modal-resize')
}

function mouseDown(e) {
  if (!props.nonDraggable && !isModalFullScreen.value) {
    mousePositionX.value = e.clientX
    mousePositionY.value = e.clientY
    document.body.addEventListener('mousemove', mouseMove)
  }
}

function removeMouseMoveEventListener() {
  moving.value = false
  document.body.removeEventListener('mousemove', mouseMove)
}

function mouseMove(e) {
  const mouseOffsetLeft = mousePositionX.value - e.clientX
  const mouseOffsetTop = mousePositionY.value - e.clientY
  const dialog = modalDialogRef.value

  if (e.clientY > 0 && e.clientX > 0) {
    dialog.style.top = (parseInt(dialog.style.top) || 0) - mouseOffsetTop + 'px'
    dialog.style.left = (parseInt(dialog.style.left) || 0) - mouseOffsetLeft + 'px'

    mousePositionX.value = e.clientX
    mousePositionY.value = e.clientY
  } else {
    removeMouseMoveEventListener()
  }
}

function setZIndexes() {
  let name: string | VNodeNormalizedChildren = props.name
  try {
    if (!name?.length) {
      name = slots.header()?.[0]?.children
    }
  } catch (e) {
    console.warn("Error handled, no header for modal - falling back to 'Modal - number' name", e)
    name = `Modal ${currentModalStackPosition.value + 1}`
  }

  store.commit('modals/open', {
    name,
    modalRef: {
      isModalFullScreen,
      close,
    },
  })
  currentModalStackPosition.value = modalCounter.value - 1
  modal.value._element.style.zIndex = modalZIndex.value
  modal.value._backdrop._element.style.zIndex = backdropZIndex.value
}
</script>

<template lang="pug">
teleport(to="#modal-portal")
  .modal(ref="modalRef" @click.stop tabindex="-1")
    .modal-dialog(ref="modalDialogRef" :class="modalClasses" @transitionend="onResizeEnd")
      .modal-content(:name="'modalCounter-' + currentModalStackPosition")
        ea-spinner(v-if="blocker" :spinnerSize="30" :withoutTimeout="withoutTimeout" matchParent)
        .modal-header.px-3.pt-4(
          v-if="!!slots?.header"
          @mousedown.prevent="mouseDown"
          @mouseup.prevent="removeMouseMoveEventListener"
        )
          h5.modal-title
            slot(:close="close" name="header")
          slot(:close="close" name="header-actions")
          .d-flex.align-items-center.align-self-start
            button.btn.btn-sm.btn-link.text-black-50.ps-2.pe-2.me-1.d-none.d-xl-block(
              v-if="!isModalFullScreen && size != 'xl'"
              @click="resize()"
              @mousedown.stop
              style="padding-top: 0.35rem"
              type="button"
              v-tooltip.options="{ title: isModalXl ? 'Back to initial size' : 'Make wider' }"
            )
              i.fas.fa-compress-alt.d-inline-block.transform-45deg(
                v-if="isModalXl"
                style="font-size: 1.15em; text-decoration: none !important"
              )
              i.fas.fa-expand-alt.d-inline-block.transform-45deg(
                v-else
                style="font-size: 1.15em; text-decoration: none !important"
              )
            button.btn.btn-sm.btn-link.text-black-50.ps-2.pe-2.me-3(
              @click="fullScreen()"
              style="padding-top: 0.35rem"
              type="button"
              v-tooltip="isModalFullScreen ? 'Make smaller' : 'Full screen'"
            )
              i.fas.fa-compress(v-if="isModalFullScreen" style="font-size: 1.15em")
              i.fas.fa-expand(v-else style="font-size: 1.15em")
            button.btn.btn-sm.btn-bg-white.bi.ps-2.pe-2(
              @click="close"
              @mousedown.stop
              aria-label="Close"
              input-class="form-control-sm"
              type="button"
            )
              i.bi-x
              //- i.fa.fa-times
        .modal-body.bg-light(:class="[modalBodyClass, { 'no-padding': props.noBodyPadding }]")
          slot(:close="close" name="default")
          .bottom-line(v-if="!props.hideFooter && !props.hideBottomLine")
        .modal-footer(v-if="!props.hideFooter")
          slot(:close="close" name="footer")
            dismiss-button(@click="close()")
</template>

<style scoped lang="scss">
.modal-body {
  z-index: 1;
  position: relative;
  &.no-padding {
    padding: 0 !important;
  }
}

.modal-header {
  padding-bottom: 0;
}
</style>
