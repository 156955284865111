import { defineAsyncComponent } from 'vue'

const TransferGroup = defineAsyncComponent(() => import('./transfer-group.vue'))
const TransferGroupDetails = defineAsyncComponent(() =>
  import('./transfer-group-details/transfer-group-details.vue'),
)
const TransferGroupsIndex = defineAsyncComponent(() => import('./transfer-groups-index.vue'))
const TransferGroupsTable = defineAsyncComponent(() => import('./transfer-groups-table.vue'))
const TransferGroupAasmState = defineAsyncComponent(() => import('./transfer-group-aasm-state.vue'))
const TransferGroupPassengersList = defineAsyncComponent(() =>
  import('./transfer-group-passengers-list.vue'),
)

const AASM_STATES = [
  { value: 'pending', label: 'Pending', action: 'reset_state' },
  { value: 'in_route', label: 'In route', action: 'start_pickup_journey' },
  { value: 'at_pickup_site', label: 'At pick-up site', action: 'arrive_at_pickup_site' },
  { value: 'picked_up', label: 'Picked up', action: 'onboard_clients' },
  { value: 'in_progress', label: 'In progress', action: 'start_journey' },
  { value: 'at_dropoff_site', label: 'At drop-off site', action: 'arrive_at_dropoff_site' },
  { value: 'dropped_off', label: 'Dropped off', action: 'dropoff_clients' },
  { value: 'no_show', label: 'Incomplete', action: 'nobody_to_onboard' },
]

export * from './helpers'

export {
  AASM_STATES,
  TransferGroup,
  TransferGroupAasmState,
  TransferGroupDetails,
  TransferGroupPassengersList,
  TransferGroupsIndex,
  TransferGroupsTable,
}
