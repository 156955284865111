<script>
export default {
  name: 'BreakingList',
  inheritAttrs: false,
  props: {
    items: { type: Array, default: () => [] },
    labelFactory: { type: Function, default: item => item?.name },
    nowrap: { type: Boolean, default: true },
    higlightable: { type: Boolean, default: false },
    comma: { type: Boolean, default: true },
    isBadge: { type: Boolean, default: false },
  },
}
</script>

<template lang="pug">
.item-label(
  v-for="(item, index) in items"
  v-bind="$attrs"
  :class="{ 'text-nowrap': nowrap, higlightable: higlightable }"
)
  slot(:item="item")
    span(v-bind="$attrs")
      | {{ labelFactory(item) }}
  span(v-if="comma && index !== items.length - 1 && !isBadge")
    | ,&nbsp;
</template>

<style scoped lang="scss">
.item-label {
  transition: background 0.3s;
  // line-height: 1.35;
  padding: 0 0.1em 0.2em 0;
  display: inline-block;
}
.higlightable:hover {
  border-radius: 5px;
  background: rgb(255, 252, 225);
  transition: background 0.3s;
}
</style>
