<script>
import 'fifa-gis-library/dist/index.css'

import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import FifaGisItem from './map-items/fifa-gis-item.vue'
import { defaultIcon, FIFA_HEADQUARTERS_LAT_LNG, useFifaGisMap } from './use-fifa-gis-map'

export default {
  name: 'FifaGisMapPreview',
  props: {
    location: {
      type: Object,
    },
    mapCenterItem: {
      type: Object,
      validator(el) {
        return !el || (!!el.type && !!el.coordinates)
      },
    },
    mapItems: {
      type: Array,
    },
    boundingBox: {
      type: Object,
    },
  },
  setup(props) {
    const mapRef = ref(null)
    const mapContainer = ref(null)
    let resizeObserver = null

    const { initializeMap, map, isMapLoaded, centerMapOn } = useFifaGisMap(mapRef)
    const marker = ref(null)

    const markerPosition = computed(() => {
      const { longitude, latitude } = props.location || {}

      if (latitude && longitude) {
        return {
          lat: Number.parseFloat(latitude),
          lng: Number.parseFloat(longitude),
        }
      } else {
        return null
      }
    })

    const initialMapCenter = computed(() => {
      if (markerPosition.value) {
        return { coordinates: [markerPosition.value.lat, markerPosition.value.lng], type: 'Point' }
      } else if (props.boundingBox) {
        return props.boundingBox
      } else if (props.mapCenterItem) {
        return props.mapCenterItem
      } else {
        return FIFA_HEADQUARTERS_LAT_LNG
      }
    })

    onMounted(async () => {
      await initializeMap({ item: initialMapCenter.value })
      centerMapOn(initialMapCenter.value, { padding: [30, 30], duration: 0, maxDuration: 0 })

      if (markerPosition.value) {
        marker.value = map.value.addMarker({
          position: markerPosition.value,
          icon: defaultIcon(),
        })
      }

      resizeObserver = new ResizeObserver(() => {
        if (typeof map.value?.map?._onResize === 'function') {
          map.value.map._onResize()
        }
      })

      resizeObserver.observe(mapContainer.value)
    })

    onBeforeUnmount(() => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }),
      watch(
        () => props.boundingBox,
        newBoundingBox => {
          if (newBoundingBox) {
            centerMapOn(newBoundingBox, { padding: [50, 50], duration: 0, maxDuration: 0 })
          }
        },
      )

    onBeforeUnmount(() => {
      if (map.value) {
        map.value.dispose()
      }
    })

    return { isMapLoaded, mapRef, map, mapContainer }
  },
  components: {
    FifaGisItem,
  },
}
</script>

<template lang="pug">
.fifa-gis-map-preview.position-relative(ref="mapContainer")
  ea-spinner(v-if="!isMapLoaded" :spinnerSize="30" :withoutTimeout="false" matchParent)
  //- .loader-container.position-absolute.top-0.left-0.w-100.h-100.d-flex.align-items-center.justify-content-center.bg-white(
  //-   v-if="!isMapLoaded"
  //-   style="--bs-bg-opacity: 0.8"
  //- )
    loading-indicator
  .fifa-gis-map-container(ref="mapRef")
  template(v-if="isMapLoaded")
    fifa-gis-item(v-for="(item, index) in mapItems" :item="item" :key="index")
    slot(name="map-items")
slot
</template>

<style lang="scss">
.loader-container {
  z-index: 1000;
  min-height: 500px;
  height: 500px;
}
.fifa-gis-map-preview {
  height: 500px;
  min-height: 500px;

  .editor-container {
    width: 100%;
  }
  .fifa-gis-map-container {
    height: 500px;
  }
}
</style>
