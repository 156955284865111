<script>
import SearchCriterionPreviewContainer from 'frontend/common/data-tables/search-criteria/SearchCriterionPreviewContainer.vue'

export default {
  name: 'DataTableUsedSearchCriteria',
  emits: ['update:filters'],
  props: {
    filters: {
      type: Object,
    },
    searchCriteria: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activeFilters() {
      return Object.keys(this.filters ?? {}).filter(
        k => !!this.filters[k] || this.filters[k] == 0 || this.filters[k] == false,
      )
    },
  },
  mounted() {
    this.$eventHub.$on('keydown', this.listenForFiltersRemoval)
  },
  unmounted() {
    this.$eventHub.$off('keydown', this.listenForFiltersRemoval)
  },
  methods: {
    listenForFiltersRemoval(event) {
      if (
        this.activeFilters?.length &&
        event.shiftKey &&
        event.key === 'Backspace' &&
        event.target?.tagName?.toLowerCase() != 'input' &&
        event.srcElement?.tagName?.toLowerCase() != 'input' &&
        !this.isBehindOtherElement(this.$refs.filtersContainerRef)
      ) {
        event.stopPropagation()
        event.preventDefault()
        this.removeFilter(this.activeFilters[this.activeFilters.length - 1])
      }
    },
    isBehindOtherElement(element) {
      const boundingRect = element.getBoundingClientRect()
      const left = boundingRect.left + 1
      const right = boundingRect.right - 1
      const top = boundingRect.top + 1
      const bottom = boundingRect.bottom - 1

      if (!element.contains(document.elementFromPoint(left, top))) return true
      if (!element.contains(document.elementFromPoint(right, top))) return true
      if (!element.contains(document.elementFromPoint(left, bottom))) return true
      if (!element.contains(document.elementFromPoint(right, bottom))) return true

      return false
    },
    removeFilter(f) {
      const newFilters = { ...this.filters }
      newFilters[f] = null
      // const newFilters = Object.assign({}, this.filters, this.localFilters)
      this.$emit('update:filters', newFilters)
    },
    clearAllFilters() {
      this.$emit('update:filters', {})
    },
  },
  components: {
    SearchCriterionPreviewContainer,
  },
}
</script>

<template lang="pug">
.data-table-used-filters.flex-fill.align-items-center.justify-content-start.flex-wrap(
  ref="filtersContainerRef"
)
  button.button.btn.fw-500.btn-text.text-decoration-none.px-2(
    v-if="activeFilters?.length > 0"
    @click="clearAllFilters"
  ) Clear all
    template(v-if="activeFilters?.length > 0") &nbsp;({{ activeFilters.length }})
  template(v-for="filterKey in activeFilters" :key="filterKey")
    SearchCriterionPreviewContainer(
      :filterKey="filterKey"
      :filterValue="filters[filterKey]"
      :searchCriteria="searchCriteria"
      @remove-filter="removeFilter(filterKey)"
    )
</template>
