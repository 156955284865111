<script>
import FifaGisMarker from './fifa-gis-marker.vue'
import FifaGisPolygon from './fifa-gis-polygon.vue'
import FifaGisPolyline from './fifa-gis-polyline.vue'

export default {
  name: 'FifaGisItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mapItem() {
      const geometryTransformer = this.item?.geometryTransformer?.FIFA_GIS

      if (geometryTransformer) {
        const result = {
          ...this.item,
          coordinates: geometryTransformer(this.item),
        }
        return result
      } else {
        return this.item
      }
    },
  },
  components: {
    FifaGisMarker,
    FifaGisPolygon,
    FifaGisPolyline,
  },
}
</script>

<template lang="pug">
template(v-if="mapItem?.type === 'Point'")
  fifa-gis-marker(:marker="mapItem")
template(v-else-if="mapItem?.type === 'Polygon'")
  fifa-gis-polygon(:polygon="mapItem")
template(v-else-if="mapItem?.type === 'Polyline'")
  fifa-gis-polyline(:polyline="mapItem")
</template>
