import { ClientGroup } from 'frontend/client-groups'
import { Cluster } from 'frontend/clusters'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { DriverCategory } from 'frontend/driver-categories'
import { DriverSource } from 'frontend/driver-sources'
import { Driver } from 'frontend/drivers'
import { Location, LocationWithTp } from 'frontend/locations'
import { Osl } from 'frontend/osls'
import { Shift } from 'frontend/shifts'
import { ShuttleService } from 'frontend/shuttle-services'
import { BusIdDisplay, SOURCES } from 'frontend/shuttle-transfers'
import AasmState from 'frontend/shuttle-transfers/shuttle-transfer-aasm-state.vue'
import { TransportationPoint } from 'frontend/transportation-points'
import { VehicleCategory } from 'frontend/vehicle-categories'
import { VehicleModel } from 'frontend/vehicle-models'
import { VehicleType } from 'frontend/vehicle-types'
import { Vehicle } from 'frontend/vehicles'
import { camelCase } from 'lodash'
import { markRaw } from 'vue'

export const ShuttleTransferDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'start_at',
    'end_at',
    'updated_at',
    'driver_comment',
    'bus_id',
    'aasm_state',
    'source',
    'remote_id',
    'vehicle_type',
    {
      shuttle_service: ['id', 'eid', 'name', 'bus_id'],
    },
    { vehicle_types: ['id', 'name'] },
    { client_groups: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
    {
      operational_service_levels: [
        'id',
        'name',
        'font_color',
        'background_color',
        'border_color',
        'description',
      ],
    },
    {
      shuttle_points: [
        'id',
        'position',
        'arrival_at',
        {
          location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
        },
      ],
    },
    {
      day_labels: ['id', 'name', 'font_color', 'background_color', 'border_color'],
    },
    { start_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
    { end_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
    { start_transportation_point: ['id', 'name'] },
    { end_transportation_point: ['id', 'name'] },

    {
      shift: [
        'id',
        'kind',
        'eid',
        {
          staging_area: [
            'id',
            {
              location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
            },
          ],
        },
        {
          driver: [
            'id',
            'name',
            'surname',
            'assigned_phone',
            'personal_phone',
            'primary_phone',
            { cluster: ['id', 'name', 'time_zone'] },
            {
              driver_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
            },
            {
              driver_source: ['id', 'name'],
            },
          ],
        },
        {
          vehicle: [
            'id',
            'fleet_number',
            'registration_plate',
            { cluster: ['id', 'name', 'time_zone'] },
            { vehicle_model: ['id', 'name', { vehicle_brand: ['id', 'name'] }] },
            { vehicle_type: ['id', 'name'] },
            { vehicle_source: ['id', 'name', 'billable', 'maintainable'] },
            {
              vehicle_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
            },
          ],
        },
      ],
    },
  ],
  subscriptions: {
    items: 'ShuttleTransfersChannel',
    operationalServiceLevels: 'OperationalServiceLevelsSimpleChannel',
    locations: 'LocationsSimpleChannel',
    shifts: 'ShiftsSimpleChannel',
    drivers: 'DriversDetailedChannel',
    driverCategories: 'DriverCategoriesSimpleChannel',
    vehicles: 'VehiclesDetailedChannel',
    vehicleTypes: 'VehicleTypesSimpleChannel',
    clientGroups: 'ClientGroupsSimpleChannel',
    vehicleCategories: 'VehicleCategoriesSimpleChannel',
    vehicleModels: 'VehicleModelsSimpleChannel',
    vehicleBrands: 'VehicleBrandsSimpleChannel',
    transportationPoints: 'TransportationPointsSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'startAt',
    'aasmState',
    'busId',
    'shuttleService',
    'osls',
    'vehicleTypes',
    'clientGroups',
    'pu',
    'do',
    'otherLocations',
    'dayLabels',
    'shift',
    'assignedDriver',
    'assignedVehicle',
    'source',
    'updatedAt',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },

    startAt: {
      label: 'Start at',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.startAt, tz: item.startLocation?.timeZone }),
      },
    },

    startAtTime: {
      label: 'Start time',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.startAt,
          tz: item.startLocation?.timeZone,
          format: 'timeOnly',
        }),
      },
    },

    startAtDate: {
      label: 'Start date',
      header: {
        sort: 'startAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.startAt,
          tz: item.startLocation?.timeZone,
          format: 'dateOnly',
        }),
      },
    },

    endAt: {
      label: 'End at',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.endAt, tz: item.endLocation?.timeZone }),
      },
    },

    endAtTime: {
      label: 'End time',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.endAt,
          tz: item.endLocation?.timeZone,
          format: 'timeOnly',
        }),
      },
    },

    endAtDate: {
      label: 'End date',
      header: {
        sort: 'endAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.endAt,
          tz: item.endLocation?.timeZone,
          format: 'dateOnly',
        }),
      },
    },

    aasmState: {
      label: 'State',
      header: {
        sort: 'aasmState',
      },
      cell: {
        kind: 'component',
        component: markRaw(AasmState),
        componentProps: item => ({
          aasmState: item.aasmState,
        }),
      },
    },

    shuttleService: {
      label: 'Shuttle Service',
      header: {
        sort: 'shuttleServiceLowerName',
      },
      cell: {
        kind: 'component',
        component: markRaw(ShuttleService),
        componentProps: el => ({
          shuttleService: el.shuttleService,
        }),
      },
    },

    osls: {
      label: 'OSLs',
      tooltip: 'Operational service levels',
      header: {
        // sort: 'operationalServiceLevelPosition',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: el.operationalServiceLevels,
          limit: 5,
          component: markRaw(Osl),
          componentProps: ell => ({ osl: ell }),
        }),
      },
    },
    vehicleTypes: {
      label: 'Vehicle types',
      header: {
        // sort: 'vehicleTypeName',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: el.vehicleTypes,
          limit: 5,
          component: markRaw(VehicleType),
          componentProps: ell => ({ vehicleType: ell }),
        }),
      },
    },
    clientGroups: {
      label: 'Client groups',
      header: {
        // sort: 'clientGroupsName',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: el.clientGroups,
          limit: 5,
          component: markRaw(ClientGroup),
          componentProps: ell => ({ clientGroup: ell }),
        }),
      },
    },

    startCluster: {
      label: 'First Cluster',
      header: {
        sort: 'startLocationClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: tb => ({
          cluster: tb.startLocation?.cluster,
        }),
      },
    },

    startLocation: {
      label: 'First Location',
      header: {
        sort: 'startLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: tb => ({
          location: tb.startLocation,
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },

    startTransportationPoint: {
      label: 'First TP',
      tooltip: 'First transportation point',
      header: {
        sort: 'startTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: tb => ({
          transportationPoint: tb.startTransportationPoint,
          kindIn: "['shuttle']",
          nowrap: true,
        }),
      },
    },

    pu: {
      label: 'First location with TP',
      tooltip: 'First location with transportation point',
      header: {
        sort: 'startLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(LocationWithTp),
        componentProps: el => ({
          item: el,
          kind: 'start',
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },

    endCluster: {
      label: 'Last Cluster',
      header: {
        sort: 'endLocationClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: tb => ({
          cluster: tb.endLocation?.cluster,
        }),
      },
    },

    endLocation: {
      label: 'Last Location',
      header: {
        sort: 'endLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Location),
        componentProps: tb => ({
          location: tb.endLocation,
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },

    endTransportationPoint: {
      label: 'Last TP',
      tooltip: 'Last transportation point',

      header: {
        sort: 'endTransportationPointName',
      },
      cell: {
        kind: 'component',
        component: markRaw(TransportationPoint),
        componentProps: tb => ({
          transportationPoint: tb.endTransportationPoint,
          kindIn: "['shuttle']",
          nowrap: true,
        }),
      },
    },

    do: {
      label: 'Last location with TP',
      tooltip: 'Last location with transportation point',
      header: {
        sort: 'endLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(LocationWithTp),
        componentProps: tb => ({
          item: tb,
          kind: 'end',
          // nowrap: true,
          minWidth: 250,
        }),
      },
    },
    busId: {
      label: 'BUS ID',
      header: {
        sort: 'busIdOrShuttleServiceBusId',
      },
      cell: {
        kind: 'component',
        component: BusIdDisplay,
        componentProps: el => ({
          item: el,
        }),
      },
    },
    source: {
      label: 'Source',
      header: {
        sort: 'sourceWithRemoteId',
      },
      cell: {
        kind: 'function',
        fn: el => {
          const name = SOURCES.find(s => s.value == (el.source || 'manually_created'))?.label
          if (
            ['imported', 'synchronized_via_api', 'overwritten_api_synchronization'].includes(
              el.source,
            )
          ) {
            return `${name} with Remote ID ${el.remoteId}`
          } else {
            return name
          }
        },
      },
    },
    remoteId: {
      label: 'Remote ID',
      header: {
        sort: 'remoteId',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'remoteId',
      },
    },
    otherLocations: {
      label: 'Other locations in route',
      header: {
        // sort: 'clientFullName',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: otherLocations(el),
          limit: 5,
          component: markRaw(Location),
          componentProps: l => ({ location: l, noCluster: true }),
        }),
      },
    },
    dayLabels: {
      label: 'Occasion',
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: tb => ({
          items: tb.dayLabels,
          limit: 5,
          component: 'erebus-label',
          componentProps: c => ({ item: c }),
        }),
      },
    },
    shift: {
      label: 'Assigned Shift',
      header: {
        sort: 'shiftStagingAreaLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Shift),
        componentProps: tb => ({
          shift: tb.shift,
          detailed: true,
        }),
      },
    },

    assignedDriver: {
      label: 'Driver',
      header: {
        sort: 'shiftDriverReverseFullName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Driver),
        componentProps: tb => ({
          driver: tb.shift?.driver,
          detailed: true,
        }),
      },
    },

    assignedDriverName: {
      label: 'Driver name',
      header: {
        sort: 'shiftDriverName',
      },
      cell: {
        kind: 'simple',
        path: 'shift.driver.name',
      },
    },

    assignedDriverSurname: {
      label: 'Driver surname',
      header: {
        sort: 'shiftDriverSurname',
      },
      cell: {
        kind: 'simple',
        path: 'shift.driver.surname',
      },
    },

    assignedDriverPhoneNumber: {
      label: 'Driver phone number',
      header: {
        sort: 'shiftDriverMainPhone',
      },
      cell: {
        kind: 'component',
        component: 'phone-number',
        componentProps: tb => ({
          phoneNumber: tb.shift?.driver?.[camelCase(tb.shift?.driver?.primaryPhone || '')],
        }),
      },
    },

    assignedDriverCategory: {
      label: 'Driver category',
      header: {
        sort: 'shiftDriverDriverCategoryName',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverCategory),
        componentProps: tb => ({
          driverCategory: tb.shift?.driver?.driverCategory,
        }),
      },
    },

    assignedDriverSource: {
      label: 'Driver source',
      header: {
        sort: 'shiftDriverDriverSourceName',
      },
      cell: {
        kind: 'component',
        component: markRaw(DriverSource),
        componentProps: tb => ({
          driverSource: tb.shift?.driver?.driverSource,
        }),
      },
    },

    driverComment: {
      label: 'Driver comment',
      header: {
        sort: 'driver_comment',
      },
      cell: {
        kind: 'component',
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item.driverComment,
          limit: 250,
        }),
      },
    },

    assignedVehicle: {
      label: 'Vehicle',
      header: {
        sort: ['shiftVehicleFleetNumberPrefix', 'shiftVehicleFleetNumberInt'],
      },
      cell: {
        kind: 'component',
        component: markRaw(Vehicle),
        componentProps: tb => ({
          vehicle: tb.shift?.vehicle,
          detailed: true,
        }),
      },
    },

    assignedVehicleFleetNumber: {
      label: 'Vehicle fleet number',
      header: {
        sort: ['shiftVehicleFleetNumberPrefix', 'shiftVehicleFleetNumberInt'],
      },
      cell: {
        kind: 'simple',
        path: 'shift.vehicle.fleetNumber',
      },
    },

    assignedVehicleRegistrationPlate: {
      label: 'Vehicle reg. plate',
      tooltip: 'Vehicle registration plate',
      header: {
        sort: 'shiftVehicleRegistrationPlate',
      },
      cell: {
        kind: 'simple',
        path: 'shift.vehicle.registrationPlate',
      },
    },

    assignedVehicleCluster: {
      label: 'Vehicle cluster',
      header: {
        sort: 'shiftVehicleVehicleClusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: tb => ({
          cluster: tb.shift?.vehicle?.cluster,
        }),
      },
    },

    assignedVehicleCategory: {
      label: 'Vehicle category',
      header: {
        sort: 'shiftVehicleVehicleCategoryName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleCategory),
        componentProps: tb => ({
          vehicleCategory: tb.shift?.vehicle?.vehicleCategory,
        }),
      },
    },

    assignedVehicleType: {
      label: 'Assigned vehicle type',
      header: {
        sort: 'shiftVehicleVehicleTypeName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleType),
        componentProps: tb => ({
          vehicleType: tb.shift?.vehicle?.vehicleType,
        }),
      },
    },

    assignedVehicleModel: {
      label: 'Vehicle model',
      header: {
        sort: 'shiftVehicleVehicleModelName',
      },
      cell: {
        kind: 'component',
        component: markRaw(VehicleModel),
        componentProps: tb => ({
          vehicleModel: tb.shift?.vehicle?.vehicleModel,
        }),
      },
    },

    shiftDetails: {
      label: 'Assigned Shift Details',
      header: {
        sort: 'shiftStagingAreaLocationName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Shift),
        componentProps: tb => ({
          shift: tb.shift,
          detailed: true,
          withDriver: true,
          withVehicle: true,
        }),
      },
    },

    updatedAt: {
      label: 'Updated datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },

    updatedAtTime: {
      label: 'Updated time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },

    updatedAtDate: {
      label: 'Updated date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
  },
}
