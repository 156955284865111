//cspell:ignoreWords valign
import { FilterXSS } from 'xss'

export const tableWhitelist = {
  caption: [],
  table: ['width', 'border', 'align', 'valign'],
  tbody: ['align', 'valign'],
  td: ['width', 'rowspan', 'colspan', 'align', 'valign'],
  tfoot: ['align', 'valign'],
  th: ['width', 'rowspan', 'colspan', 'align', 'valign'],
  thead: ['align', 'valign'],
  tr: ['rowspan', 'align', 'valign'],
  tt: [],
  col: ['align', 'valign', 'span', 'width'],
  colgroup: ['align', 'valign', 'span', 'width'],
}

export const imageWhitelist = {
  img: ['src', 'alt', 'title', 'width', 'height'],
}

//can be used malicious to redirect user to other pages
export const linkWhitelist = {
  a: ['href'],
}

//original default https://github.com/leizongmin/js-xss/blob/master/lib/default.js
export function getDefaultWhiteList(whitelist = {}) {
  return {
    abbr: ['title'],
    center: [],
    code: [],

    dd: [],
    details: ['open'],
    div: ['class', 'id'],

    dl: [],
    dt: [],

    hr: [],
    br: [],

    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    p: [],

    header: [],
    footer: [],

    pre: [],

    section: [],
    small: [],
    span: ['class', 'id', 'style'],

    summary: [],

    sub: [],
    sup: [],

    em: [],
    mark: [],
    strong: [],
    strike: [],

    b: [],
    i: [],
    s: [],
    u: [],

    ol: [],
    ul: [],
    li: [],
    ...whitelist,
  }
}

const sanitizer = new FilterXSS({
  whiteList: getDefaultWhiteList(),
})

function createSanitizer(whitelist) {
  return new FilterXSS({
    whiteList: getDefaultWhiteList(whitelist),
  })
}

export { createSanitizer, sanitizer }
