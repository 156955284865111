<script>
export default {
  name: 'TransportationPointEnabledStatuses',
  props: {
    transportationPoint: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template lang="pug">
.enabled-row.text-nowrap
  .kind-col.flex-column.d-flex.align-items-center
    .circle-frame(
      :class="{ enabled: transportationPoint.enabledForArrival }"
      v-tooltip="'Arrival'"
    )
      i.fas.fa-plane-arrival
  .kind-col.flex-column.d-flex.align-items-center
    .circle-frame(
      :class="{ enabled: transportationPoint.enabledForDeparture }"
      v-tooltip="'Departure'"
    )
      i.fas.fa-plane-departure
  .kind-col.flex-column.d-flex.align-items-center
    .circle-frame(:class="{ enabled: transportationPoint.enabledForOnsite }" v-tooltip="'On-site'")
      i.fas.fa-car
  .kind-col.flex-column.d-flex.align-items-center
    .circle-frame(
      :class="{ enabled: transportationPoint.enabledForShuttle }"
      v-tooltip="'Shuttle'"
    )
      i.fas.fa-bus-alt
</template>

<style>
.time-offset .circle-frame:last-child {
  margin-bottom: 1em;
}
</style>
