export enum KeyboardSpecialKeyTypes {
  Backspace = 'backspace',
  Tab = 'tab',
  Clear = 'clear',
  Enter = 'enter',
  Escape = 'escape',
  Left = 'left',
  Down = 'down',
  Up = 'up',
  Right = 'right',
  Space = 'space',
  Slash = 'slash',
  NumpadDivide = 'numpadDivide',
}
