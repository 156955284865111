<script>
import { computed, inject, onBeforeUnmount, ref, shallowRef, watch } from 'vue'

import { useFifaGisMap } from '../use-fifa-gis-map'

export default {
  name: 'FifaGisPolyline',
  props: {
    polyline: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const fifaGisMap = inject('fifaGisMap', ref(null))
    const fifaGisMapLoaded = inject('fifaGisMapLoaded', ref(false))

    const { preparePolyline } = useFifaGisMap()

    const instance = shallowRef(null)

    const isReady = computed(() => fifaGisMap.value && fifaGisMapLoaded.value)

    watch(
      [isReady, () => props.polyline],
      () => {
        if (!isReady.value) return

        if (!props.polyline?.type === 'Polyline' && instance.value) {
          instance.value.dispose()
        }

        if (instance.value) {
          instance.value.update(preparePolyline(props.polyline))
        } else {
          instance.value = fifaGisMap.value.addPolyline(preparePolyline(props.polyline))
        }
      },
      { immediate: true },
    )

    onBeforeUnmount(() => {
      if (instance.value) {
        instance.value.dispose()
        fifaGisMap.value.removePolyline(instance.value)
      }
    })

    return {
      fifaGisMap,
      fifaGisMapLoaded,
    }
  },
}
</script>

<template lang="pug"></template>
