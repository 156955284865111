import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'

export const GisRuleDataModelTypeConfig: IDataModelTypeConfig = {
  fields: ['id', 'cluster_id'],
  subscriptions: {
    items: 'GisRulesChannel',
  },
  defaultColumns: ['eid'],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'eid',
      },
    },
  },
}
