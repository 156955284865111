import latinize from 'latinize'
import { computed, onMounted, ref, toRefs } from 'vue'

export function useResourceSelect(
  props,
  fetchItems,
  { withFilter, propNamesToPass } = {
    withFilter: false,
    propNamesToPass: [],
  },
) {
  const { collection, modelValue, optionsFilter, reduce, labelFactory, keepFilteredOptgroups } =
    toRefs(props?.value || props)
  const selectItems = ref([])
  const search = ref('')

  const options = computed((): any[] => {
    return collection.value || selectItems.value || []
  })

  function isSelected(item) {
    const key = reduce?.value(item)
    const selected = modelValue.value
    return Array.isArray(selected) ? selected.includes(key) : selected === key
  }

  function parseProps(props, propName) {
    const propsObj = props?.value || props
    const isRef = propsObj[propName]?.__v_isRef
    const propContent = isRef ? propsObj[propName].value : propsObj[propName]
    return propContent
  }

  const selectedItems = computed(() => {
    return new Set(options.value.filter(isSelected))
  })

  let filteredOptions = options

  if (withFilter) {
    filteredOptions = computed(() => {
      let result = options.value

      if (typeof optionsFilter.value === 'function') {
        result = result.filter(optionsFilter.value)
      }

      if (search.value?.length) {
        const normalizedSearch = latinize(search.value).toLowerCase()

        result = result.filter(option => {
          if (keepFilteredOptgroups?.value && option?.isOptgroup) {
            return true
          }
          const normalizedLabel = latinize(labelFactory?.value(option)?.toLowerCase()).replace(
            /<[^>]*>?/gm,
            '',
          )
          return normalizedLabel.includes(normalizedSearch)
        })
      }

      return Array.from(new Set([...selectedItems.value, ...result]))
    })
  }

  const fetchAndSet = () => {
    const additionalPayload = {}
    if (propNamesToPass?.length) {
      propNamesToPass.forEach(propName => {
        const propContent = parseProps(props, propName)

        if (!!propContent || propContent == false || propContent == 0) {
          additionalPayload[propName] = propContent
        }
      })
    }
    return fetchItems(additionalPayload).then(items => {
      selectItems.value = items
    })
  }

  onMounted(() => {
    if (collection.value === false) {
      fetchAndSet()
    }
  })

  return {
    options: filteredOptions,
    unfilteredOptions: options,
    search,
    selectedItems,
    fetchAndSet,
  }
}
