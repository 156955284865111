<script setup lang="ts">
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'
import { computed } from 'vue'

interface Props {
  modelValue: unknown
  criterion: ISearchCriteriaNode
  predicate: string
}
const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: string): void
}
const emit = defineEmits<Emits>()

const valueModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

function onToggleBoolean(value: boolean) {
  if (valueModel.value === value) {
    valueModel.value = null
  } else {
    valueModel.value = value
  }
}

const coercedValue = computed(() => {
  return props.modelValue != null ? String(props.modelValue).toLowerCase() === 'true' : null
})
</script>

<template>
  <div class="boolean-criteria-value-type-input">
    <div
      class="option truthy"
      :class="{ 'is-selected': coercedValue === true }"
      @click="onToggleBoolean(true)"
    >
      yes
    </div>
    <div
      class="option falsy"
      :class="{ 'is-selected': coercedValue === false }"
      @click="onToggleBoolean(false)"
    >
      no
    </div>
  </div>
</template>

<style scoped lang="scss">
.boolean-criteria-value-type-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid;
  padding: 0 8px;
  cursor: pointer;
  min-height: 32px;

  &:hover {
    color: white;

    &.truthy {
      background: #6fcf97;
    }

    &.falsy {
      background: #ff7875;
      //background: #FF881A;
    }
  }

  &.truthy.is-selected {
    background-color: #6fcf97;
    color: white;
  }

  &.falsy.is-selected {
    background-color: #ff7875;
    //background-color: #FF881A;
    color: white;
  }
}
</style>
