<script>
export default {
  name: 'AddButton',
  emits: ['click'],
  props: {
    name: {
      type: String,
    },
    size: {
      type: String,
      default: null,
      validator(val) {
        return ['xs', 'sm', 'md', 'lg'].includes(val)
      },
    },
    outline: { type: Boolean, default: false },
  },
  computed: {
    sizeClass() {
      return this.size ? { [`btn-${this.size}`]: true } : {}
    },
    btnTypeClass() {
      const btnClass = this.outline ? 'btn-outline-success' : 'btn-success'
      return { [btnClass]: true }
    },
  },
}
</script>

<template lang="pug">
button.btn.text-white.add-button.with-icon(
  :class="{ ...sizeClass, ...btnTypeClass }"
  :name="name"
  @click="$emit('click', $event)"
  type="button"
)
  i.mi-outlined.mi-add
  slot Add a record
</template>
