<script setup lang="ts">
import { type Component, computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const modals = computed(() => {
  return Object.entries<{ props: any; component: Component; onClosed: () => void }>(
    store.getters['modals/globalModals'],
  )
})

function closed(key: string, event: unknown, onClosed: (result: unknown) => void) {
  store.commit('modals/removeGlobalModal', key)
  if (onClosed) {
    onClosed(event)
  }
}

onBeforeUnmount(() => {
  store.commit('modals/clearGlobalModals')
})
</script>

<template lang="pug">
template(v-for="[key, modal] in modals" :key="key")
  component(
    v-bind="modal.props"
    :is="modal.component"
    @closed="closed(key, $event, modal.onClosed)"
  )
</template>
