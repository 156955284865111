<script>
import 'mapbox-gl/dist/mapbox-gl.css'

import mapbox from 'mapbox-gl'
import { markRaw, onMounted, provide, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { mapEvents } from './constants/map-events'

export default {
  name: 'EaMapbox',
  emits: ['map:loaded', ...mapEvents],
  props: {
    // apiKey: {
    //   type: String,
    //   required: true,
    // },
    height: { type: Number, default: 500 },
    center: { type: [Array, Object] },
    mapCenterItem: { type: Object },
    mapItems: { type: Array },
    boundingBox: { type: Object },
    useCustomStyle: { type: Boolean, default: false },
    mapOptions: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const map = ref(null)
    const mapRef = ref(null)
    const mapLoaded = ref(false)
    const store = useStore()

    provide('map', map)
    provide('mapLoaded', mapLoaded)

    onMounted(() => {
      const customMapboxStyle = store.state.currentContext.event.customMapboxStyle
      const hasCustomStyle = !!customMapboxStyle?.length
      const mapStyles = hasCustomStyle
        ? JSON.parse(customMapboxStyle)
        : 'mapbox://styles/mapbox/streets-v11?optimized=true'

      mapbox.accessToken = atob(
        'cGsuZXlKMUlqb2laVzVuYVc1bFlYSmphQ0lzSW1FaU9pSmpiREIzWVhsc1luTXhNR2hrTTJsc2VuTjFNWHBzYmpsbEluMC5Ud0d2UTM0WVRTU0stVzE4VmdkWUNR',
      )
      map.value = markRaw(
        new mapbox.Map({
          container: mapRef.value,
          pitchWithRotate: false,
          rotate: false,
          style: mapStyles,
          ...props.mapOptions,
        }),
      )
      centerMap(true)
      listenMapEvents()
    })

    watch([() => props.center, () => props.mapCenterItem, () => props.boundingBox], () => {
      centerMap()
    })

    function centerMap(initial) {
      const bbox = props.boundingBox || props.mapCenterItem

      if (props.center) {
        if (initial) {
          map.value?.jumpTo({ center: props.center.map(c => parseFloat(c)), zoom: 12 })
        } else {
          map.value?.easeTo({ center: props.center.map(c => parseFloat(c)) })
        }
      } else if (bbox) {
        if (bbox.type == 'Polygon') {
          const itemPath = (bbox.coordinates?.[0] || []).map(c => ({
            lat: Number.parseFloat(c[0]),
            lng: Number.parseFloat(c[1]),
          }))
          map.value.fitBounds([itemPath[1], itemPath[3]], {
            padding: 35,
            maxDuration: 2000,
            speed: 2,
          })
        } else if (bbox.type == 'Point') {
          map.value.setCenter({
            lat: bbox.coordinates?.[0],
            lng: bbox.coordinates?.[1],
          })
          map.value.setZoom(12)
        }
      }
    }

    function listenMapEvents() {
      mapEvents.forEach(e => {
        map.value.on(e, evt => {
          switch (e) {
            case 'load':
              mapLoaded.value = true
              emit('map:loaded', map.value)
              map.value.resize()
              break
            default:
              emit(e, evt)
              break
          }
        })
      })
    }

    return {
      map,
      mapRef,
      mapLoaded,
    }
  },
}
</script>

<template lang="pug">
#mapbox-container(ref="mapRef" :style="`height: ${height}px`")
  template(v-if="mapLoaded")
    slot(:map="map")
slot(name="footer")
</template>

<style lang="scss" scoped>
#mapbox-container {
  width: 100%;
  height: 100%;
}
</style>
