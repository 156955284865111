/* eslint-disable @typescript-eslint/ban-types */
import emitter from 'tiny-emitter/instance'

export default {
  // emitter: emitter,
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
}

export interface EventHub {
  $on: (event: string, listener: Function) => void
  $once: (event: string, listener: Function) => void
  $off: (event: string, listener: Function) => void

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $emit: (event: string, ...args: any[]) => void
}
