<script>
import { inject, ref } from 'vue'

import { ResourceService } from './services/resource-service'

export default {
  name: 'RemoveItemButton',
  inheritAttrs: false,
  emits: ['removed', 'remove', 'blocker'],
  props: {
    item: { type: Object },
    path: {
      type: String,
    },
    service: {
      type: ResourceService,
    },
    table: {
      type: Boolean,
      default: false,
    },
    confirmationMessage: {
      type: String,
      default: 'Are you sure that you want to remove chosen item?',
    },
  },
  setup() {
    const axios = inject('axios')
    return {
      isBlocked: ref(false),
      apiProvider: axios,
    }
  },
  methods: {
    confirmAction(action) {
      this.$dialogs
        .confirm(this.confirmationMessage)
        .then(action)
        .catch(() => false)
    },
    removeItem() {
      if (this.path) {
        this.isBlocked = true
        // this.$emit('blocker', true)
        this.apiProvider({
          method: 'delete',
          url: this.path,
        })
          .then(() => {
            this.$success({ message: 'The item has been removed' })
          })
          .catch(err => {
            console.error(err)
            const message =
              err?.response?.data?.errors?.base?.join('; ') ??
              err?.response.data?.errors ??
              "Can't remove the item"
            this.$error({ message })
          })
          .finally(() => {
            // this.$emit('blocker', false)
            this.isBlocked = false
            this.$emit('removed', true)
          })
      } else {
        this.$emit('remove')
      }
    },
    onRemoveClick() {
      this.confirmAction(this.removeItem)
    },
  },
}
</script>

<template lang="pug">
.d-inline-flex.position-relative(:class="{ disabled: isBlocked }" @click="onRemoveClick($event)")
  ea-spinner(v-if="isBlocked" :spinnerSize="10" matchParent)
  slot
    template(v-if="table")
      data-table-remove-button(v-bind="$attrs")
    template(v-else)
      remove-button(v-bind="$attrs")
</template>

<style scoped lang="scss"></style>
