export function pickTextColorBasedOnBgColor(bgColor: string): string {
  let r, g, b
  if (bgColor.charAt(0) === '#') {
    const color = bgColor.substring(1, 7)
    r = parseInt(color.substring(0, 2), 16) // hexToR
    g = parseInt(color.substring(2, 4), 16) // hexToG
    b = parseInt(color.substring(4, 6), 16) // hexToB
  } else {
    const color = bgColor
      .replace(/rgba?\(/g, '')
      .replace(/\)/g, '')
      .replace(/ /g, '')
      .split(',')
      .map(el => parseInt(el))
    r = color[0]
    g = color[1]
    b = color[2]
  }
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#333' : '#fff'
}
