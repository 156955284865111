<script>
import { inject, nextTick, provide, ref } from 'vue'

export default {
  name: 'ResponsiveDataTable',
  props: {
    skipFixedHeight: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: '100px',
    },
  },
  setup() {
    const isModalFullScreen = inject('isModalFullScreen')
    const inModal = inject('inModal')

    const tableHeight = ref(0)
    const tableResponsiveRef = ref(null)

    provide('tableHeight', tableHeight)

    return {
      tableHeight,
      tableResponsiveRef,
      isModalFullScreen,
      inModal,
    }
  },
  computed: {
    modalFooterHeight() {
      if (!this.tableResponsiveRef) {
        return 0
      } else {
        return (
          this.tableResponsiveRef.offsetParent.offsetParent.getElementsByClassName(
            'modal-footer',
          )[0]?.clientHeight || 0
        )
      }
    },
    modalBottomSpace() {
      return this.isModalFullScreen ? '0px' : '1rem'
    },
    computedTableHeight() {
      if (this.inModal && this.tableResponsiveRef?.offsetTop) {
        const modalTop = this.isModalFullScreen
          ? 0
          : this.tableResponsiveRef.offsetParent.offsetParent.offsetParent.offsetTop
        const modalBodyTop = this.tableResponsiveRef.offsetParent.offsetTop
        const itemOffsetInModalBody = this.tableResponsiveRef.offsetTop
        const modalBodyBottomPadding = '0.5rem'

        this.setProvidedTableHeight()
        if (
          window.innerHeight -
            (modalTop + modalBodyTop + itemOffsetInModalBody + this.modalFooterHeight) <
          200
        ) {
          return '200px'
        } else {
          const scrollFix = '1px'
          return `calc(100vh - (${modalTop}px + ${modalBodyTop}px + ${itemOffsetInModalBody}px + ${this.modalFooterHeight}px + ${modalBodyBottomPadding} + ${this.modalBottomSpace} + ${scrollFix}))`
        }
      } else {
        this.setProvidedTableHeight()
        return null
      }
    },
    stylesForContainer() {
      if (this.computedTableHeight) {
        return {
          height: this.skipFixedHeight ? 'auto' : this.computedTableHeight,
          maxHeight: this.computedTableHeight,
          minHeight: this.minHeight,
        }
      } else if (this.skipFixedHeight) {
        return { height: 'auto' }
      } else {
        return {}
      }
    },
  },
  mounted() {
    this.$eventHub.$on('windowResize', this.setProvidedTableHeight)
    this.$eventHub.$on('tab-changed', this.setProvidedTableHeight)
  },
  unmounted() {
    this.$eventHub.$off('windowResize', this.setProvidedTableHeight)
    this.$eventHub.$off('tab-changed', this.setProvidedTableHeight)
  },
  methods: {
    setProvidedTableHeight() {
      nextTick(() => {
        if (this.tableHeight != this.tableResponsiveRef?.clientHeight) {
          this.tableHeight = this.tableResponsiveRef?.clientHeight
        }
      })
    },
  },
}
</script>

<template lang="pug">
.table-responsive(
  ref="tableResponsiveRef"
  :class="{ 'skipped-fixed-height': skipFixedHeight }"
  :style="stylesForContainer"
)
  slot
</template>

<style scoped lang="scss">
.table-responsive {
  //display: grid;
  //grid-template-rows: 1fr auto;
  //align-items: flex-start;
  //justify-items: flex-start;

}
</style>
