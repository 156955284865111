/* eslint-disable import/default */
import copyText from '@meforma/vue-copy-to-clipboard'
import axios from 'frontend/_config/axios'
import TooltipDirective from 'frontend/_directives/tooltip'
import computeSchema from 'frontend/_globals/compute-schema'
import { dialogs } from 'frontend/_globals/dialogs'
import eventHub from 'frontend/_globals/event-hub'
import formatDate from 'frontend/_globals/format-date'
import newlinesToBr from 'frontend/_globals/newlines-to-br'
import normalizedSubscription from 'frontend/_globals/normalized-subscription'
import scrollToElement from 'frontend/_globals/scroll-to-element'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter.js'
import store from 'frontend/_stores/index'
import AddButton from 'frontend/common/add-button.vue'
import ArchiveButton from 'frontend/common/archive-button.vue'
import ArchiveItemButton from 'frontend/common/archive-item-button.vue'
import ContextMenu from 'frontend/common/context-menu.vue'
import CustomColumnsTrigger from 'frontend/common/customizable-columns/custom-columns-trigger.vue'
import DataTable from 'frontend/common/data-tables/data-table.vue'
import DataTableActionButton from 'frontend/common/data-tables/data-table-action-button.vue'
import DataTableArchiveButton from 'frontend/common/data-tables/data-table-archive-button.vue'
import DataTableBatchActions from 'frontend/common/data-tables/data-table-batch-actions.vue'
import DataTableCalendarButton from 'frontend/common/data-tables/data-table-calendar-button.vue'
import DataTableEditButton from 'frontend/common/data-tables/data-table-edit-button.vue'
import DataTableFilterPresets from 'frontend/common/data-tables/data-table-filter-presets.vue'
import DataTableFilters from 'frontend/common/data-tables/data-table-filters.vue'
import DataTableFooter from 'frontend/common/data-tables/data-table-footer.vue'
import DataTableHeaderCell from 'frontend/common/data-tables/data-table-header-cell.vue'
import DataTableNothingFound from 'frontend/common/data-tables/data-table-nothing-found.vue'
import DataTableRemoveButton from 'frontend/common/data-tables/data-table-remove-button.vue'
import DataTableRestoreButton from 'frontend/common/data-tables/data-table-restore-button.vue'
import DataTableShowButton from 'frontend/common/data-tables/data-table-show-button.vue'
import DataTableTbody from 'frontend/common/data-tables/data-table-tbody.vue'
import DataTableTools from 'frontend/common/data-tables/data-table-tools.vue'
import DataTableUsedFilters from 'frontend/common/data-tables/data-table-used-filters.vue'
import DataTableVersionButton from 'frontend/common/data-tables/data-table-version-button.vue'
import DenormalizedItem from 'frontend/common/data-tables/denormalized-item.vue'
import NewDataTable from 'frontend/common/data-tables/new-data-table.vue'
import ResponsiveDataTable from 'frontend/common/data-tables/responsive-data-table.vue'
import DataTableUsedSearchCriteria from 'frontend/common/data-tables/search-criteria//data-table-used-search-criteria.vue'
import DataTableSearchCriteria from 'frontend/common/data-tables/search-criteria/data-table-search-criteria.vue'
import DataTableSearchCriteriaWithPresets from 'frontend/common/data-tables/search-criteria/data-table-search-criteria-with-presets.vue'
import DismissButton from 'frontend/common/dismiss-button.vue'
import Dropdown from 'frontend/common/dropdown.vue'
import EaChooser from 'frontend/common/ea-chooser.vue'
import EaFilter from 'frontend/common/ea-filter/ea-filter.vue'
import EaFilterClearAll from 'frontend/common/ea-filter/ea-filter-clear-all.vue'
import EaCheckbox from 'frontend/common/ea-form-controls/ea-checkbox.vue'
import EaCheckboxGroup from 'frontend/common/ea-form-controls/ea-checkbox-group.vue'
import EaDateSelector from 'frontend/common/ea-form-controls/ea-date-selector.vue'
import EaDatetimepicker from 'frontend/common/ea-form-controls/ea-datetimepicker.vue'
import EaDraggableNestedFormCollection from 'frontend/common/ea-form-controls/ea-draggable-nested-form-collection.vue'
import EaFileInput from 'frontend/common/ea-form-controls/ea-file-input.vue'
import EaInput from 'frontend/common/ea-form-controls/ea-input.vue'
import EaNestedForm from 'frontend/common/ea-form-controls/ea-nested-form.vue'
import EaNestedFormCollection from 'frontend/common/ea-form-controls/ea-nested-form-collection.vue'
import EaRadioGroup from 'frontend/common/ea-form-controls/ea-radio-group.vue'
import EaSelect from 'frontend/common/ea-form-controls/ea-select.vue'
import EaSimpleCheckbox from 'frontend/common/ea-form-controls/ea-simple-checkbox.vue'
import EaSwitch from 'frontend/common/ea-form-controls/ea-switch.vue'
import FormControlContainer from 'frontend/common/ea-form-controls/form-control-container.vue'
import SeparatedDatetimepicker from 'frontend/common/ea-form-controls/separated-datetimepicker.vue'
import EaFullcalendar from 'frontend/common/ea-fullcalendar/ea-fullcalendar.vue'
import EaPagination from 'frontend/common/ea-pagination.vue'
import EaPopover from 'frontend/common/ea-popover.vue'
import EaSort from 'frontend/common/ea-sort.vue'
import EaSpinner from 'frontend/common/ea-spinner.vue'
import EditButton from 'frontend/common/edit-button.vue'
import EllipsisWithTooltip from 'frontend/common/ellipsis-with-tooltip.vue'
import Email from 'frontend/common/email.vue'
import ErebusLabel from 'frontend/common/erebus-label.vue'
import FormattedTime from 'frontend/common/formatted-time.vue'
import FullName from 'frontend/common/full-name.vue'
import HumanizedDuration from 'frontend/common/humanized-duration.vue'
import LimitedComponentsList from 'frontend/common/limited-components-list.vue'
import Modal from 'frontend/common/modal.vue'
import ModalButton from 'frontend/common/modal-button.vue'
import NewDropdown from 'frontend/common/new-dropdown.vue'
import NewEaPagination from 'frontend/common/new-ea-pagination.vue'
import PassengerDefaultButton from 'frontend/common/passenger-default-button.vue'
import PhoneNumber from 'frontend/common/phone-number.vue'
import RemoveButton from 'frontend/common/remove-button.vue'
import RemoveItemButton from 'frontend/common/remove-item-button.vue'
import RestoreButton from 'frontend/common/restore-button.vue'
import RestoreItemButton from 'frontend/common/restore-item-button.vue'
import PassengerRoundButton from 'frontend/common/round-button.vue'
import SearchCriterion from 'frontend/common/search-criterion/search-criterion.vue'
import ShowNumberOfHours from 'frontend/common/show-number-of-hours.vue'
import StringTimePresenter from 'frontend/common/string-time-presenter.vue'
import SubmitButton from 'frontend/common/submit-button.vue'
import Tab from 'frontend/common/tab.vue'
import TabsWrapper from 'frontend/common/tabs-wrapper.vue'
import Tile from 'frontend/common/tile.vue'
import TimeDisplay from 'frontend/common/time-display.vue'
import TrueFalseLabel from 'frontend/common/true-false-label.vue'
import InputWithIconComponent from 'frontend/roles/components/InputWithIconComponent.vue'
import MergedInputTitle from 'frontend/ui/components/merged-input-title.vue'
import Maska from 'maska'
import VueAxios from 'vue-axios'
import VueFlatPickr from 'vue-flatpickr-component'

import {
  $$dialogs,
  $$error,
  $$eventHub,
  $$globals,
  $$info,
  $$mainDomain,
  $$success,
  $$warning,
} from './global-injection-keys'

export function registerPlugins(app) {
  app.use(setupRouter)
  app.use(VueAxios, axios)
  app.use(store)
  app.use(VueFlatPickr)
  app.use(Maska)
  app.use(copyText)
}

export function registerDirectives(app) {
  app.directive('tooltip', TooltipDirective)
}

export function registerComponents(app) {
  app.component('EaInput', EaInput)
  app.component('MergedInputTitle', MergedInputTitle)
  app.component('InputWithIconComponent', InputWithIconComponent)
  app.component('EaChooser', EaChooser)
  app.component('EaFileInput', EaFileInput)
  app.component('EaSelect', EaSelect)
  app.component('EaNestedFormCollection', EaNestedFormCollection)
  app.component('EaNestedForm', EaNestedForm)
  app.component('EaRadioGroup', EaRadioGroup)
  app.component('EaSimpleCheckbox', EaSimpleCheckbox)
  app.component('EaCheckboxGroup', EaCheckboxGroup)
  app.component('EaCheckbox', EaCheckbox)
  app.component('EaSwitch', EaSwitch)
  app.component('EaDatetimepicker', EaDatetimepicker)
  app.component('EaDateSelector', EaDateSelector)
  app.component('SeparatedDatetimepicker', SeparatedDatetimepicker)
  app.component('FormControlContainer', FormControlContainer)
  app.component('EaSpinner', EaSpinner)
  app.component('EaPagination', EaPagination)
  app.component('NewEaPagination', NewEaPagination)
  app.component('EaFilter', EaFilter)
  app.component('SearchCriterion', SearchCriterion)
  app.component('EaSort', EaSort)
  app.component('EaFullcalendar', EaFullcalendar)
  app.component('Modal', Modal)
  app.component('Tile', Tile)
  app.component('Tab', Tab)
  app.component('TabsWrapper', TabsWrapper)
  app.component('Dropdown', Dropdown)
  app.component('NewDropdown', NewDropdown)
  app.component('EaFilterClearAll', EaFilterClearAll)
  app.component('ErebusLabel', ErebusLabel)
  app.component('FormattedTime', FormattedTime)
  app.component('StringTimePresenter', StringTimePresenter)
  app.component('HumanizedDuration', HumanizedDuration)
  app.component('FullName', FullName)
  app.component('LimitedComponentsList', LimitedComponentsList)
  app.component('ResponsiveDataTable', ResponsiveDataTable)
  app.component('DataTable', DataTable)
  app.component('DataTableTbody', DataTableTbody)
  app.component('NewDataTable', NewDataTable)
  app.component('DenormalizedItem', DenormalizedItem)
  app.component('EllipsisWithTooltip', EllipsisWithTooltip)
  app.component('DataTableTools', DataTableTools)
  app.component('DataTableFilters', DataTableFilters)
  app.component('DataTableFilterPresets', DataTableFilterPresets)
  app.component('DataTableSearchCriteriaWithPresets', DataTableSearchCriteriaWithPresets)
  app.component('DataTableUsedSearchCriteria', DataTableUsedSearchCriteria)
  app.component('DataTableSearchCriteria', DataTableSearchCriteria)
  app.component('DataTableUsedFilters', DataTableUsedFilters)
  app.component('DataTableBatchActions', DataTableBatchActions)
  app.component('DataTableHeaderCell', DataTableHeaderCell)
  app.component('DataTableFooter', DataTableFooter)
  app.component('DataTableEditButton', DataTableEditButton)
  app.component('DataTableCalendarButton', DataTableCalendarButton)
  app.component('DataTableShowButton', DataTableShowButton)
  app.component('DataTableVersionButton', DataTableVersionButton)
  app.component('DataTableActionButton', DataTableActionButton)
  app.component('DataTableRemoveButton', DataTableRemoveButton)
  app.component('DataTableNothingFound', DataTableNothingFound)
  app.component('DismissButton', DismissButton)
  app.component('SubmitButton', SubmitButton)
  app.component('AddButton', AddButton)
  app.component('PassengerRoundButton', PassengerRoundButton)
  app.component('PassengerDefaultButton', PassengerDefaultButton)
  app.component('EditButton', EditButton)
  app.component('RemoveButton', RemoveButton)
  app.component('TimeDisplay', TimeDisplay)
  app.component('ModalButton', ModalButton)
  app.component('TrueFalseLabel', TrueFalseLabel)
  app.component('Email', Email)
  app.component('PhoneNumber', PhoneNumber)
  app.component('ContextMenu', ContextMenu)
  app.component('CustomColumnsTrigger', CustomColumnsTrigger)
  app.component('RemoveItemButton', RemoveItemButton)
  app.component('EaDraggableNestedFormCollection', EaDraggableNestedFormCollection)
  app.component('DataTableArchiveButton', DataTableArchiveButton)
  app.component('DataTableRestoreButton', DataTableRestoreButton)
  app.component('ArchiveButton', ArchiveButton)
  app.component('ArchiveItemButton', ArchiveItemButton)
  app.component('RestoreButton', RestoreButton)
  app.component('RestoreItemButton', RestoreItemButton)
  app.component('ShowNumberOfHours', ShowNumberOfHours)
  app.component('EaPopover', EaPopover)
}

export function registerGlobalProperties(app) {
  app.config.globalProperties.$globals = {
    formatDate,
    scrollToElement,
    newlinesToBr,
    computeSchema,
    normalizedSubscription,
  }
  app.config.globalProperties.$eventHub = eventHub

  app.config.globalProperties.$dialogs = dialogs

  app.config.globalProperties.$success = payload => store.dispatch('toast/success', payload)
  app.config.globalProperties.$warning = payload => store.dispatch('toast/warning', payload)
  app.config.globalProperties.$info = payload => store.dispatch('toast/info', payload)
  app.config.globalProperties.$error = payload => store.dispatch('toast/error', payload)
  app.config.globalProperties.$destroyToast = toastId => store.commit('toast/destroyToast', toastId)
  app.config.globalProperties.$debug = (...content) => console.debug('DEBUG:', ...content)
  let mainDomain
  if (import.meta.env.MODE == 'development' || import.meta.env.MODE == 'test') {
    mainDomain = document?.body?.getAttribute('md') || 'localhost:3000'
  } else if (import.meta.env.MODE == 'edge') {
    mainDomain = 'erebus-edge.enginearch.com'
  } else if (import.meta.env.MODE == 'dev') {
    mainDomain = 'tmmdev.fifa.org'
  } else if (import.meta.env.MODE == 'staging') {
    mainDomain = 'tmmuat.fifa.org'
  } else if (import.meta.env.MODE == 'production') {
    mainDomain = 'tmm.fifa.org'
  }
  app.config.globalProperties.$mainDomain = mainDomain

  //TODO: remove string keys after migration to InjectionKeys (full TS support)

  app.provide('$globals', app.config.globalProperties.$globals)
  app.provide('$eventHub', app.config.globalProperties.$eventHub)
  app.provide('$dialogs', app.config.globalProperties.$dialogs)

  app.provide($$globals, app.config.globalProperties.$globals)
  app.provide($$eventHub, app.config.globalProperties.$eventHub)
  app.provide($$dialogs, app.config.globalProperties.$dialogs)

  app.provide('axios', app.config.globalProperties.axios)
  app.provide(axios, app.config.globalProperties.axios)

  app.provide('$success', app.config.globalProperties.$success)
  app.provide('$warning', app.config.globalProperties.$warning)
  app.provide('$error', app.config.globalProperties.$error)
  app.provide('$info', app.config.globalProperties.$info)

  app.provide($$success, app.config.globalProperties.$success)
  app.provide($$warning, app.config.globalProperties.$warning)
  app.provide($$error, app.config.globalProperties.$error)
  app.provide($$info, app.config.globalProperties.$info)

  app.provide('$mainDomain', app.config.globalProperties.$mainDomain)
  app.provide($$mainDomain, app.config.globalProperties.$mainDomain)

  store.axios = app.config.globalProperties.axios
  store.$globals = app.config.globalProperties.$globals
  store.$eventHub = app.config.globalProperties.$eventHub
}
