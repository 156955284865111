<script>
import { camelCase } from 'lodash'
import { FilterXSS } from 'xss'

import { uuidRegExpAsString } from '../access-helpers'
import KindData, { TIME_VARIABLES } from '../ea-filter/_ea-filter-kind-data'

export default {
  name: 'DataTableUsedFilter',
  emits: ['remove-filter'],
  props: {
    filterValue: {
      type: [Object, Array, String, Number, Boolean],
    },
    filterKey: {
      type: String,
      required: true,
    },
    filtersConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetchedHumanizedFilterValue: '[loading...]',
      sanitizer: new FilterXSS({
        whiteList: {
          i: ['class'],
        },
      }),
    }
  },
  computed: {
    escapedFilterText() {
      return this.sanitizer.process(this.filterText)
    },
    humanizedFilterValue() {
      const result = this.filterValue
      if (result.constructor == String && result.match(`^${uuidRegExpAsString}$`)) {
        if (this.filterConfig?.fetchSelectedItems) {
          this.fetchAndSetHumanizedFilterValue()
          return this.fetchedHumanizedFilterValue
        }
      }
      if (
        result.constructor == Array &&
        result.filter(el => el.match(`^${uuidRegExpAsString}$`)).length == result.length
      ) {
        if (this.filterConfig?.fetchSelectedItems) {
          this.fetchAndSetHumanizedFilterValue()
          return this.fetchedHumanizedFilterValue
        }
      }
      if (
        result.constructor == String &&
        (result.search(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/gi) == 0 ||
          result.search(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/gi) == 0)
      ) {
        if (this.filterConfig?.dayjsFormat) {
          return dayjs(result)
            .tz(this.$store.state.session.timeZone)
            .format(this.filterConfig?.dayjsFormat)
        } else {
          return dayjs(result).tz(this.$store.state.session.timeZone).format('DD/MM/YYYY HH:mm')
        }
      }
      if (result.constructor == String && Object.keys(TIME_VARIABLES).includes(result)) {
        return TIME_VARIABLES[result]
      }
      if (result.constructor == Array && this.filterConfig?.componentProps?.collection) {
        return this.filterConfig?.componentProps?.collection
          .filter(el => result.includes(el.value))
          .map(el => el.label)
          .join(', ')
      }
      if (result.constructor == String && this.filterConfig?.componentProps?.collection) {
        const potentialResult = this.filterConfig?.componentProps?.collection.find(
          el => result == el.value,
        )?.label
        if (potentialResult?.length) {
          return potentialResult
        }
      }
      if (result.constructor == Array) {
        return result.join(', ')
      }
      if (result.constructor == Boolean) {
        let trueLabel = 'YES'
        let falseLabel = 'NO'
        const options = this.filterConfig.slots?.bool?.componentProps?.options

        if (options?.length) {
          trueLabel = options.find(option => option.value === true)?.label || trueLabel
          falseLabel = options.find(option => option.value === false)?.label || falseLabel
        }
        return result ? trueLabel : falseLabel
      }
      if (result.constructor == String && ['true', 'false'].includes(result)) {
        return result == 'true' ? 'YES' : 'NO'
      }
      return result
    },
    humanizedFilterKey() {
      let result = ''
      if (this.filterConfigPath?.length) {
        this.filterConfigPath.forEach(cc => {
          result = `${result}${result.length > 0 ? '<i class="bi-arrow-right"></i>' : ''}${
            cc.label
          }`
        })
      }
      return result
    },
    humanizedFilterPredicate() {
      if (this.filterConfig) {
        const kind = this.filterConfig.kinds.find(
          kind =>
            this.filterKey == camelCase(`${this.filterConfig.attribute} ${KindData[kind].suffix}`),
        )
        if (kind?.length) {
          return KindData[kind].humanized || KindData[kind].name || '??'
        }
      }
      return '??'
    },
    filterConfig() {
      if (this.filterConfigPath?.length) {
        return this.filterConfigPath[this.filterConfigPath.length - 1]
      } else {
        return null
      }
    },
    filterConfigPath() {
      return this.findFilterConfigPath(this.filtersConfig)
    },
    filterText() {
      return `${this.humanizedFilterKey} ${this.humanizedFilterPredicate} ${this.humanizedFilterValue}`
    },
  },
  methods: {
    fetchAndSetHumanizedFilterValue() {
      this.filterConfig?.fetchSelectedItems(this.filterValue).then(result => {
        this.fetchedHumanizedFilterValue = result
      })
    },
    findFilterConfigPath(items) {
      const item = items.find(
        config =>
          config.attribute?.length &&
          config.kinds?.length &&
          !!config.kinds.find(
            kind => this.filterKey == camelCase(`${config.attribute} ${KindData[kind].suffix}`),
          ),
      )
      if (item) {
        return [item]
      }
      let result = null
      items.forEach(item => {
        if (result) {
          return result
        }
        if (item?.children) {
          const subResult = this.findFilterConfigPath(item.children)
          if (subResult) {
            result = [item, ...subResult]
          }
        }
      })
      return result
    },
  },
}
</script>

<template lang="pug">
.used-filter.align-items-center.d-inline-flex.justify-content-start(
  v-tooltip.options="{ title: filterText, placement: 'bottom', html: true }"
)
  .filter-content(v-html="escapedFilterText")
  i.fa.fa-times.ms-1(@click="$emit('remove-filter')")
</template>
