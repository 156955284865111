import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import {
  COLUMNS_CONFIG,
  DEFAULT_COLUMNS,
  FIELDS,
  SUBSCRIPTIONS,
} from 'frontend/shift-jobs/shift-jobs-table-helpers'

export const ShiftJobDataModelTypeConfig: IDataModelTypeConfig = {
  fields: FIELDS,
  subscriptions: SUBSCRIPTIONS,
  defaultColumns: DEFAULT_COLUMNS,
  columnConfig: COLUMNS_CONFIG,
}
