<script setup lang="ts">
import { pickTextColorBasedOnBgColor } from 'frontend/ui/functions/pickTextColorBasedOnBgColor'
import type { ILabel } from 'frontend/ui/types/i-label'
import { computed } from 'vue'

interface Props {
  item: ILabel
  additionalClass?: string
}
const props = withDefaults(defineProps<Props>(), {
  item: null,
  additionalClass: '',
})

const fontColor = computed(() => {
  if (props.item.fontColor) {
    return props.item.fontColor
  } else if (props.item.backgroundColor) {
    return pickTextColorBasedOnBgColor(props.item.backgroundColor)
  } else {
    return '#333'
  }
})

const badgeStyle = computed(() => {
  if (!props.item) return {}
  return {
    color: fontColor.value,
    backgroundColor: props.item.backgroundColor,
    borderColor: props.item.borderColor || props.item.backgroundColor,
    borderStyle: 'solid',
  }
})
</script>

<template lang="pug">
span.erebus-label(
  :class="{ 'line-through': !!item?.archivedAt }"
  v-tooltip="!!item?.archivedAt ? 'Is archived' : item?.description?.length ? item.description : null"
)
  span.badge.border-1(v-if="item" :class="additionalClass" :id="item.id" :style="badgeStyle") {{ item.name }}
    slot
  span.text-black-15(v-else) empty
</template>

<style lang="scss" scoped>
.erebus-label {
  height: 100%;
  display: flex;
  align-items: center;
}
.badge {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}
</style>
