export const ColumnPresetFields = [
  'id',
  'name',
  'data_model_type',
  'enabled_column_types',
  'default_sort_column_type',
  'default_sort_direction',
  'position',
  'column_widths',
  'is_global',
]
