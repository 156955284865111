<script setup lang="ts">
import ToggleComponent from 'frontend/ui/components/ToggleComponent.vue'
import { computed } from 'vue'

interface Props {
  isMultiSortEnabled?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  isMultiSortEnabled: false,
})

interface Emits {
  (e: 'update:isMultiSortEnabled', value: boolean): void
}
const emit = defineEmits<Emits>()

const isMultiSortEnabledModel = computed<boolean>({
  get() {
    return props.isMultiSortEnabled
  },
  set(value: boolean) {
    emit('update:isMultiSortEnabled', value)
  },
})
</script>

<template>
  <div class="table-multi-sort-toggle">
    <div class="name">Multi sort</div>
    <ToggleComponent v-model:isChecked="isMultiSortEnabledModel" />
  </div>
</template>

<style scoped lang="scss">
.table-multi-sort-toggle {
  display: flex;
  gap: 8px;
  margin-right: 16px;
  align-items: center;
}

.name {
  //font-weight: 600;
  //text-transform: uppercase;
  color: hsl(0deg, 0%, 15%);
  font-size: 15px;
  white-space: nowrap;
}
</style>
