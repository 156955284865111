import { defineAsyncComponent } from 'vue'

// const NotificationTemplate = defineAsyncComponent(() => import('./notification-template.vue'))
// const NotificationTemplateFormButton = defineAsyncComponent(() => import('./notification-template-form-button.vue'))
const NotificationTemplateFormModal = defineAsyncComponent(
  () => import('./notification-template-form-modal.vue'),
)
// const NotificationTemplatesSelect = defineAsyncComponent(() => import('./notification-templates-select.vue'))
const NotificationTemplatesTable = defineAsyncComponent(
  () => import('./notification-templates-table.vue'),
)

const KINDS = {
  driver_sign_out: 'Driver signed out',
  shift_acceptance_request: 'New acceptance request',
  shift_assigned: 'New shift assigned',
  shift_changed: 'Shift changed',
  shift_removed: 'Shift removed',
  break_scheduled: 'New break scheduled',
  custom_job_assigned: 'New custom job assigned',
  location_backup_assigned: 'New location backup assigned',
  transfer_group_assigned: 'New transfer group assigned',
  shuttle_transfer_assigned: 'New shuttle transfer assigned',
  break_updated: 'Break updated',
  custom_job_updated: 'Custom job updated',
  location_backup_updated: 'Location backup updated',
  transfer_group_updated: 'Transfer group updated',
  shuttle_transfer_updated: 'Shuttle transfer updated',
  break_unassigned: 'Break removed',
  custom_job_unassigned: 'Custom job unassigned',
  location_backup_unassigned: 'Location backup unassigned',
  transfer_group_unassigned: 'Transfer group unassigned',
  shuttle_transfer_unassigned: 'Shuttle transfer unassigned',
}

export {
  KINDS,
  // NotificationTemplate,
  // NotificationTemplateFormButton,
  NotificationTemplateFormModal,
  // NotificationTemplatesSelect,
  NotificationTemplatesTable,
}
