<script>
export default {
  name: 'DataTableRestoreButton',
  emits: ['click'],
  props: {
    tooltip: { type: String, default: 'Restore' },
    iconSizeClass: { type: String, default: null },
  },
}
</script>

<template lang="pug">
.d-inline-flex.position-relative
  button.btn.btn-link.with-icon.py-0.px-1.ms-2(
    @click="$emit('click', $event)"
    type="button"
    v-tooltip.options="{ title: tooltip, placement: 'left' }"
  )
    i.fas.fa-recycle.text-warning(:class="iconSizeClass")
</template>
