import { ASLDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ASLDataModelTypeStrategy/ASLDataModelTypeStrategy'
import { ASLDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ASLDataModelTypeStrategy/constants/ASLDataModelTypeConfig'
import { BatchOnsiteRequestDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/BatchOnsiteRequestDataModelTypeStrategy/BatchOnsiteRequestDataModelTypeStrategy'
import { BatchOnsiteRequestDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/BatchOnsiteRequestDataModelTypeStrategy/constants/BatchOnsiteRequestDataModelTypeConfig'
import { ClientCategoryDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientCategoryDataModelTypeStrategy/ClientCategoryDataModelTypeStrategy'
import { ClientCategoryDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientCategoryDataModelTypeStrategy/constants/ClientCategoryDataModelTypeConfig'
import { ClientCommunicationMessageDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientCommunicationMessageDataModelTypeStrategy/ClientCommunicationMessageDataModelTypeStrategy'
import { ClientCommunicationMessageDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientCommunicationMessageDataModelTypeStrategy/constants/ClientCommunicationMessageDataModelTypeConfig'
import { ClientCommunicationRuleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientCommunicationRuleDataModelTypeStrategy/ClientCommunicationRuleDataModelTypeStrategy'
import { ClientCommunicationRuleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientCommunicationRuleDataModelTypeStrategy/constants/ClientCommunicationRuleDataModelTypeConfig'
import { ClientDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientDataModelTypeStrategy/ClientDataModelTypeStrategy'
import { ClientDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientDataModelTypeStrategy/constants/ClientDataModelTypeConfig'
import { ClientFunctionDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientFunctionDataModelTypeStrategy/ClientFunctionDataModelTypeStrategy'
import { ClientFunctionDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientFunctionDataModelTypeStrategy/constants/ClientFunctionDataModelTypeConfig'
import { ClientFunctionRemarkDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientFunctionRemarkDataModelTypeStrategy/ClientFunctionRemarkDataModelTypeStrategy'
import { ClientFunctionRemarkDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientFunctionRemarkDataModelTypeStrategy/constants/ClientFunctionRemarkDataModelTypeConfig'
import { ClientGroupDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientGroupDataModelTypeStrategy/ClientGroupDataModelTypeStrategy'
import { ClientGroupDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientGroupDataModelTypeStrategy/constants/ClientGroupDataModelTypeConfig'
import { ClientImportDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientImportDataModelTypeStrategy/ClientImportDataModelTypeStrategy'
import { ClientImportDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientImportDataModelTypeStrategy/constants/ClientImportDataModelTypeConfig'
import { ClientNonCompanionDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientNonCompanionDataModelTypeStrategy/ClientNonCompanionDataModelTypeStrategy'
import { ClientNonCompanionDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClientNonCompanionDataModelTypeStrategy/constants/ClientNonCompanionDataModelTypeConfig'
import { ClusterCalendarEntryDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClusterCalendarEntryDataModelTypeStrategy/ClusterCalendarEntryDataModelTypeStrategy'
import { ClusterCalendarEntryDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClusterCalendarEntryDataModelTypeStrategy/constants/ClusterCalendarEntryDataModelTypeConfig'
import { ClusterDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClusterDataModelTypeStrategy/ClusterDataModelTypeStrategy'
import { ClusterDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ClusterDataModelTypeStrategy/constants/ClusterDataModelTypeConfig'
import { ColumnPresetDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ColumnPresetDataModelTypeStrategy/ColumnPresetDataModelTypeStrategy'
import { ColumnPresetDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ColumnPresetDataModelTypeStrategy/constants/ColumnPresetDataModelTypeConfig'
import { DayLabelDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DayLabelDataModelTypeStrategy/constants/DayLabelDataModelTypeConfig'
import { DayLabelDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DayLabelDataModelTypeStrategy/DayLabelDataModelTypeStrategy'
import { DepotDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DepotDataModelTypeStrategy/constants/DepotDataModelTypeConfig'
import { DepotDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DepotDataModelTypeStrategy/DepotDataModelTypeStrategy'
import { DriverCalendarEntryDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverCalendarEntryDataModelTypeStrategy/constants/DriverCalendarEntryDataModelTypeConfig'
import { DriverCalendarEntryDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverCalendarEntryDataModelTypeStrategy/DriverCalendarEntryDataModelTypeStrategy'
import { DriverCategoryDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverCategoryDataModelTypeStrategy/constants/DriverCategoryDataModelTypeConfig'
import { DriverCategoryDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverCategoryDataModelTypeStrategy/DriverCategoryDataModelTypeStrategy'
import { DriverDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverDataModelTypeStrategy/constants/DriverDataModelTypeConfig'
import { DriverDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverDataModelTypeStrategy/DriverDataModelTypeStrategy'
import { DriverImportDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverImportDataModelTypeStrategy/constants/DriverImportDataModelTypeConfig'
import { DriverImportDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverImportDataModelTypeStrategy/DriverImportDataModelTypeStrategy'
import { DriverRegistrationQrTokenDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverRegistrationQrTokenDataModelTypeStrategy/constants/DriverRegistrationQrTokenDataModelTypeConfig'
import { DriverRegistrationQrTokenDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverRegistrationQrTokenDataModelTypeStrategy/DriverRegistrationQrTokenDataModelTypeStrategy'
import { DriverRegistrationRequestDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverRegistrationRequestDataModelTypeStrategy/constants/DriverRegistrationRequestDataModelTypeConfig'
import { DriverRegistrationRequestDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverRegistrationRequestDataModelTypeStrategy/DriverRegistrationRequestDataModelTypeStrategy'
import { DriverSourceDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverSourceDataModelTypeStrategy/constants/DriverSourceDataModelTypeConfig'
import { DriverSourceDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DriverSourceDataModelTypeStrategy/DriverSourceDataModelTypeStrategy'
import { DurationRuleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DurationRuleDataModelTypeStrategy/constants/DurationRuleDataModelTypeConfig'
import { DurationRuleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/DurationRuleDataModelTypeStrategy/DurationRuleDataModelTypeStrategy'
import { EmailTemplateDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/EmailTemplateDataModelTypeStrategy/constants/EmailTemplateDataModelTypeConfig'
import { EmailTemplateDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/EmailTemplateDataModelTypeStrategy/EmailTemplateDataModelTypeStrategy'
import { EventDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/EventDataModelTypeStrategy/constants/EventDataModelTypeConfig'
import { EventDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/EventDataModelTypeStrategy/EventDataModelTypeStrategy'
import { EventOrganizationUserDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/EventOrganizationUserDataModelTypeStrategy/constants/EventOrganizationUserDataModelTypeConfig'
import { EventOrganizationUserDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/EventOrganizationUserDataModelTypeStrategy/EventOrganizationUserDataModelTypeStrategy'
import { EventOrganizationUserImportDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/EventOrganizationUserImportDataModelTypeStrategy/constants/EventOrganizationUserImportDataModelTypeConfig'
import { EventOrganizationUserImportDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/EventOrganizationUserImportDataModelTypeStrategy/EventOrganizationUserImportDataModelTypeStrategy'
import { GisRuleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/GisRuleDataModelTypeStrategy/constants/GisRuleDataModelTypeConfig'
import { GisRuleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/GisRuleDataModelTypeStrategy/GisRuleDataModelTypeStrategy'
import { LocationDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/LocationDataModelTypeStrategy/constants/LocationDataModelTypeConfig'
import { LocationDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/LocationDataModelTypeStrategy/LocationDataModelTypeStrategy'
import { LocationTypeDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/LocationTypeDataModelTypeStrategy/constants/LocationTypeDataModelTypeConfig'
import { LocationTypeDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/LocationTypeDataModelTypeStrategy/LocationTypeDataModelTypeStrategy'
import { MapProviderDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/MapProviderDataModelTypeStrategy/constants/MapProviderDataModelTypeConfig'
import { MapProviderDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/MapProviderDataModelTypeStrategy/MapProviderDataModelTypeStrategy'
import { NotificationTemplateDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/NotificationTemplateDataModelTypeStrategy/constants/NotificationTemplateDataModelTypeConfig'
import { NotificationTemplateDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/NotificationTemplateDataModelTypeStrategy/NotificationTemplateDataModelTypeStrategy'
import { OperationalServiceLevelDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/OperationalServiceLevelDataModelTypeStrategy/constants/OperationalServiceLevelDataModelTypeConfig'
import { OperationalServiceLevelDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/OperationalServiceLevelDataModelTypeStrategy/OperationalServiceLevelDataModelTypeStrategy'
import { OSLDRuleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/OSLDRuleDataModelTypeStrategy/constants/OSLDRuleDataModelTypeConfig'
import { OSLDRuleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/OSLDRuleDataModelTypeStrategy/OSLDRuleDataModelTypeStrategy'
import { OSLTBRuleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/OSLTBRuleDataModelTypeStrategy/constants/OSLTBRuleDataModelTypeConfig'
import { OSLTBRuleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/OSLTBRuleDataModelTypeStrategy/OSLTBRuleDataModelTypeStrategy'
import { OSLVRuleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/OSLVRuleDataModelTypeStrategy/constants/OSLVRuleDataModelTypeConfig'
import { OSLVRuleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/OSLVRuleDataModelTypeStrategy/OSLVRuleDataModelTypeStrategy'
import { PredefinedCriteriaScopeDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/PredefinedCriteriaScopeDataModelTypeStrategy/constants/PredefinedCriteriaScopeDataModelTypeConfig'
import { PredefinedCriteriaScopeDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/PredefinedCriteriaScopeDataModelTypeStrategy/PredefinedCriteriaScopeDataModelTypeStrategy'
import { ReportDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ReportDataModelTypeStrategy/constants/ReportDataModelTypeConfig'
import { ReportDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ReportDataModelTypeStrategy/ReportDataModelTypeStrategy'
import { RoleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/RoleDataModelTypeStrategy/constants/RoleDataModelTypeConfig'
import { RoleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/RoleDataModelTypeStrategy/RoleDataModelTypeStrategy'
import { ShiftDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShiftDataModelTypeStrategy/constants/ShiftDataModelTypeConfig'
import { ShiftDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShiftDataModelTypeStrategy/ShiftDataModelTypeStrategy'
import { ShiftJobDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShiftJobDataModelTypeStrategy/constants/ShiftJobDataModelTypeConfig'
import { ShiftJobDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShiftJobDataModelTypeStrategy/ShiftJobDataModelTypeStrategy'
import { ShuttleServiceDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShuttleServiceDataModelTypeStrategy/constants/ShuttleServiceDataModelTypeConfig'
import { ShuttleServiceDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShuttleServiceDataModelTypeStrategy/ShuttleServiceDataModelTypeStrategy'
import { ShuttleServiceImportDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShuttleServiceImportDataModelTypeStrategy/constants/ShuttleServiceImportDataModelTypeConfig'
import { ShuttleServiceImportDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShuttleServiceImportDataModelTypeStrategy/ShuttleServiceImportDataModelTypeStrategy'
import { ShuttleTransferDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShuttleTransferDataModelTypeStrategy/constants/ShuttleTransferDataModelTypeConfig'
import { ShuttleTransferDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/ShuttleTransferDataModelTypeStrategy/ShuttleTransferDataModelTypeStrategy'
import { StagingAreaCalendarEntryDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/StagingAreaCalendarEntryDataModelTypeStrategy/constants/StagingAreaCalendarEntryDataModelTypeConfig'
import { StagingAreaCalendarEntryDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/StagingAreaCalendarEntryDataModelTypeStrategy/StagingAreaCalendarEntryDataModelTypeStrategy'
import { StagingAreaDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/StagingAreaDataModelTypeStrategy/constants/StagingAreaDataModelTypeConfig'
import { StagingAreaDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/StagingAreaDataModelTypeStrategy/StagingAreaDataModelTypeStrategy'
import { SystemNotificationDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/SystemNotificationDataModelTypeStrategy/constants/SystemNotificationDataModelTypeConfig'
import { SystemNotificationDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/SystemNotificationDataModelTypeStrategy/SystemNotificationDataModelTypeStrategy'
import { TBVersionDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TBVersionDataModelTypeStrategy/constants/TBVersionDataModelTypeConfig'
import { TBVersionDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TBVersionDataModelTypeStrategy/TBVersionDataModelTypeStrategy'
import { TrackerDeviceDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TrackerDeviceDataModelTypeStrategy/constants/TrackerDeviceDataModelTypeConfig'
import { TrackerDeviceDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TrackerDeviceDataModelTypeStrategy/TrackerDeviceDataModelTypeStrategy'
import { TrackingServiceDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TrackingServiceDataModelTypeStrategy/constants/TrackingServiceDataModelTypeConfig'
import { TrackingServiceDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TrackingServiceDataModelTypeStrategy/TrackingServiceDataModelTypeStrategy'
import { TransportationPointDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportationPointDataModelTypeStrategy/constants/TransportationPointDataModelTypeConfig'
import { TransportationPointDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportationPointDataModelTypeStrategy/TransportationPointDataModelTypeStrategy'
import { TransportationPointImportDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportationPointImportDataModelTypeStrategy/constants/TransportationPointImportDataModelTypeConfig'
import { TransportationPointImportDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportationPointImportDataModelTypeStrategy/TransportationPointImportDataModelTypeStrategy'
import { TransportBookingDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportBookingDataModelTypeStrategy/constants/TransportBookingDataModelTypeConfig'
import { TransportBookingDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportBookingDataModelTypeStrategy/TransportBookingDataModelTypeStrategy'
import { TransportBookingGroupingRuleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportBookingGroupingRuleDataModelTypeStrategy/constants/TransportBookingGroupingRuleDataModelTypeConfig'
import { TransportBookingGroupingRuleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportBookingGroupingRuleDataModelTypeStrategy/TransportBookingGroupingRuleDataModelTypeStrategy'
import { TransportBookingImportDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportBookingImportDataModelTypeStrategy/constants/TransportBookingImportDataModelTypeConfig'
import { TransportBookingImportDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportBookingImportDataModelTypeStrategy/TransportBookingImportDataModelTypeStrategy'
import { TransportBufferDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportBufferDataModelTypeStrategy/constants/TransportBufferDataModelTypeConfig'
import { TransportBufferDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TransportBufferDataModelTypeStrategy/TransportBufferDataModelTypeStrategy'
import { TSLDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TSLDataModelTypeStrategy/constants/TSLDataModelTypeConfig'
import { TSLDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/TSLDataModelTypeStrategy/TSLDataModelTypeStrategy'
import { UserDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/UserDataModelTypeStrategy/constants/UserDataModelTypeConfig'
import { UserDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/UserDataModelTypeStrategy/UserDataModelTypeStrategy'
import { VariableDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VariableDataModelTypeStrategy/constants/VariableDataModelTypeConfig'
import { VariableDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VariableDataModelTypeStrategy/VariableDataModelTypeStrategy'
import { VehicleBrandDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleBrandDataModelTypeStrategy/constants/VehicleBrandDataModelTypeConfig'
import { VehicleBrandDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleBrandDataModelTypeStrategy/VehicleBrandDataModelTypeStrategy'
import { VehicleCategoryDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleCategoryDataModelTypeStrategy/constants/VehicleCategoryDataModelTypeConfig'
import { VehicleCategoryDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleCategoryDataModelTypeStrategy/VehicleCategoryDataModelTypeStrategy'
import { VehicleDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleDataModelTypeStrategy/constants/VehicleDataModelTypeConfig'
import { VehicleDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleDataModelTypeStrategy/VehicleDataModelTypeStrategy'
import { VehicleImportDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleImportDataModelTypeStrategy/constants/VehicleImportDataModelTypeConfig'
import { VehicleImportDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleImportDataModelTypeStrategy/VehicleImportDataModelTypeStrategy'
import { VehicleModelDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleModelDataModelTypeStrategy/constants/VehicleModelDataModelTypeConfig'
import { VehicleModelDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleModelDataModelTypeStrategy/VehicleModelDataModelTypeStrategy'
import { VehicleSourceDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleSourceDataModelTypeStrategy/constants/VehicleSourceDataModelTypeConfig'
import { VehicleSourceDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleSourceDataModelTypeStrategy/VehicleSourceDataModelTypeStrategy'
import { VehicleSourcePricingDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleSourcePricingDataModelTypeStrategy/constants/VehicleSourcePricingDataModelTypeConfig'
import { VehicleSourcePricingDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleSourcePricingDataModelTypeStrategy/VehicleSourcePricingDataModelTypeStrategy'
import { VehicleTypeDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleTypeDataModelTypeStrategy/constants/VehicleTypeDataModelTypeConfig'
import { VehicleTypeDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VehicleTypeDataModelTypeStrategy/VehicleTypeDataModelTypeStrategy'
import { VersionDataModelTypeConfig } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VersionDataModelTypeStrategy/constants/VersionDataModelTypeConfig'
import { VersionDataModelTypeStrategy } from 'frontend/dataModels/strategies/DataModelTypeStrategies/VersionDataModelTypeStrategy/VersionDataModelTypeStrategy'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { IDataModelTypeStrategy } from 'frontend/dataModels/types/IDataModelTypeStrategy'
import { DataModelType } from 'frontend/roles/enum/DataModelType'
import { Container } from 'inversify'

import { InterfaceType } from '../constants/InterfaceType'

export function bindStrategies(container: Container): void {
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ASLDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ASL)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(EventDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Event)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientFunctionDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClientFunction)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientFunctionRemarkDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClientFunctionRemark)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DayLabelDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.DayLabel)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DriverCategoryDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.DriverCategory)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(LocationTypeDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.LocationType)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(OperationalServiceLevelDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.OperationalServiceLevel)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VehicleBrandDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.VehicleBrand)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VehicleCategoryDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.VehicleCategory)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VehicleModelDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.VehicleModel)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VehicleTypeDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.VehicleType)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TSLDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TSL)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientCategoryDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClientCategory)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(MapProviderDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.MapProvider)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(SystemNotificationDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.SystemNotification)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClusterCalendarEntryDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClusterCalendarEntry)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(NotificationTemplateDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.NotificationTemplate)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DriverCalendarEntryDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.DriverCalendarEntry)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(StagingAreaCalendarEntryDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.StagingAreaCalendarEntry)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ShiftDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Shift)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ShiftJobDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ShiftJob)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientNonCompanionDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClientNonCompanion)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Client)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientGroupDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClientGroup)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(EmailTemplateDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.EmailTemplate)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ShuttleTransferDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ShuttleTransfer)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ShuttleServiceDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ShuttleService)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VehicleSourcePricingDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.VehicleSourcePricing)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VehicleSourceDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.VehicleSource)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VehicleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Vehicle)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TrackerDeviceDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TrackerDevice)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TrackingServiceDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TrackingService)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DriverDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Driver)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DriverSourceDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.DriverSource)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientCommunicationRuleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClientCommunicationRule)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientCommunicationMessageDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClientCommunicationMessage)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(OSLTBRuleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.OSLTBRule)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(OSLVRuleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.OSLVRule)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(OSLDRuleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.OSLDRule)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DurationRuleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.DurationRule)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(LocationDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Location)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TransportationPointDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TransportationPoint)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TransportBufferDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TransportBuffer)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(StagingAreaDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.StagingArea)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClusterDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Cluster)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DepotDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Depot)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TransportBookingDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TransportBooking)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(BatchOnsiteRequestDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.BatchOnsiteRequest)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TransportBookingGroupingRuleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TransportBookingGroupingRule)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(RoleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Role)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ClientImportDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ClientImport)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(UserDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.User)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(EventOrganizationUserDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.EventOrganizationUser)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(EventOrganizationUserImportDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.EventOrganizationUserImport)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ColumnPresetDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ColumnPreset)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(PredefinedCriteriaScopeDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.PredefinedCriteriaScope)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DriverImportDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.DriverImport)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DriverRegistrationRequestDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.DriverRegistrationRequest)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VersionDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Version)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TBVersionDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TBVersion)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(DriverRegistrationQrTokenDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.DriverRegistrationQrToken)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ShuttleServiceImportDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.ShuttleServiceImport)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TransportationPointImportDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TransportationPointImport)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(TransportBookingImportDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.TransportBookingImport)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VehicleImportDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.VehicleImport)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(ReportDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Report)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(VariableDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.Variable)
  container
    .bind<IDataModelTypeStrategy>(InterfaceType.strategies.DataModelTypeStrategy)
    .to(GisRuleDataModelTypeStrategy)
    .whenTargetNamed(DataModelType.GisRule)

  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ASLDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ASL)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(EventDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Event)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientFunctionDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClientFunction)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientFunctionRemarkDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClientFunctionRemark)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DayLabelDataModelTypeConfig)
    .whenTargetNamed(DataModelType.DayLabel)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DriverCategoryDataModelTypeConfig)
    .whenTargetNamed(DataModelType.DriverCategory)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(LocationTypeDataModelTypeConfig)
    .whenTargetNamed(DataModelType.LocationType)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(OperationalServiceLevelDataModelTypeConfig)
    .whenTargetNamed(DataModelType.OperationalServiceLevel)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VehicleBrandDataModelTypeConfig)
    .whenTargetNamed(DataModelType.VehicleBrand)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VehicleCategoryDataModelTypeConfig)
    .whenTargetNamed(DataModelType.VehicleCategory)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VehicleModelDataModelTypeConfig)
    .whenTargetNamed(DataModelType.VehicleModel)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VehicleTypeDataModelTypeConfig)
    .whenTargetNamed(DataModelType.VehicleType)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TSLDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TSL)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientCategoryDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClientCategory)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(MapProviderDataModelTypeConfig)
    .whenTargetNamed(DataModelType.MapProvider)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(SystemNotificationDataModelTypeConfig)
    .whenTargetNamed(DataModelType.SystemNotification)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClusterCalendarEntryDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClusterCalendarEntry)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(NotificationTemplateDataModelTypeConfig)
    .whenTargetNamed(DataModelType.NotificationTemplate)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DriverCalendarEntryDataModelTypeConfig)
    .whenTargetNamed(DataModelType.DriverCalendarEntry)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(StagingAreaCalendarEntryDataModelTypeConfig)
    .whenTargetNamed(DataModelType.StagingAreaCalendarEntry)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ShiftDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Shift)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ShiftJobDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ShiftJob)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientNonCompanionDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClientNonCompanion)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Client)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientGroupDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClientGroup)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(EmailTemplateDataModelTypeConfig)
    .whenTargetNamed(DataModelType.EmailTemplate)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ShuttleTransferDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ShuttleTransfer)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ShuttleServiceDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ShuttleService)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VehicleSourcePricingDataModelTypeConfig)
    .whenTargetNamed(DataModelType.VehicleSourcePricing)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VehicleSourceDataModelTypeConfig)
    .whenTargetNamed(DataModelType.VehicleSource)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VehicleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Vehicle)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TrackerDeviceDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TrackerDevice)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TrackingServiceDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TrackingService)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DriverDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Driver)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DriverSourceDataModelTypeConfig)
    .whenTargetNamed(DataModelType.DriverSource)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientCommunicationRuleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClientCommunicationRule)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientCommunicationMessageDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClientCommunicationMessage)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(OSLTBRuleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.OSLTBRule)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(OSLVRuleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.OSLVRule)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(OSLDRuleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.OSLDRule)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DurationRuleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.DurationRule)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(LocationDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Location)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TransportationPointDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TransportationPoint)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(StagingAreaDataModelTypeConfig)
    .whenTargetNamed(DataModelType.StagingArea)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClusterDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Cluster)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DepotDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Depot)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TransportBookingDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TransportBooking)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TransportBookingImportDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TransportBookingImport)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(BatchOnsiteRequestDataModelTypeConfig)
    .whenTargetNamed(DataModelType.BatchOnsiteRequest)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TransportBookingGroupingRuleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TransportBookingGroupingRule)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(RoleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Role)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ClientImportDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ClientImport)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(UserDataModelTypeConfig)
    .whenTargetNamed(DataModelType.User)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(EventOrganizationUserDataModelTypeConfig)
    .whenTargetNamed(DataModelType.EventOrganizationUser)

  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(EventOrganizationUserImportDataModelTypeConfig)
    .whenTargetNamed(DataModelType.EventOrganizationUserImport)

  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ColumnPresetDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ColumnPreset)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(PredefinedCriteriaScopeDataModelTypeConfig)
    .whenTargetNamed(DataModelType.PredefinedCriteriaScope)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DriverImportDataModelTypeConfig)
    .whenTargetNamed(DataModelType.DriverImport)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DriverRegistrationRequestDataModelTypeConfig)
    .whenTargetNamed(DataModelType.DriverRegistrationRequest)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VersionDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Version)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TBVersionDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TBVersion)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(DriverRegistrationQrTokenDataModelTypeConfig)
    .whenTargetNamed(DataModelType.DriverRegistrationQrToken)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ShuttleServiceImportDataModelTypeConfig)
    .whenTargetNamed(DataModelType.ShuttleServiceImport)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TransportationPointImportDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TransportationPointImport)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VehicleImportDataModelTypeConfig)
    .whenTargetNamed(DataModelType.VehicleImport)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(ReportDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Report)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(VariableDataModelTypeConfig)
    .whenTargetNamed(DataModelType.Variable)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(TransportBufferDataModelTypeConfig)
    .whenTargetNamed(DataModelType.TransportBuffer)
  container
    .bind<IDataModelTypeConfig>(InterfaceType.strategies.DataModelTypeConfigStrategy)
    .toConstantValue(GisRuleDataModelTypeConfig)
    .whenTargetNamed(DataModelType.GisRule)
}
