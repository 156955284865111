<script lang="ts">
import { appModule } from 'frontend/_config/modules'
import eventHub from 'frontend/_globals/event-hub'
import routerHooks from 'frontend/_setup/setupRouter/setupRouterHooks'
import { PageLayoutClassNameType } from 'frontend/_setup/types/PageLayoutClassNameType'
import ModalNavigation from 'frontend/common/modal-navigation.vue'
import LayoutPassengerControls from 'passenger_portal/layout/layout-passenger-controls.vue'
import { onBeforeUnmount, provide, ref } from 'vue'
import { mapGetters } from 'vuex'

import { calcRGB } from './helpers'
import Dialogs from './layout/dialogs/dialogs.vue'
import MainFooter from './layout/layout-main-footer.vue'
import MainHeader from './layout/layout-main-header.vue'
import MainSidebar from './layout/layout-main-sidebar.vue'
import ModalsRoot from './layout/modals-root.vue'
import ToastsRoot from './layout/toasts/toasts-root.vue'
import ServiceWorkerHandler from './service-worker/service-worker-handler.vue'

export default {
  name: 'Erebus',
  mixins: [routerHooks],
  provide: {
    isModalFullScreen: false,
    inModal: false,
  },
  data() {
    return {
      currentEnv: null,
      isMenuToggled: false,
      hasScrolledToBottom: false,
    }
  },
  setup() {
    const footerTeleportNode = ref(null)
    const realFooterTeleportNode = document.getElementById('#footer-teleport-node')
    if (realFooterTeleportNode) {
      footerTeleportNode.value = realFooterTeleportNode
    }
    const setFooterTeleportNode = newVal => {
      footerTeleportNode.value = newVal
    }

    const hiddenAttrName = ref(null)
    const visibilityChangeEventName = ref(null)
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hiddenAttrName.value = 'hidden'
      visibilityChangeEventName.value = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      hiddenAttrName.value = 'msHidden'
      visibilityChangeEventName.value = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      hiddenAttrName.value = 'webkitHidden'
      visibilityChangeEventName.value = 'webkitvisibilitychange'
    }

    provide('footerTeleportNode', footerTeleportNode)

    eventHub.$on('footer-teleport-node', setFooterTeleportNode)
    onBeforeUnmount(() => {
      eventHub.$off('footer-teleport-node', setFooterTeleportNode)
    })
    return {
      hiddenAttrName,
      visibilityChangeEventName,
      PageLayoutClassNameType,
    }
  },
  computed: {
    ...mapGetters('session', ['currentUser', 'currentlyFetching']),
    currentOrganization() {
      if (!this.currentUser?.currentOrganizationId) {
        return null
      } else {
        return (this.currentUser?.organizations || []).find(
          o => o.id == this.currentUser.currentOrganizationId,
        )
      }
    },
    customColors() {
      if (
        this.currentOrganization &&
        ((this.currentOrganization.customDomain &&
          this.currentOrganization.customDomain != window.location.host) ||
          (!this.currentOrganization.customDomain && window.location.host != this.$mainDomain))
      ) {
        return {}
      }
      const primaryColor = this.currentOrganization?.customPrimaryColor ?? '#326295'
      const { rgb: primary, rgbValues: primaryValues } = calcRGB({ rgbstr: primaryColor })
      const { rgb: lighter, rgbValues: lighterValues } = calcRGB({
        rgbstr: primaryColor,
        colorChange: 0.25,
      })
      const { rgb: darker, rgbValues: darkerValues } = calcRGB({
        rgbstr: primaryColor,
        colorChange: -0.15,
      })
      return {
        '--custom-primary': primary,
        '--custom-primary-lighter': lighter,
        '--custom-primary-darker': darker,
        '--custom-primary-rgb': primaryValues,
        '--custom-primary-lighter-rgb': lighterValues,
        '--custom-primary-darker-rgb': darkerValues,
      }
    },
    isOperationalModule() {
      return this.currentUser && this.$route.meta.module === appModule.OPERATIONAL
    },
    isPassengerPortalModule() {
      return this.currentUser && this.$route.meta.module === appModule.PASSENGER
    },
    isErebusHubRoute() {
      return this.currentUser && this.$route.name === 'tmm-hub'
    },
  },
  watch: {
    currentOrganization: {
      handler: function () {
        if (
          this.currentOrganization &&
          ((this.currentOrganization.customDomain &&
            this.currentOrganization.customDomain != window.location.host) ||
            (!this.currentOrganization.customDomain && window.location.host != this.$mainDomain))
        ) {
          this.$router.push('/session/choose-context')
        }
      },
      immediate: true,
    },
    customColors: {
      handler: function () {
        Object.keys(this.customColors || {}).forEach(key => {
          document.body.style.setProperty(key, this.customColors[key])
        })
      },
      immediate: true,
    },
  },
  mounted() {
    this.currentEnv = import.meta.env.MODE
    console.log(`%c CURRENT ENVIRONMENT ${this.currentEnv}`, 'color: green')
    window.addEventListener('keydown', this.keydown, true)
    window.addEventListener('resize', this.resize)
    window.addEventListener('mousedown', this.mousedown)
    window.addEventListener('mouseup', this.mouseup)
    if (this.visibilityChangeEventName) {
      document.addEventListener(this.visibilityChangeEventName, this.visibilityChanged, false)
    }
  },
  unmounted() {
    window.removeEventListener('keydown', this.keydown, true)
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('mousedown', this.mousedown)
    window.removeEventListener('mouseup', this.mouseup)
    if (this.visibilityChangeEventName) {
      document.removeEventListener(this.visibilityChangeEventName, this.visibilityChanged, false)
    }
  },
  methods: {
    visibilityChanged() {
      if (this.hiddenAttrName) {
        this.$eventHub.$emit('visibilityChanged', !document[this.hiddenAttrName])
      }
    },
    mousedown(event) {
      setTimeout(() => {
        this.$eventHub.$emit('mousedown', event)
      }, 50)
    },
    mouseup(event) {
      this.$eventHub.$emit('mouseup', event)
    },
    keydown(event) {
      this.$eventHub.$emit('keydown', event)
    },
    resize(event) {
      setTimeout(() => {
        this.$eventHub.$emit('windowResize', event)
      }, 50)
    },
    toggleMenu(value) {
      this.isMenuToggled = value
    },
    handleScroll(element) {
      if (element.target.scrollTop > 1) {
        this.hasScrolledToBottom = true
      } else {
        this.hasScrolledToBottom = false
      }
    },
    scrollToTop() {
      this.$globals.scrollToElement(this.$refs.containerScroller)
    },
  },
  components: {
    ToastsRoot,
    Dialogs,
    MainHeader,
    MainSidebar,
    MainFooter,
    LayoutPassengerControls,
    ModalNavigation,
    ServiceWorkerHandler,
    ModalsRoot,
  },
}
</script>

<template lang="pug">
.erebus-app
  .content-wrapper(:class="!isMenuToggled && isOperationalModule ? 'is-condensed' : null")
    template(v-if="isOperationalModule")
      main-header(@toggle-menu="toggleMenu($event)")
      .operational-layout
        main-sidebar
      .route-content.container-fluid.position-relative.container-fluid-padding.width-transition(
        :class="[{ 'uses-dimmed-page-background': $route.meta?.usesDimmedPageBackground }, `layout-${$route.meta?.pageLayoutClassName || PageLayoutClassNameType.Default}`]"
        :style="$route.meta?.additionalPageLayoutStyle || {}"
      )
        router-view

      main-footer.light

    template(v-else-if="isPassengerPortalModule")
      .passenger-portal-layout
        layout-passenger-controls
      .container-fluid.position-relative.w-100(ref="containerScroller" @scroll="handleScroll")
        router-view
        transition(name="fade")
          passenger-round-button.btn-warning.btn-scroll-top.text-white(
            v-if="hasScrolledToBottom"
            @click="scrollToTop"
            v-tooltip.options="{ title: 'Scroll top', placement: 'right' }"
          )
            i.bi-arrow-up

      main-footer.light

    template(v-else-if="isErebusHubRoute")
      main-header
      router-view
      main-footer

    template(v-else)
      router-view
      main-footer.light

  #modal-portal
  modal-navigation
  #context-menu-portal
  modals-root
  toasts-root
  dialogs
  service-worker-handler

.env-ribbon(v-if="!['production', 'test'].includes(currentEnv)") {{ currentEnv }}
</template>

<style scoped lang="scss">
.route-content {
  &.uses-dimmed-page-background {
    background: #e9ecef;
  }

  &.layout-default {
    padding: 24px;
  }

  &.layout-no-padding {
    padding: 0;
  }
  &.layout-legacy-padding {
    padding: 12px;
  }
}
</style>
