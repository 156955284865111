import DatatableQueryPreview from 'frontend/common/ea-query-preview/datatable-query-preview.vue'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { FLIGHT_NUMBER_MATCHES } from 'frontend/transport-booking-grouping-rules'

export const TransportBookingGroupingRuleDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'eid',
    'name',
    'description',
    'delta_time',
    'flight_number_match',
    'priority',
    'created_at',
    'updated_at',
    'criteria',
  ],
  subscriptions: {
    items: 'TransportBookingGroupingRulesChannel',
  },
  defaultColumns: [
    'eid',
    'priority',
    'name',
    'timeWindow',
    'description',
    'flightNumberMatch',
    'criteria',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },
    priority: {
      label: 'Priority',
      cell: {
        kind: 'simple',
        path: 'priority',
      },
    },
    name: {
      label: 'Name',
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },
    description: {
      label: 'Description',
      header: {},
      cell: {
        kind: 'simple',
        path: 'description',
      },
    },
    timeWindow: {
      label: 'Time window',
      header: {},
      cell: {
        kind: 'function',
        fn: el => `±${el.deltaTime}min`,
      },
    },
    flightNumberMatch: {
      label: 'Matching travel no',
      header: {
        sort: 'flightNumberMatch',
      },
      cell: {
        kind: 'function',
        fn: el => FLIGHT_NUMBER_MATCHES.find(m => m.value == el.flightNumberMatch)?.label,
      },
    },
    criteria: {
      label: 'Criteria',
      cell: {
        kind: 'component',
        component: DatatableQueryPreview,
        componentProps: el => ({
          query: el.criteria,
        }),
      },
    },
    createdAt: {
      label: 'Created Datetime',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroup => ({ t: clientGroup.createdAt }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroup => ({ t: clientGroup.updatedAt }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroup => ({
          t: clientGroup.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: clientGroup => ({
          t: clientGroup.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  },
}
