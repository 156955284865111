<script setup lang="ts">
import { ClientGroup } from 'frontend/client-groups'
import { Client } from 'frontend/clients'
import { computed } from 'vue'

interface Props {
  transportBooking: any
  limit?: number
  allowWrap?: boolean
  showTotal?: boolean
}

const props = withDefaults(defineProps<Props>(), { limit: Infinity, showTotal: false })

const coPassengers = computed(() => {
  const tb = props.transportBooking
  return (tb.shiftJob?.passengers || []).filter(c => c.id != tb.client?.id)
})

// legacy support
const unnamedCoPassengersCount = computed(() => {
  const tb = props.transportBooking
  if (!tb.client?.id && !tb.clientGroup?.id) {
    return tb.shiftJob?.additionalPassengers - (tb.numberOfClients || 1)
  } else {
    return tb.shiftJob?.additionalPassengers
  }
})

const clientGroupCoPassengers = computed(() => {
  const shiftJob = props.transportBooking.shiftJob
  if (!shiftJob) return []

  return shiftJob.clientGroupPassengers.reduce((acc, clientGroupWithCount) => {
    if (clientGroupWithCount.id === props.transportBooking.clientGroup?.id) {
      const cg = {
        ...clientGroupWithCount,
        clientsCount: clientGroupWithCount.clientsCount - props.transportBooking.numberOfClients,
      }
      if (cg.clientsCount > 0) {
        acc.push(cg)
      }
    } else {
      acc.push(clientGroupWithCount)
    }

    return acc
  }, [])
})

const hasUnnamedPassengers = computed(() => {
  return unnamedCoPassengersCount.value > 0
})

const shouldShowTotal = computed(() => {
  if (!props.showTotal) return false

  const hasSingleClientGroupType =
    clientGroupCoPassengers.value.length == 1 &&
    !coPassengers.value.length &&
    !hasUnnamedPassengers.value
  const hasOnlyOneClient =
    coPassengers.value.length == 1 &&
    !clientGroupCoPassengers.value?.length &&
    !hasUnnamedPassengers.value
  const hasOnlyUnnamedClients =
    !coPassengers.value.length &&
    !clientGroupCoPassengers.value?.length &&
    hasUnnamedPassengers.value

  return !hasOnlyOneClient && !hasSingleClientGroupType && !hasOnlyUnnamedClients
})

const totalCoPassengers = computed(() => {
  return (
    coPassengers.value.length +
    clientGroupCoPassengers.value.reduce((acc, cg) => acc + cg.clientsCount, 0) +
    (unnamedCoPassengersCount.value > 0 ? unnamedCoPassengersCount.value : 0)
  )
})

const rowsToShow = computed(() => {
  let remaining = props.limit
  const clientsToShow = Math.min(coPassengers.value.length, remaining)
  remaining -= clientsToShow
  const clientGroupsToShow = Math.min(clientGroupCoPassengers.value.length, remaining)
  remaining -= clientGroupsToShow
  const unnamedToShow = Math.min(unnamedCoPassengersCount.value, remaining)

  return {
    clients: coPassengers.value?.slice(0, clientsToShow) || [],
    clientGroups: clientGroupCoPassengers.value?.slice(0, clientGroupsToShow) || [],
    unnamed: unnamedToShow,
    totalShown: clientsToShow + clientGroupsToShow + unnamedToShow,
    totalRows:
      coPassengers.value.length +
      clientGroupCoPassengers.value.length +
      Math.min(unnamedCoPassengersCount.value, 1),
  }
})
</script>

<template lang="pug">
.d-flex.align-items-start.justify-content-start
  .flex-1
    slot(:coPassengers="coPassengers" name="clients")
      .inline-block.text-nowrap(v-for="client of rowsToShow.clients" :key="client.id")
        client(:client="client")

    slot(:clientGroupCoPassengers="clientGroupCoPassengers" name="clientGroups")
      template(
        v-for="clientGroupPassengers of rowsToShow.clientGroups"
        :key="clientGroupPassengers.id"
      )
        .text-nowrap
          span.small.bold {{ clientGroupPassengers.clientsCount }} x&nbsp;
          client-group(:clientGroup="clientGroupPassengers")
    slot(name="unnamed")
      .text-nowrap(v-if="hasUnnamedPassengers && rowsToShow.unnamed > 0")
        span {{ unnamedCoPassengersCount || 1 }}
        span.ms-1(v-if="unnamedCoPassengersCount > 1") unnamed clients
        span.ms-1(v-else) unnamed client
    .small.text-black-50(v-if="props.limit < rowsToShow.totalRows")
      .text-nowrap and {{ rowsToShow.totalRows - props.limit }} rows more...
      .text-nowrap (click row details to see all)
    .small.text-black-15(v-else-if="totalCoPassengers <= 0") empty
small.float-end.ms-2.text-muted(v-if="shouldShowTotal && totalCoPassengers > 0") ({{ totalCoPassengers }} total)
</template>
