import { IColumnPresetData } from '../types/IColumnPresetData'

export function mergeColumnPresetData(
  ...dataItems: Array<IColumnPresetData | Record<string, never>>
): IColumnPresetData | Record<string, never> {
  const targetObj = {}
  for (const dataItem of dataItems) {
    for (const [key, value] of Object.entries(dataItem)) {
      if (value) {
        targetObj[key] = value
      }
    }
  }

  return targetObj
}
