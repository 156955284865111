<script>
import EaInput from 'frontend/common/ea-form-controls/ea-input.vue'
import InputWithIconComponent from 'frontend/roles/components/InputWithIconComponent.vue'
import { computed, inject } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'DataTableTools',
  emits: ['update:search'],
  props: {
    disableTitleAndFilters: { type: Boolean, default: false },
    title: {
      type: String,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
    },
    shouldShowFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const inModal = inject('inModal')

    const shownTitle = computed(() => {
      if (inModal.value || props.title || props.title === null) {
        return props.title
      } else {
        return useRoute().meta?.title
      }
    })

    return {
      shownTitle,
    }
  },
  computed: {
    leftSideSlotsPresent() {
      return Object.keys(this.$slots).filter(slot => ['title', 'filters'].includes(slot))
    },
    searchModel: {
      get() {
        return this.search
      },
      set(value) {
        this.$emit('update:search', value)
      },
    },
  },
  components: { InputWithIconComponent, EaInput },
}
</script>

<template lang="pug">
.data-table-tools
  .tools
    .search-wrapper
      input-with-icon-component(icon-class="mi-outlined mi-search" v-if="enableSearch")
        .input-wrapper
          ea-input.search-input(
            v-model="searchModel"
            icon="search"
            placeholder="Search"
          )
    .other-tools-wrapper
      //.data-table-tools-title-container(
      //  v-if="(shownTitle?.length || leftSideSlotsPresent) && !disableTitleAndFilters"
      //)
      .data-table-tools-slot-container
        slot(v-if="shouldShowFilters" name="filters")
        slot
</template>

<style scoped lang="scss">
.data-table-tools {
  display: grid;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid #b8c1cc;

  .modal & {
    padding: 0 0 16px 0;
    border: none;
  }
}

.tools {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;
  align-items: center;
}

.search-wrapper {
  &:deep(.form-group.mb-1) {
    margin-bottom: 0 !important;
  }
  .input-with-icon {
    width: 100%;
  }
  .search-input {
    width: 100%;
  }
}

.input-wrapper {
  width: 100%;
}
</style>
