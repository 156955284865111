import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { KINDS } from 'frontend/notification-templates'

export const NotificationTemplateDataModelTypeConfig: IDataModelTypeConfig = {
  fields: ['id', 'kind', 'title', 'body', 'updated_at', 'created_at'],
  subscriptions: {
    items: 'NotificationTemplatesChannel',
    eventOrganizationUsers: 'EventOrganizationUsersSimpleChannel',
  },
  defaultColumns: ['kind', 'title', 'body'],
  columnConfig: {
    kind: {
      label: 'Message type',
      header: {
        sort: 'kind_index',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'function',
        fn: item => KINDS[item.kind],
      },
    },

    title: {
      label: 'Title',
      header: {
        sort: 'title',
      },
      cell: {
        class: 'text-nowrap',
        kind: 'simple',
        path: 'title',
      },
    },
    body: {
      label: 'Body',
      header: {
        sort: 'body',
      },
      cell: {
        class: 'width-100p',
        kind: 'simple',
        path: 'body',
      },
    },

    createdAt: {
      label: 'Created Datetime',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: notificationTemplate => ({ t: notificationTemplate.createdAt }),
      },
    },
    createdDate: {
      label: 'Created Date',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: notificationTemplate => ({
          t: notificationTemplate.createdAt,
          format: 'dateOnly',
        }),
      },
    },
    createdTime: {
      label: 'Created Time',
      header: {
        sort: 'createdAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: notificationTemplate => ({
          t: notificationTemplate.createdAt,
          format: 'timeOnly',
        }),
      },
    },
    updatedAt: {
      label: 'Updated Datetime',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: notificationTemplate => ({ t: notificationTemplate.updatedAt }),
      },
    },
    updatedDate: {
      label: 'Updated Date',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: notificationTemplate => ({
          t: notificationTemplate.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
    updatedTime: {
      label: 'Updated Time',
      header: {},
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: notificationTemplate => ({
          t: notificationTemplate.updatedAt,
          format: 'timeOnly',
        }),
      },
    },
  },
}
