import { createGlobalState } from '@vueuse/core'
import { KeyboardModifierKeyTypes } from 'frontend/keyboard/enum/KeyboardModifierKeyTypes'
import { KeyboardKeyCodeMap } from 'frontend/keyboard/maps/KeyboardKeyCodeMap'
import { IKeyboardKeyCombination } from 'frontend/keyboard/types/IKeyboardKeyCombination'
import { IKeyboardShortcut } from 'frontend/keyboard/types/IKeyboardShortcut'
import { IKeyboardShortcutItem } from 'frontend/keyboard/types/IKeyboardShortcutItem'
import { computed, ref } from 'vue'

export const useKeyboardShortcutStore = createGlobalState(() => {
  const shortcutItems = ref<Array<IKeyboardShortcutItem>>([])
  const scopes = ref<symbol[]>([])

  window.addEventListener('keydown', keyDownListener)

  function createScope(scope: symbol) {
    scopes.value.push(scope)
  }

  function deleteScope(scope: symbol) {
    scopes.value = scopes.value.filter(currentScope => {
      return currentScope !== scope
    })
  }

  function registerShortcuts(
    shortcuts: IKeyboardShortcut[],
    scope: symbol,
    isGlobal: boolean,
  ): symbol[] {
    return shortcuts.map(shortcut => {
      return registerShortcut(shortcut, scope, isGlobal)
    })
  }

  function registerShortcut(shortcut: IKeyboardShortcut, scope: symbol, isGlobal: boolean): symbol {
    const id = Symbol()
    shortcutItems.value.push({
      shortcut,
      id,
      scope,
      isGlobal,
    })
    return id
  }

  function deleteShortcutsByIds(ids: symbol[]): void {
    shortcutItems.value = shortcutItems.value.filter(shortcut => {
      return !ids.includes(shortcut.id)
    })
  }

  function deleteShortcutById(id: symbol): void {
    deleteShortcutsByIds([id])
  }

  const currentScope = computed(() => {
    return scopes.value[scopes.value.length - 1]
  })

  const shortcutsInCurrentScope = computed(() => {
    return shortcutItems.value.filter(shortcut => {
      return shortcut.scope === currentScope.value || shortcut.isGlobal === true
    })
  })

  function getKeyCombinationOutOfEvent(event: KeyboardEvent): IKeyboardKeyCombination {
    const items = [
      {
        keyType: KeyboardModifierKeyTypes.Alt,
        value: event.altKey,
      },
      {
        keyType: KeyboardModifierKeyTypes.Control,
        value: event.ctrlKey,
      },
      {
        keyType: KeyboardModifierKeyTypes.Shift,
        value: event.shiftKey,
      },
      {
        keyType: KeyboardModifierKeyTypes.Meta,
        value: event.metaKey,
      },
    ]
    const modifierKeys = items.filter(item => item.value).map(item => item.keyType)
    const key = mapKeyCodeToKey(event.code)
    return [...modifierKeys, key]
  }

  function mapKeyCodeToKey(keyCode: string): string {
    return KeyboardKeyCodeMap.get(keyCode) || keyCode
  }

  function compareTwoKeyCombinations(
    firstCombination: IKeyboardKeyCombination,
    secondCombination: IKeyboardKeyCombination,
  ): boolean {
    return (
      firstCombination
        .map(e => e.toLowerCase())
        .sort()
        .join(',') ===
      secondCombination
        .map(e => e.toLowerCase())
        .sort()
        .join(',')
    )
  }

  function keyDownListener(event: KeyboardEvent) {
    const eventCombination = getKeyCombinationOutOfEvent(event)
    // console.log('shortcutsInCurrentScope.value', shortcutsInCurrentScope.value)
    const matchingShortcuts = shortcutsInCurrentScope.value.filter(shortcut => {
      // console.log('shortcut', shortcut)
      if (Array.isArray(shortcut.shortcut.keys[0])) {
        return shortcut.shortcut.keys.some(keys =>
          compareTwoKeyCombinations(eventCombination, keys),
        )
      }

      return compareTwoKeyCombinations(
        eventCombination,
        shortcut.shortcut.keys as IKeyboardKeyCombination,
      )
    })
    if (!matchingShortcuts.length) {
      return
    }
    for (const shortcut of matchingShortcuts) {
      shortcut.shortcut.callback(event)
    }
    event.preventDefault()
  }

  return {
    shortcutItems,
    scopes,
    createScope,
    deleteScope,
    registerShortcuts,
    registerShortcut,
    deleteShortcutsByIds,
    deleteShortcutById,
    currentScope,
    shortcutsInCurrentScope,
    getKeyCombinationOutOfEvent,
    mapKeyCodeToKey,
    compareTwoKeyCombinations,
  }
})
