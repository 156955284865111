import turfBbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import turfCenter from '@turf/center'
import { IMap } from 'fifa-gis-library'
import { provide, ref, shallowRef } from 'vue'

export const defaultIcon = ({
  scale = 1.0,
  label,
  fillColor = '#5ac9dd',
  strokeWeight = 2.2,
  strokeColor = '#0082b5',
} = {}) => {
  const width = 30 * scale
  const height = 47 * scale

  return {
    size: [width, height],
    source: `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="-9 -44 18 45">
        <path d="M 0 0 C -2 -20 -10 -22 -10 -30 A 10 10 0 1 1 10 -30 C 10 -22 2 -20 0 0 z M -2 -30 a 2 2 0 1 1 4 0 a 2 2 0 1 1 -4 0 z M -1 -30 a 1 1 0 1 1 2 0 a 1 1 0 1 1 -2 0 z M -3 -30 a 3 3 0 1 1 6 0 a 3 3 0 1 1 -6 0" fill="${fillColor}" stroke-width="${strokeWeight}" stroke="${strokeColor}">
        </path>
      </svg>
    `,
    anchor: [15, 47],
    labelOffset: [15, 15],
    label: label || '',
    labelStyle: {
      fontColor: '#ffffff',
      fontSize: 11,
      fontWeight: 500,
    },
  }
}

export const iconFactory = ({
  label = null,
  fillColor = '#ff4646',
  strokeColor = '#770000',
  scale = 1.0,
  ...rest
} = {}) => {
  const width = 30 * scale
  const height = 47 * scale
  const fontSize = 11 * scale

  return {
    size: [width, height],
    source: `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="-9 -44 18 45">
        <path d="M 0 0 C -2 -20 -10 -22 -10 -30 A 10 10 0 1 1 10 -30 C 10 -22 2 -20 0 0 Z" fill="${fillColor}" stroke-width="1" stroke="${strokeColor}">
        </path>
      </svg>
    `,
    anchor: [15 * scale, 47 * scale],
    labelOffset: [15 * scale, 16 * scale],
    label: label || '',
    labelStyle: {
      fontColor: '#ffffff',
      fontSize: fontSize,
      fontWeight: 500,
    },
    ...rest,
  }
}

export const dropoffIconFactory = ({
  label = null,
  fillColor = '#0d98ff',
  strokeColor = '#FFFFFF',
  scale = 1.2,
  withTime = true,
  ...rest
} = {}) => {
  const width = 58 * scale
  const height = 30 * scale
  const fontSize = 11 * scale
  const timeRect = `<rect x="3" y="2" width="60" height="20" rx="10" fill="${fillColor}"/>`
  return {
    size: [width, height],
    source: `
        <svg width="${width}" height="${height}" viewBox="0 0 63 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        ${withTime ? timeRect : ''}
        <g filter="url(#filter0_d_79_65)">
        <circle cx="12" cy="12" r="10" fill="${strokeColor}"/>
        <circle cx="12" cy="12" r="9.5" stroke="${fillColor}"/>
        </g>
        <circle cx="12" cy="12" r="6.5" fill="${strokeColor}" stroke="${fillColor}"/>
        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 13)" fill="${fillColor}"/>
        <defs>
        <filter id="filter0_d_79_65" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="1"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_79_65"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_79_65" result="shape"/>
        </filter>
        </defs>
        </svg>
    `,
    anchor: [10 * scale, (26 / 2) * scale],
    labelOffset: [38 * scale, (30 / 2) * scale],
    label: label || '',
    labelStyle: {
      fontColor: '#ffffff',
      fontSize: fontSize,
      fontWeight: 500,
    },
    ...rest,
  }
}
export const pickupIconFactory = ({
  label = null,
  fillColor = '#FFFFFF',
  strokeColor = '#32a85e',
  scale = 1.2,
  withTime = true,
  ...rest
} = {}) => {
  const width = 58 * scale
  const height = 30 * scale
  const fontSize = 11 * scale

  const timeRect = `<rect x="3.5" y="2.5" width="60" height="19" rx="10" fill="${fillColor}" stroke="${strokeColor}" stroke-width="2"/>`

  return {
    size: [width, height],
    source: `
      <svg width="${width}" height="${height}" viewBox="0 0 64 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      ${withTime ? timeRect : ''}
      <g>
      <circle cx="12" cy="12" r="10" fill="${strokeColor}"/>
      <circle cx="12" cy="12" r="9.5" stroke="${strokeColor}" stroke-width="2"/>
      </g>
      <path d="M18.5 11.134C19.1667 11.5189 19.1667 12.4811 18.5 12.866L9.5 18.0622C8.83333 18.4471 8 17.966 8 17.1962L8 6.80385C8 6.03405 8.83333 5.55292 9.5 5.93782L18.5 11.134Z" fill="${fillColor}"/>
      <defs>
      </defs>
      </svg>

    `,
    anchor: [10 * scale, (26 / 2) * scale],
    labelOffset: [38 * scale, (30 / 2) * scale],
    label: label || '',
    labelStyle: {
      fontColor: strokeColor,
      fontSize: fontSize,
      fontWeight: 500,
    },
    ...rest,
  }
}

export const waypointIconFactory = ({
  label = null,
  fillColor = '#ffffff',
  strokeColor = '#326295',
  scale = 1.0,
  ...rest
} = {}) => {
  const width = 40 * scale
  const height = 40 * scale
  const fontSize = 11 * scale

  return {
    size: [width, height],
    source: `
    <svg  width="${width}" height="${height}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" style="filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3))" cy="12" r="8" fill="${strokeColor}"></circle>
      <circle cx="12" cy="12" r="6" fill="${fillColor}"></circle>
    </svg>
    `,
    anchor: [20 * scale, 20 * scale],
    labelOffset: [20 * scale, 22 * scale],
    label: label || '',
    labelStyle: {
      fontColor: '#326295',
      fontSize: fontSize,
      fontWeight: 500,
    },
    ...rest,
  }
}

export const FIFA_HEADQUARTERS_LAT_LNG = { lat: 47.38159973416059, lng: 8.57449358650673 }

export function useFifaGisMap(mapRef) {
  const isMapLoaded = ref(false)
  const map = shallowRef(null)
  if (mapRef) {
    provide('fifaGisMap', map)
    provide('fifaGisMapLoaded', isMapLoaded)
  }

  function initializeMap(options) {
    let onLoaded
    const promise = new Promise(resolve => {
      onLoaded = resolve
    })

    let center
    if (options?.item?.type == 'Polygon') {
      const bbox = turfBbox(options.item)
      center = turfCenter(bboxPolygon(bbox)).geometry.coordinates
      center = { lat: center[0], lng: center[1] }
    } else if (options?.item?.type == 'Point') {
      center = { lat: options.item.coordinates[0], lng: options.item.coordinates[1] }
    } else if (options?.item?.latitude && options?.item?.longitude) {
      center = { lat: options.item.latitude, lng: options.item.longitude }
    }

    if (!mapRef?.value) {
      throw new Error('"mapRef" is not defined. Cannot initialize fifa gis map.')
    }

    map.value = new IMap(mapRef?.value, {
      center: center || FIFA_HEADQUARTERS_LAT_LNG,
      zoom: center ? 15 : 2,
      minZoom: 1,
      maxZoom: 19,
      ...(options || {}),
    })

    map.value.whenReady(() => {
      onLoaded(map.value)
      isMapLoaded.value = true
    })

    return promise
  }

  function preparePoint(item) {
    return {
      position: {
        lat: item.coordinates[0],
        lng: item.coordinates[1],
      },
      label: item.label,
      labelOffset: [50, 50],
      labelStyle: {
        fontColor: '#000000',
        fontSize: 12,
        fontWeight: 500,
      },
      infoPopoverContent: item.title,
      infoPopoverTrigger: 'hover',
      icon: item.icon || iconFactory(item),
    }
  }

  const preparePolygon = item => ({
    geometry: { ...item },
    style: {
      strokeColor: item.strokeColor || '#FF0000',
      strokeOpacity: item.strokeOpacity || 1.0,
      strokeWeight: item.strokeWeight || 2,
      fillColor: item.fillColor || '#000000',
      fillOpacity: item.fillOpacity || 0.1,
    },
  })

  const preparePolyline = item => ({
    geometry: { ...item },
    style: {
      strokeColor: item.strokeColor || '#326295',
      strokeOpacity: item.strokeOpacity || 0.7,
      lineWidth: item.lineWidth || 4,
      ...(item?.style || {}),
    },
  })

  const addMapItems = items => {
    items?.forEach(item => addMapItem(item))
  }

  const addMapItem = item => {
    switch (item.type) {
      case 'Polygon':
        return map.value.addPolygon(preparePolygon(item))
      case 'Point': {
        return map.value.addMarker(preparePoint(item))
      }
    }
  }

  function centerMapOn(item, options) {
    options = options || {}
    if (!item) return

    if (item.type == 'Polygon') {
      const bbox = turfBbox(item)
      const bounds = {
        ne: { lat: parseFloat(bbox[2]), lng: parseFloat(bbox[3]) },
        sw: { lat: parseFloat(bbox[0]), lng: parseFloat(bbox[1]) },
      }

      map.value.fitBounds(bounds, { padding: [20, 20], duration: 0.2, ...options })
    } else if (item.type == 'Point') {
      map.value?.panTo({
        center: { lat: item.coordinates?.[0], lng: item.coordinates?.[1] },
        zoom: 15,
        duration: 0.2,
        ...options,
      })
    } else if (item.latitude && item.longitude) {
      map.value?.panTo({
        center: { lat: item.latitude, lng: item.longitude },
        zoom: 15,
        duration: 0.2,
        ...options,
      })
    }
  }

  return {
    addMapItems,
    centerMapOn,
    initializeMap,
    isMapLoaded,
    map,
    preparePolygon,
    preparePoint,
    preparePolyline,
    iconFactory,
    waypointIconFactory,
    pickupIconFactory,
    dropoffIconFactory,
  }
}
