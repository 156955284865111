<script>
import ShiftActionAasmState from 'frontend/shift-actions/shift-action-aasm-state.vue'
import ShuttleTransferAasmState from 'frontend/shuttle-transfers/shuttle-transfer-aasm-state.vue'
import TransferGroupAasmState from 'frontend/transfer-groups/transfer-group-aasm-state.vue'

export default {
  name: 'ShiftJobAasmState',
  props: {
    item: { type: Object },
  },
  components: {
    TransferGroupAasmState,
    ShiftActionAasmState,
    ShuttleTransferAasmState,
  },
}
</script>

<template lang="pug">
transfer-group-aasm-state(v-if="item.type == 'TransferGroup'" :aasmState="item.aasmState")
shift-action-aasm-state(v-else-if="item.type == 'ShiftAction'" :aasmState="item.aasmState")
shuttle-transfer-aasm-state(v-else-if="item.type == 'ShuttleTransfer'" :aasmState="item.aasmState")
span.text-black-15(v-else) empty
</template>
