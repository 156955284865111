import { ClientGroup } from 'frontend/client-groups'
import { Cluster } from 'frontend/clusters'
import { IDataModelTypeConfig } from 'frontend/dataModels/types/IDataModelTypeConfig'
import { Osl } from 'frontend/osls'
import DaysOfService from 'frontend/shuttle-services/shuttle-service-days-of-service.vue'
import ShuttleServicePoint from 'frontend/shuttle-services/shuttle-service-point.vue'
import { VehicleType } from 'frontend/vehicle-types'
import { markRaw } from 'vue'

export const ShuttleServiceDataModelTypeConfig: IDataModelTypeConfig = {
  fields: [
    'id',
    'average_duration',
    'days_of_service',
    'description',
    'eid',
    'bus_id',
    'match_end_time',
    'match_start_time',
    'name',
    'timetable',
    'created_at',
    'updated_at',
    'cluster_id',
    'is_imported',
    { cluster: ['id', 'name', 'time_zone'] },
    { vehicle_types: ['id', 'name'] },
    { client_groups: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
    {
      operational_service_levels: [
        'id',
        'name',
        'font_color',
        'background_color',
        'border_color',
        'description',
      ],
    },
    {
      shuttle_service_points: [
        'id',
        'position',
        'direction',
        'common_name',
        { location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
        { transportation_point: ['id', 'name'] },
      ],
    },
  ],
  subscriptions: {
    items: 'ShuttleServicesChannel',
    operationalServiceLevels: 'OperationalServiceLevelsSimpleChannel',
    locations: 'LocationsSimpleChannel',
    vehicleTypes: 'VehicleTypesSimpleChannel',
    clientGroups: 'ClientGroupsSimpleChannel',
    transportationPoints: 'TransportationPointsSimpleChannel',
  },
  defaultColumns: [
    'eid',
    'name',
    'busId',
    'daysOfService',
    'cluster',
    'averageDuration',
    'uproute',
    'downroute',
    'osls',
    'vehicleTypes',
    'clientGroups',
    'matchStartTime',
    'matchEndTime',
    'description',
    'isImported',
    'updatedAt',
  ],
  columnConfig: {
    eid: {
      label: 'EID',
      header: {
        sort: 'eid',
      },
      cell: {
        kind: 'simple',
        path: 'eid',
      },
    },

    name: {
      label: 'Name',
      header: {
        sort: 'name',
      },
      cell: {
        kind: 'simple',
        path: 'name',
      },
    },

    matchStartTime: {
      label: 'Match/event start',
      header: {
        sort: 'matchStartTime',
      },
      cell: {
        kind: 'component',
        component: 'string-time-presenter',
        componentProps: el => ({
          t: el.matchStartTime,
          tz: el.cluster?.timeZone,
        }),
      },
    },

    matchEndTime: {
      label: 'Match/event end',
      header: {
        sort: 'matchEndTime',
      },
      cell: {
        kind: 'component',
        component: 'string-time-presenter',
        componentProps: el => ({
          t: el.matchEndTime,
          tz: el.cluster?.timeZone,
        }),
      },
    },

    description: {
      label: 'Description',
      header: {
        sort: 'description',
      },
      cell: {
        kind: 'simple',
        path: 'description',
      },
    },

    busId: {
      label: 'Bus ID',
      header: {
        sort: 'busId',
      },
      cell: {
        kind: 'simple',
        path: 'busId',
      },
    },

    isImported: {
      label: 'Imported',
      header: {
        sort: 'isImported',
      },
      cell: {
        kind: 'function',
        fn: ss => (ss.isImported ? 'Yes' : 'No'),
      },
    },

    cluster: {
      label: 'Cluster',
      header: {
        sort: 'clusterName',
      },
      cell: {
        kind: 'component',
        component: markRaw(Cluster),
        componentProps: item => ({
          cluster: item.cluster,
        }),
      },
    },

    uproute: {
      label: 'Up-route',
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: uproute(el),
          limit: 5,
          component: markRaw(ShuttleServicePoint),
          componentProps: point => ({ point: point }),
        }),
      },
    },

    downroute: {
      label: 'Down-route',
      header: {},
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: downroute(el),
          limit: 5,
          component: markRaw(ShuttleServicePoint),
          componentProps: point => ({ point: point }),
        }),
      },
    },
    osls: {
      label: 'OSLs',
      tooltip: 'Operational service levels',
      header: {
        sort: 'operationalServiceLevelsJoinedPosition',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: el.operationalServiceLevels,
          limit: 5,
          component: markRaw(Osl),
          componentProps: ell => ({ osl: ell }),
        }),
      },
    },
    vehicleTypes: {
      label: 'Vehicle types',
      header: {
        // sort: 'vehicleTypeName',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: el.vehicleTypes,
          limit: 5,
          component: markRaw(VehicleType),
          componentProps: ell => ({ vehicleType: ell }),
        }),
      },
    },
    clientGroups: {
      label: 'Client groups',
      header: {
        // sort: 'clientGroupsName',
      },
      cell: {
        kind: 'component',
        component: 'limited-components-list',
        componentProps: el => ({
          items: el.clientGroups,
          limit: 5,
          component: markRaw(ClientGroup),
          componentProps: ell => ({ clientGroup: ell }),
        }),
      },
    },
    daysOfService: {
      label: 'Days of service',
      header: {},
      cell: {
        kind: 'component',
        component: markRaw(DaysOfService),
        componentProps: el => ({
          shuttleService: el,
          class: 'small',
        }),
      },
    },
    averageDuration: {
      label: 'Average duration',
      header: {
        sort: 'averageDuration',
      },
      cell: {
        kind: 'function',
        fn: st => (st.averageDuration ? `${st.averageDuration}min` : null),
      },
    },
    updatedAt: {
      label: 'Updated datetime',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({ t: item.updatedAt }),
      },
    },

    updatedAtTime: {
      label: 'Updated time',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'timeOnly',
        }),
      },
    },

    updatedAtDate: {
      label: 'Updated date',
      header: {
        sort: 'updatedAt',
      },
      cell: {
        kind: 'component',
        component: 'formatted-time',
        componentProps: item => ({
          t: item.updatedAt,
          format: 'dateOnly',
        }),
      },
    },
  },
}
