import { TransportBufferType } from 'frontend/transport-buffers/enum/transport-buffer-type'

export const TransportBufferTypeItems = [
  {
    transportBufferType: null,
    singularName: 'All',
    pluralName: 'All buffers',
  },
  {
    transportBufferType: TransportBufferType.Arrival,
    singularName: 'Arrival',
    pluralName: 'Arrival buffers',
  },
  {
    transportBufferType: TransportBufferType.Departure,
    singularName: 'Departure',
    pluralName: 'Departure buffers',
  },
  {
    transportBufferType: TransportBufferType.Load,
    singularName: 'Load',
    pluralName: 'Load buffers',
  },
  {
    transportBufferType: TransportBufferType.Safety,
    singularName: 'Safety',
    pluralName: 'Safety buffers',
  },
  {
    transportBufferType: TransportBufferType.DriverOnSpot,
    singularName: 'Driver on spot',
    pluralName: 'Driver on spot buffers',
  },
  {
    transportBufferType: TransportBufferType.Waiting,
    singularName: 'Wait',
    pluralName: 'Wait buffers',
  },
]
