import PrivateJetDisplay from 'frontend/all-demands/private-jet-display.vue'
import { ClientGroup } from 'frontend/client-groups'
import { Cluster } from 'frontend/clusters'
import CollapsibleItems from 'frontend/common/collapsible-items.vue'
import DurationWithOverride from 'frontend/common/duration-with-override.vue'
import Text from 'frontend/common/text.vue'
import TrueFalseLabel from 'frontend/common/true-false-label.vue'
import { DriverCategory } from 'frontend/driver-categories'
import { DriverSource } from 'frontend/driver-sources'
import { Driver } from 'frontend/drivers'
import { Location, LocationWithTp } from 'frontend/locations'
import { Osl } from 'frontend/osls'
import { KINDS as SA_KINDS } from 'frontend/shift-actions'
import ShiftJobAasmState from 'frontend/shift-jobs/shift-job-aasm-state.vue'
import ShiftJobType from 'frontend/shift-jobs/shift-job-type.vue'
import { Shift } from 'frontend/shifts'
import { ShuttleService } from 'frontend/shuttle-services'
import { BusIdDisplay, SOURCES } from 'frontend/shuttle-transfers/index'
import { TransferGroupPassengersList } from 'frontend/transfer-groups'
import { transferGroupPassengersCount } from 'frontend/transfer-groups/helpers/transfer-group-passengers-count'
import {
  PROVIDED_STAMPS,
  TransportBooking,
  TransportBookingTimeRange,
} from 'frontend/transport-bookings'
import { TransportationPoint } from 'frontend/transportation-points'
import { User } from 'frontend/users'
import { VehicleCategory } from 'frontend/vehicle-categories'
import { VehicleModel } from 'frontend/vehicle-models'
import { VehicleSource } from 'frontend/vehicle-sources'
import { VehicleType } from 'frontend/vehicle-types'
import { Vehicle } from 'frontend/vehicles'
import { camelCase, trim, uniq, uniqBy } from 'lodash'
import { markRaw } from 'vue'

import { CUSTOM_JOB_KINDS } from './index'

const otherShuttlePoints = st => {
  return uniqBy(
    (st.shuttlePoints || [])
      .filter(sp => ![st.startLocation?.id, st.endLocation?.id].includes(sp.location?.id))
      .sort((a, b) => a.position - b.position),
    el => el.location?.id,
  )
}

const uniqCreatedBy = tg => {
  const tbs = (tg.transportBookings || []).filter(tb => !!tb.createdBy)
  if (tbs?.length) {
    return uniqBy(
      tbs.map(el => el.createdBy),
      'id',
    )
  } else {
    return null
  }
}

const uniqTptComment = tg => {
  const tbs = (tg.transportBookings || []).filter(tb => tb.tptComment?.length)
  if (tbs?.length) {
    return uniq(tbs.map(el => trim(el.tptComment)))
  } else {
    return null
  }
}

const uniqComment = tg => {
  const tbs = (tg.transportBookings || []).filter(tb => tb.comment?.length)
  if (tbs?.length) {
    return uniq(tbs.map(el => trim(el.comment)))
  } else {
    return null
  }
}

const uniqFlightNumber = tg => {
  const tbs = (tg.transportBookings || []).filter(tb => tb.flightNumber?.length)
  if (tbs?.length) {
    return uniq(tbs.map(el => trim(el.flightNumber)))
  } else {
    return null
  }
}

const uniqPrivateJets = tg => {
  const tbToDisplay = []
  if (tg.transportBookings?.length) {
    uniq(tg.transportBookings.map(el => tbToDisplay.push(el)))
    return tbToDisplay
  } else {
    return null
  }
}

const uniqFlightClass = tg => {
  const tbs = (tg.transportBookings || []).filter(tb => tb.flightClass?.length)
  if (tbs?.length) {
    return uniq(tbs.map(el => trim(el.flightClass)))
  } else {
    return null
  }
}

const tbsByOppositeRouteBookingId = tg =>
  (tg.transportBookings || []).filter(tb => tb.oppositeRouteBookingId?.length > 0)

const uniqTbProvidedStamps = tg => {
  const result = uniq(
    tg.transportBookings?.map(el => PROVIDED_STAMPS.find(s => s.value == el.providedStamp)?.label),
  )
  return result
}

const uniqTbRequestedTimes = item => {
  const result = {}
  item.transportBookings?.forEach(tb => {
    const key = dayjs(tb.requestedTime).format('HH:mm')
    result[key] = result[key] || tb.requestedTime
    // result[key].push(tb)
  })
  return Object.values(result)
}

const uniqTbRequestedDates = item => {
  const result = {}
  item.transportBookings?.forEach(tb => {
    const key = dayjs(tb.requestedTime).format('DD.MM.YYYY')
    result[key] = result[key] || tb.requestedTime
    // result[key].push(tb)
  })
  return Object.values(result)
}

const uniqTbsByTimeAndFixedTime = item => {
  const result = {}
  item.transportBookings?.forEach(tb => {
    const key = `${dayjs(tb.startAt).format('HH:mm')}${dayjs(tb.endAt).format('HH:mm')}${
      tb.providedStampFixed ? tb.providedStamp : ''
    }`
    result[key] = result[key] || []
    result[key].push(tb)
  })
  return Object.values(result)
}

const tbRemoteIds = tg => (tg.transportBookings || []).filter(tb => tb.remoteId?.length > 0)

export const FIELDS = [
  'id',
  'eid',
  'type',
  'aasm_state',
  'in_route_at',
  'at_pickup_site_at',
  'picked_up_at',
  'in_progress_at',
  'at_dropoff_site_at',
  'dropped_off_at',
  'no_show_at',
  'end_at',
  'start_at',
  'duration',
  'updated_at',
  'shift_action_kind',
  'bus_id',
  'source',
  'additional_clients',
  'description',
  'driver_comment',
  'all_client_groups',
  'subkind',
  {
    operational_service_levels: [
      'id',
      'name',
      'font_color',
      'background_color',
      'border_color',
      'position',
      'description',
    ],
  },
  {
    vehicle_types: ['id', 'name'],
  },
  {
    shuttle_points: [
      'id',
      'position',
      'common_name',
      'arrival_at',
      {
        location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
      },
    ],
  },
  {
    shuttle_service: ['id', 'eid', 'name', 'bus_id'],
  },
  {
    shift: [
      'id',
      'kind',
      'eid',
      {
        staging_area: [
          'id',
          {
            location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }],
          },
        ],
      },
      {
        driver: [
          'id',
          'name',
          'surname',
          'assigned_phone',
          'personal_phone',
          'primary_phone',
          { cluster: ['id', 'name', 'time_zone'] },
          {
            driver_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
          },
          {
            driver_source: ['id', 'name'],
          },
        ],
      },
      {
        vehicle: [
          'id',
          'fleet_number',
          'registration_plate',
          { cluster: ['id', 'name', 'time_zone'] },
          { vehicle_model: ['id', 'name', { vehicle_brand: ['id', 'name'] }] },
          { vehicle_type: ['id', 'name'] },
          { vehicle_source: ['id', 'name', 'billable', 'maintainable'] },
          {
            vehicle_category: ['id', 'name', 'background_color', 'border_color', 'font_color'],
          },
        ],
      },
    ],
  },
  { end_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
  { start_location: ['id', 'name', 'time_zone', { cluster: ['id', 'name', 'time_zone'] }] },
  { end_transportation_point: ['id', 'name'] },
  { start_transportation_point: ['id', 'name'] },
  {
    transport_bookings: [
      'id',
      'start_at',
      'end_at',
      'comment',
      'flight_number',
      'kind',
      'extended_kind',
      'remote_id',
      'provided_stamp_fixed',
      'provided_stamp',
      'last_known_shift_id',
      'unassignment_seen',
      'calculated_arrival_buffer',
      'calculated_departure_buffer',
      'calculated_driver_on_spot_buffer',
      'calculated_load_buffer',
      'calculated_safety_buffer',
      'overridden_arrival_buffer',
      'overridden_departure_buffer',
      'overridden_driver_on_spot_buffer',
      'overridden_load_buffer',
      'overridden_safety_buffer',
      'technical_state',
      'driver_on_spot_at',
      'travel_start_at',
      'safety_end_at',
      'number_of_clients',
      'has_police_escort',
      {
        client: [
          'id',
          'name',
          'surname',
          'archived_at',
          'email',
          'phone',
          'event_phone',
          'personal_assistant_email',
          { client_groups: ['id', 'name', 'background_color', 'border_color', 'font_color'] },
        ],
      },
      {
        client_group: ['id', 'background_color', 'border_color', 'font_color', 'name'],
      },
      {
        batch_onsite_request: ['id', 'eid'],
      },
      {
        operational_service_level: [
          'id',
          'name',
          'font_color',
          'background_color',
          'border_color',
          'position',
          'description',
        ],
      },
    ],
  },
  { vehicle_type: ['id', 'name'] },
  {
    operational_service_level: [
      'id',
      'name',
      'font_color',
      'background_color',
      'border_color',
      'position',
      'description',
    ],
  },
]

export const SUBSCRIPTIONS = {
  items: 'ShiftJobsMainChannel',
  locations: 'LocationsSimpleChannel',
  transportationPoints: 'TransportationPointsSimpleChannel',
  shifts: 'ShiftsSimpleChannel',
  clients: 'ClientsSimpleChannel',
  clusters: 'ClustersSimpleChannel',
  drivers: 'DriversDetailedChannel',
  driverCategories: 'DriverCategoriesSimpleChannel',
  vehicles: 'VehiclesDetailedChannel',
  vehicleTypes: 'VehicleTypesSimpleChannel',
  vehicleCategories: 'VehicleCategoriesSimpleChannel',
  vehicleModels: 'VehicleModelsSimpleChannel',
  vehicleBrands: 'VehicleBrandsSimpleChannel',
  transportBookings: 'TransportBookingsSimpleChannel',
  operationalServiceLevel: 'OperationalServiceLevelsDetailedChannel',
}

export const DEFAULT_COLUMNS = [
  'eid',
  'type',
  'aasmState',
  'pu',
  'do',
  'startAt',
  'endAt',
  'shift',
  'driver',
  'vehicle',
  'osl',
  'updatedAt',
]

export const COLUMNS_CONFIG = {
  eid: {
    label: 'EID',
    header: {
      sort: 'eid',
    },
    cell: {
      kind: 'simple',
      path: 'eid',
    },
  },
  type: {
    label: 'Type',
    header: {
      sort: 'type',
    },
    cell: {
      kind: 'component',
      component: markRaw(ShiftJobType),
      componentProps: item => ({ item: item }),
    },
  },
  aasmState: {
    label: 'State',
    header: {
      sort: 'aasmStateForSort',
    },
    cell: {
      kind: 'component',
      component: markRaw(ShiftJobAasmState),
      componentProps: item => ({ item: item }),
    },
  },
  pu: {
    label: 'Pick-up/start',
    header: {
      sort: 'startLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(LocationWithTp),
      componentProps: sj => ({
        item: {
          id: sj.id,
          startTransportationPoint: sj.startTransportationPoint,
          startLocation:
            sj?.type == 'ShiftAction' && sj?.shiftActionKind != 'custom_job'
              ? null
              : sj?.startLocation,
        },
        kind: 'start',
        // nowrap: true,
        minWidth: 250,
      }),
    },
  },
  startCluster: {
    label: 'Pick-up/start cluster',
    header: {
      sort: 'startLocationClusterName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Cluster),
      componentProps: item => ({
        cluster:
          item?.type == 'ShiftAction' && item?.shiftActionKind != 'custom_job'
            ? null
            : item?.startLocation?.cluster,
      }),
    },
  },
  startLocation: {
    label: 'Pick-up/start location',
    header: {
      sort: 'startLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Location),
      componentProps: item => ({
        minWidth: 250,
        location:
          item?.type == 'ShiftAction' && item?.shiftActionKind != 'custom_job'
            ? null
            : item?.startLocation,
      }),
    },
  },
  startTransportationPoint: {
    label: 'Pick-up/start TP',
    header: {
      sort: 'startTransportationPointName',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransportationPoint),
      componentProps: item => ({ transportationPoint: item?.startTransportationPoint }),
    },
  },
  do: {
    label: 'Drop-off/end',
    header: {
      sort: 'endLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(LocationWithTp),
      componentProps: sj => ({
        item: {
          id: sj.id,
          endTransportationPoint: sj.endTransportationPoint,
          endLocation:
            sj?.type == 'ShiftAction' && sj?.shiftActionKind != 'custom_job'
              ? null
              : sj?.endLocation,
        },
        kind: 'end',
        // nowrap: true,
        minWidth: 250,
      }),
    },
  },
  endCluster: {
    label: 'Drop-off/end cluster',
    header: {
      sort: 'endLocationClusterName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Cluster),
      componentProps: item => ({
        cluster:
          item?.type == 'ShiftAction' && item?.shiftActionKind != 'custom_job'
            ? null
            : item?.endLocation?.cluster,
      }),
    },
  },
  endLocation: {
    label: 'Drop-off/end location',
    header: {
      sort: 'endLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Location),
      componentProps: item => ({
        minWidth: 250,
        location:
          item?.type == 'ShiftAction' && item?.shiftActionKind != 'custom_job'
            ? null
            : item?.endLocation,
      }),
    },
  },
  endTransportationPoint: {
    label: 'Drop-off/end TP',
    header: {
      sort: 'endTransportationPointName',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransportationPoint),
      componentProps: item => ({ transportationPoint: item?.endTransportationPoint }),
    },
  },
  startAt: {
    label: 'Pick-up/start datetime',
    header: {
      sort: 'startAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item?.startAt, tz: item.startLocation?.timeZone }),
    },
  },
  startTime: {
    label: 'Pick-up/start time',
    header: {
      sort: 'startAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item?.startAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  startDate: {
    label: 'Pick-up/start date',
    header: {
      sort: 'startAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item?.startAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  endAt: {
    label: 'Drop-off/end datetime',
    header: {
      sort: 'endAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item?.endAt, tz: item.endLocation?.timeZone }),
    },
  },
  endTime: {
    label: 'Drop-off/end time',
    header: {
      sort: 'endAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item?.endAt,
        format: 'timeOnly',
        tz: item.endLocation?.timeZone,
      }),
    },
  },
  endDate: {
    label: 'Drop-off/end date',
    header: {
      sort: 'endAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item?.endAt,
        format: 'dateOnly',
        tz: item.endLocation?.timeZone,
      }),
    },
  },
  duration: {
    label: 'Duration',
    header: {
      sort: 'duration',
    },
    cell: {
      kind: 'component',
      component: 'humanized-duration',
      componentProps: item => ({ minutes: item.duration }),
    },
  },

  resultingArrivalBuffer: {
    label: 'Arrival buffer',
    header: {
      sort: 'resultingArrivalBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration: item.calculatedArrivalBuffer,
        override: item.overriddenArrivalBuffer,
      }),
    },
  },

  resultingLoadBuffer: {
    label: 'Load buffer',
    header: {
      sort: 'resultingLoadBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration: item.calculatedLoadBuffer,
        override: item.overriddenLoadBuffer,
      }),
    },
  },

  resultingDriverOnSpotBuffer: {
    label: 'Driver on spot buffer',
    header: {
      sort: 'resultingDriverOnSpotBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration: item.calculatedDriverOnSpotBuffer,
        override: item.overriddenDriverOnSpotBuffer,
      }),
    },
  },

  resultingSafetyBuffer: {
    label: 'Safety buffer',
    header: {
      sort: 'resultingSafetyBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration: item.calculatedSafetyBuffer,
        override: item.overriddenSafetyBuffer,
      }),
    },
  },

  resultingDepartureBuffer: {
    label: 'Departure buffer',
    header: {
      sort: 'resultingDepartureBuffer',
    },
    cell: {
      kind: 'component',
      component: markRaw(DurationWithOverride),
      componentProps: item => ({
        duration: item.calculatedDepartureBuffer,
        override: item.overriddenDepartureBuffer,
      }),
    },
  },

  driverOnSpotAt: {
    label: 'Driver on spot at',
    header: {
      sort: 'driverOnSpotAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.driverOnSpotAt }),
    },
  },

  travelStartAt: {
    label: 'Travel start at',
    header: {
      sort: 'travelStartAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.travelStartAt }),
    },
  },

  safetyEndAt: {
    label: 'Safety end at',
    header: {
      sort: 'safetyEndAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item.safetyEndAt }),
    },
  },

  clientGroups: {
    label: 'Client Groups',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: el => ({
        items: el?.allClientGroups,
        limit: 5,
        component: markRaw(ClientGroup),
        componentProps: c => ({ clientGroup: c }),
      }),
    },
  },

  shift: {
    label: 'Assigned Shift',
    header: {
      sort: 'shiftStagingAreaLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Shift),
      componentProps: item => ({ shift: item?.shift, detailed: true }),
    },
  },
  shiftDetails: {
    label: 'Assigned Shift Details',
    header: {
      sort: 'shiftStagingAreaLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Shift),
      componentProps: item => ({
        shift: item?.shift,
        detailed: true,
        withDriver: true,
        withVehicle: true,
      }),
    },
  },
  driver: {
    label: 'Driver',
    header: {
      sort: 'shiftDriverReverseFullName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Driver),
      componentProps: item => ({ driver: item?.shift?.driver, detailed: true }),
    },
  },
  driverName: {
    label: 'Driver name',
    header: {
      sort: 'shiftDriverName',
    },
    cell: {
      kind: 'simple',
      path: 'shift.driver.name',
    },
  },
  driverSurname: {
    label: 'Driver surname',
    header: {
      sort: 'shiftDriverSurname',
    },
    cell: {
      kind: 'simple',
      path: 'shift.driver.surname',
    },
  },
  driverPhoneNumber: {
    label: 'Driver phone number',
    header: {
      sort: 'shiftDriverMainPhone',
    },
    cell: {
      kind: 'component',
      component: 'phone-number',
      componentProps: sj => ({
        phoneNumber: sj.shift?.driver?.[camelCase(sj.shift?.driver?.primaryPhone || '')],
      }),
    },
  },
  driverCategory: {
    label: 'Driver category',
    header: {
      sort: 'shiftDriverCategoryName',
    },
    cell: {
      kind: 'component',
      component: markRaw(DriverCategory),
      componentProps: sj => ({
        driverCategory: sj.shift?.driver?.driverCategory,
      }),
    },
  },

  driverSource: {
    label: 'Driver source',
    header: {
      sort: 'shiftDriverDriverSourceName',
    },
    cell: {
      kind: 'component',
      component: markRaw(DriverSource),
      componentProps: tg => ({
        driverSource: tg.shift?.driver?.driverSource,
      }),
    },
  },

  driverComment: {
    label: 'Driver comment',
    header: {
      sort: 'driver_comment',
    },
    cell: {
      kind: 'component',
      component: 'ellipsis-with-tooltip',
      componentProps: item => ({
        text: item.driverComment,
        limit: 250,
      }),
    },
  },
  vehicle: {
    label: 'Vehicle',
    header: {
      // sort: 'shiftVehicleReverseFullName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Vehicle),
      componentProps: item => ({
        vehicle: item?.shift?.vehicle,
        detailed: true,
        showCluster: false,
      }),
    },
  },
  vehicleFleetNumber: {
    label: 'Vehicle fleet number',
    header: {
      sort: ['shiftVehicleFleetNumberPrefix', 'shiftVehicleFleetNumberInt'],
    },
    cell: {
      kind: 'simple',
      path: 'shift.vehicle.fleetNumber',
    },
  },
  vehicleRegistrationPlate: {
    label: 'Vehicle reg. plate',
    tooltip: 'Vehicle registration plate',
    header: {
      sort: 'shiftVehicleRegistrationPlate',
    },
    cell: {
      kind: 'simple',
      path: 'shift.vehicle.registrationPlate',
    },
  },
  vehicleCluster: {
    label: 'Vehicle cluster',
    header: {
      sort: 'shiftVehicleVehicleClusterName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Cluster),
      componentProps: sj => ({
        cluster: sj.shift?.vehicle?.cluster,
      }),
    },
  },
  vehicleCategory: {
    label: 'Vehicle category',
    header: {
      sort: 'shiftVehicleVehicleCategoryName',
    },
    cell: {
      kind: 'component',
      component: markRaw(VehicleCategory),
      componentProps: sj => ({
        vehicleCategory: sj.shift?.vehicle?.vehicleCategory,
      }),
    },
  },
  vehicleType: {
    label: 'Vehicle type',
    header: {
      sort: 'shiftVehicleVehicleTypeName',
    },
    cell: {
      kind: 'component',
      component: markRaw(VehicleType),
      componentProps: sj => ({
        vehicleType: sj.shift?.vehicle?.vehicleType,
      }),
    },
  },
  vehicleModel: {
    label: 'Vehicle model',
    header: {
      sort: 'shiftVehicleVehicleModelName',
    },
    cell: {
      kind: 'component',
      component: markRaw(VehicleModel),
      componentProps: sj => ({
        vehicleModel: sj.shift?.vehicle?.vehicleModel,
      }),
    },
  },
  vehicleSource: {
    label: 'Vehicle source',
    header: {
      sort: 'shiftVehicleVehicleSourceName',
    },
    cell: {
      kind: 'component',
      component: markRaw(VehicleSource),
      componentProps: tg => ({
        vehicleSource: tg.shift?.vehicle?.vehicleSource,
      }),
    },
  },
  osl: {
    label: 'Operational Service Level',
    header: {
      sort: 'operationalServiceLevelPosition',
    },
    cell: {
      kind: 'component',
      component: markRaw(Osl),
      componentProps: item => ({
        osl: item?.operationalServiceLevel,
        hide: !['ShuttleTransfer', 'TransferGroup'].includes(item.type),
      }),
    },
  },
  hasPoliceEscort: {
    label: 'Has police escort',
    header: {
      sort: 'hasPoliceEscort',
    },
    cell: {
      kind: 'component',
      component: TrueFalseLabel,
      componentProps: item => ({ item: !!item.transportBookings?.find(el => el.hasPoliceEscort) }),
    },
  },
  updatedAt: {
    label: 'Updated At',
    header: {},
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item?.updatedAt }),
    },
  },
  updatedTime: {
    label: 'Updated Time',
    header: {},
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item?.updatedAt, format: 'timeOnly' }),
    },
  },
  updatedDate: {
    label: 'Updated Date',
    header: {},
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({ t: item?.updatedAt, format: 'dateOnly' }),
    },
  },
  shuttleService: {
    label: 'Shuttle Service',
    header: {
      sort: 'shuttleServiceLowerName',
    },
    cell: {
      kind: 'component',
      component: markRaw(ShuttleService),
      componentProps: el => ({
        shuttleService: el.shuttleService,
      }),
    },
  },
  busId: {
    label: 'ST Bus ID',
    tooltip: 'Shuttle Transfer bus id',
    header: {
      sort: 'busIdOrShuttleServiceBusId',
    },
    cell: {
      kind: 'component',
      component: markRaw(BusIdDisplay),
      componentProps: el => ({
        item: el,
      }),
    },
  },
  stSource: {
    label: 'ST Source',
    tooltip: 'Shuttle Transfer source',
    header: {
      sort: 'source',
    },
    cell: {
      kind: 'function',
      fn: el => {
        const name = SOURCES.find(s => s.value == (el.source || 'manually_created'))?.label
        if (
          ['imported', 'synchronized_via_api', 'overwritten_api_synchronization'].includes(
            el.source,
          )
        ) {
          return `${name} with Remote ID ${el.remoteId}`
        } else {
          return name
        }
      },
    },
  },
  stOsls: {
    label: 'ST OSLs',
    tooltip: 'Shuttle Transfer operational service levels',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: el => ({
        items: el.operationalServiceLevels,
        limit: 5,
        component: markRaw(Osl),
        componentProps: ell => ({ osl: ell }),
      }),
    },
  },
  stVehicleTypes: {
    label: 'ST Vehicle types',
    tooltip: 'Shuttle Transfer vehicle types',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: el => ({
        items: el.vehicleTypes,
        limit: 5,
        component: markRaw(VehicleType),
        componentProps: ell => ({ vehicleType: ell }),
      }),
    },
  },
  stOtherLocations: {
    label: 'ST other locations in route',
    tooltip: 'Shuttle Transfer other locations in route',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: item => ({
        items: otherShuttlePoints(item),
        limit: 5,
        component: markRaw(Location),
        componentProps: el => ({ location: el.location, noCluster: true }),
      }),
    },
  },
  // stClientGroups: {
  //   label: 'ST Client groups',
  //   tooltip: 'Shuttle Transfer client groups',
  //   cell: {
  //     kind: 'component',
  //     component: 'limited-components-list',
  //     componentProps: el => ({
  //       items: el.clientGroups,
  //       limit: 5,
  //       component: markRaw(ClientGroup),
  //       componentProps: ell => ({ clientGroup: ell }),
  //     }),
  //   },
  // },
  saKind: {
    label: 'Shift Action kind',
    header: {
      sort: 'shiftActionKind',
    },
    cell: {
      kind: 'function',
      fn: item => SA_KINDS[item.shiftActionKind],
    },
  },
  // saDescription: {
  //   label: 'Shift Action - custom job description',
  //   header: {
  //     sort: 'description',
  //   },
  //   cell: {
  //     kind: 'component',
  //     component: 'ellipsis-with-tooltip',
  //     componentProps: item => ({
  //       text: item.description,
  //       limit: 250,
  //     }),
  //   },
  // },
  saSubkind: {
    label: 'Shift Action - job type',
    header: {
      sort: 'subkind',
    },
    cell: {
      kind: 'function',
      fn: item => CUSTOM_JOB_KINDS.find(k => k.value == item.subkind)?.label,
    },
  },
  backedUpLocation: {
    label: 'Shift Action - backed up location',
    header: {
      sort: 'backupLocationName',
    },
    cell: {
      kind: 'component',
      component: markRaw(Location),
      componentProps: item => ({
        location:
          item?.type == 'ShiftAction' && item?.shiftActionKind == 'location_backup'
            ? item?.startLocation
            : null,
      }),
    },
  },
  tgClients: {
    label: 'TG Clients',
    tooltip: 'Transfer Group clients',
    header: {
      // sort: 'clientFullName',
    },
    cell: {
      kind: 'component',
      component: markRaw(TransferGroupPassengersList),
      componentProps: item => ({
        transferGroup: item,
      }),
    },
  },
  tgTotalClients: {
    label: 'TG Total clients',
    tooltip: 'Transfer Group total number of clients',
    header: {
      sort: 'clientsCount',
    },
    cell: {
      kind: 'function',
      fn: item => transferGroupPassengersCount(item)?.toString(),
    },
  },
  tbEids: {
    label: 'Transport Booking EIDs',
    cell: {
      kind: 'component',
      component: markRaw(CollapsibleItems),
      componentProps: item => ({ items: item.transportBookings?.map(el => el.eid) }),
    },
  },
  tbTimes: {
    label: 'TB Times',
    header: {},
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqTbsByTimeAndFixedTime(tg),
        limit: 5,
        component: markRaw(TransportBookingTimeRange),
        componentProps: itemGroup => ({
          transportBooking: itemGroup[0],
          attrStart: 'startAt',
          attrEnd: 'endAt',
          showAnchor: true,
          format: 'timeOnly',
          counter: itemGroup.length,
        }),
      }),
    },
  },
  tbOppositeRouteBooking: {
    label: 'Transport Bookings Opposite Route Bookings',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: tbsByOppositeRouteBookingId(tg),
        limit: 5,
        component: markRaw(TransportBooking),
        componentProps: item => ({
          transportBooking: item.oppositeRouteBookingId
            ? { id: item.oppositeRouteBookingId, eid: item.oppositeRouteBookingEid }
            : null,
          eidOnly: true,
        }),
      }),
    },
  },

  tbCreatedBy: {
    label: 'Transport Bookings Created By',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqCreatedBy(tg),
        limit: 5,
        component: markRaw(User),
        componentProps: item => ({
          user: item,
        }),
      }),
    },
  },

  tbTptComment: {
    label: 'Transport Booking Transportation Team Comment',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqTptComment(tg),
        limit: 5,
        allowWrap: true,
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item,
          limit: 250,
        }),
      }),
    },
  },

  tbComment: {
    label: 'Transport Booking Comment',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqComment(tg),
        limit: 5,
        allowWrap: true,
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item,
          limit: 250,
        }),
      }),
    },
  },

  tbFlightNumber: {
    label: 'Transport Booking Travel Number',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqFlightNumber(tg),
        limit: 5,
        allowWrap: true,
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item,
          limit: 250,
        }),
      }),
    },
  },

  tbPrivateJet: {
    label: 'Transport Booking Private Jet',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => {
        const tbs = uniqPrivateJets(tg)
        return {
          items: tbs,
          limit: 4,
          component: markRaw(PrivateJetDisplay),
          componentProps: item => ({
            transportBooking: item,
            isSole: tbs.length == 1,
          }),
        }
      },
    },
  },

  tbFlightClass: {
    label: 'Transport Booking Flight Class',
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqFlightClass(tg),
        limit: 5,
        allowWrap: true,
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item,
          limit: 250,
        }),
      }),
    },
  },

  tbFlightAtDatetime: {
    label: 'Travel arrival/departure datetime',
    header: {
      sort: 'flightAt',
    },
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniq(tg.transportBookings.map(el => el.flightAt)),
        limit: 5,
        allowWrap: true,
        component: 'formatted-time',
        componentProps: item => ({
          t: item,
          tz: tg.startLocation?.timeZone,
        }),
      }),
    },
  },

  tbFlightAtTime: {
    label: 'Travel arrival/departure time',
    header: {
      sort: 'flightAt',
    },
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniq(tg.transportBookings.map(el => el.flightAt)),
        limit: 5,
        allowWrap: true,
        component: 'formatted-time',
        componentProps: item => ({
          t: item,
          tz: tg.startLocation?.timeZone,
          format: 'timeOnly',
        }),
      }),
    },
  },

  tbFlightAtDate: {
    label: 'Travel arrival/departure date',
    header: {
      sort: 'flightAt',
    },
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniq(tg.transportBookings.map(el => el.flightAt)),
        limit: 5,
        allowWrap: true,
        component: 'formatted-time',
        componentProps: item => ({
          t: item,
          tz: tg.startLocation?.timeZone,
          format: 'dateOnly',
        }),
      }),
    },
  },

  tbTerminalNumber: {
    label: 'Terminal No.',
    header: {
      sort: 'terminalNumber',
    },
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniq(tg.transportBookings.map(el => el.terminalNumber)),
        limit: 5,
        allowWrap: true,
        component: 'ellipsis-with-tooltip',
        componentProps: item => ({
          text: item,
          limit: 250,
        }),
      }),
    },
  },

  providedStamp: {
    label: 'Transport Booking Requested Time Kinds',
    header: {
      sort: 'providedStamp',
    },
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqTbProvidedStamps(tg),
        limit: 5,
        component: markRaw(Text),
        componentProps: item => ({ text: item }),
      }),
    },
  },

  tbRequestedDateTimes: {
    label: 'Transport Booking Requested Datetimes',
    header: {},
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqTbRequestedTimes(tg),
        limit: 5,
        component: 'formatted-time',
        componentProps: item => ({
          t: item,
          tz: tg.startLocation?.timeZone,
        }),
      }),
    },
  },

  tbRequestedTimes: {
    label: 'Transport Booking Requested Times',
    header: {},
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqTbRequestedTimes(tg),
        limit: 5,
        component: 'formatted-time',
        componentProps: item => ({
          t: item,
          tz: tg.startLocation?.timeZone,
          format: 'timeOnly',
        }),
      }),
    },
  },

  tbRequestedDates: {
    label: 'Transport Booking Requested Dates',
    header: {},
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: uniqTbRequestedDates(tg),
        limit: 5,
        component: 'formatted-time',
        componentProps: item => ({
          t: item,
          tz: tg.startLocation?.timeZone,
          format: 'dateOnly',
        }),
      }),
    },
  },

  tbRemoteIds: {
    label: 'TB Remote IDs',
    header: {},
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: tbRemoteIds(tg),
        limit: 5,
        component: markRaw(Text),
        componentProps: item => ({ text: item.remoteId }),
      }),
    },
  },

  dayLabels: {
    label: 'Occasion',
    header: {},
    cell: {
      kind: 'component',
      component: 'limited-components-list',
      componentProps: tg => ({
        items: tg.dayLabels,
        limit: 5,
        component: 'erebus-label',
        componentProps: c => ({ item: c }),
      }),
    },
  },

  inRouteDatetime: {
    label: 'In route datetime',
    header: {
      sort: 'inRouteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.inRouteAt,
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  inRouteDate: {
    label: 'In route date',
    header: {
      sort: 'inRouteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.inRouteAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  inRouteTime: {
    label: 'In route time',
    header: {
      sort: 'inRouteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.inRouteAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  atPickupSiteDatetime: {
    label: 'At pick-up site datetime',
    header: {
      sort: 'atPickupSiteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.atPickupSiteAt,
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  atPickupSiteDate: {
    label: 'At pick-up site date',
    header: {
      sort: 'atPickupSiteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.atPickupSiteAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  atPickupSiteTime: {
    label: 'At pick-up site time',
    header: {
      sort: 'atPickupSiteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.atPickupSiteAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  pickedUpDatetime: {
    label: 'Picked up datetime',
    header: {
      sort: 'pickedUpAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.pickedUpAt,
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  pickedUpDate: {
    label: 'Picked up date',
    header: {
      sort: 'pickedUpAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.pickedUpAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  pickedUpTime: {
    label: 'Picked up time',
    header: {
      sort: 'pickedUpAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.pickedUpAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  inProgressDatetime: {
    label: 'In progress datetime',
    header: {
      sort: 'inProgressAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.inProgressAt,
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  inProgressDate: {
    label: 'In progress date',
    header: {
      sort: 'inProgressAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.inProgressAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  inProgressTime: {
    label: 'In progress time',
    header: {
      sort: 'inProgressAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.inProgressAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  atDropoffSiteDatetime: {
    label: 'At drop-off site datetime',
    header: {
      sort: 'atDropoffSiteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.atDropoffSiteAt,
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  atDropoffSiteDate: {
    label: 'At drop-off site date',
    header: {
      sort: 'atDropoffSiteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.atDropoffSiteAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  atDropoffSiteTime: {
    label: 'At drop-off site time',
    header: {
      sort: 'atDropoffSiteAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.atDropoffSiteAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  droppedOffDatetime: {
    label: 'Dropped off datetime',
    header: {
      sort: 'droppedOffAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.droppedOffAt,
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  droppedOffDate: {
    label: 'Dropped off date',
    header: {
      sort: 'droppedOffAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.droppedOffAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  droppedOffTime: {
    label: 'Dropped off time',
    header: {
      sort: 'droppedOffAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.droppedOffAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  noShowDatetime: {
    label: 'Incomplete datetime',
    header: {
      sort: 'noShowAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.noShowAt,
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  noShowDate: {
    label: 'Incomplete date',
    header: {
      sort: 'noShowAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.noShowAt,
        format: 'dateOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
  noShowTime: {
    label: 'Incomplete time',
    header: {
      sort: 'noShowAt',
    },
    cell: {
      kind: 'component',
      component: 'formatted-time',
      componentProps: item => ({
        t: item.noShowAt,
        format: 'timeOnly',
        tz: item.startLocation?.timeZone,
      }),
    },
  },
}
