import { CriteriaValueType } from 'frontend/common/data-tables/search-criteria/enum/CriteriaValueType'
import { ISearchCriteriaNode } from 'frontend/filters/types/ISearchCriteriaNode'

export function getCriteriaValueTypeByConfig(
  criterion: ISearchCriteriaNode,
  predicate: string,
): CriteriaValueType {
  const config = criterion
  if (!config || !predicate) {
    return CriteriaValueType.Default
  }

  if (predicate === 'ccont') {
    return CriteriaValueType.CustomContains
  }
  if (
    ['blank', 'present', 'not_null', 'null', 'exists'].includes(predicate) ||
    config.type === 'boolean'
  ) {
    return CriteriaValueType.Boolean
  }
  if (['string', 'integer', 'float', 'time'].includes(config.type)) {
    if (['in', 'not_in', 'overlap_array', 'not_overlap_array'].includes(predicate)) {
      return CriteriaValueType.TaggableSelect
    }
    return CriteriaValueType.Input
  }
  if (config.type === 'datetime_with_variables') {
    return CriteriaValueType.DateTimeWithVariables
  }
  if (config.type === 'datetime') {
    return CriteriaValueType.DateTime
  }
  if (config.type === 'date_multi') {
    return CriteriaValueType.DateMulti
  }
  if (config.type === 'selection') {
    return CriteriaValueType.Selection
  }
  if (config.type === 'resource') {
    return CriteriaValueType.ResourceList
  }

  return CriteriaValueType.Default
}
