<script>
export default {
  name: 'DataTable',
}
</script>

<template lang="pug">
table.table.table-hover.table-sm.small
  slot
</template>

<style scoped lang="scss">
.table {
  &:deep(td),
  &:deep(th) {
    box-shadow: none;
    border-color: #b8c1cc;
  }

  &:deep thead {
    top: 100px;
  }

  &:deep(thead tr:first-child) {
    background: #fbfcfd;

    &:hover {
      background: #fbfcfd;
    }
  }

  & > :not(:first-child) {
    border-top: none;
  }
}
</style>
