<script>
import { inject, ref } from 'vue'

import { TIME_VARIABLES } from '../ea-filter/_ea-filter-kind-data'

export default {
  name: 'EaDateSelector',
  inheritAttrs: false,
  emits: [
    'update:modelValue',
    'onDayCreate',
    'onValueUpdate',
    'onReady',
    'onYearChange',
    'onMonthChange',
    'onClose',
    'onOpen',
    'onChange',
  ],
  props: {
    errorsTeleport: { type: String, default: null },
    initialVisibleDate: {
      type: Object,
      default: null,
      validator(v) {
        if (!v) return true
        return dayjs.isDayjs(v)
      },
    },
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    skipDirtyCheck: { type: Boolean, default: false },
    label: { type: String, default: null },
    name: { type: String, required: true },
    modelValue: { type: [String, Array] },
    inputSize: {
      type: String,
      default: null,
      validator(val) {
        return !val || ['sm', 'lg'].includes(val)
      },
    },
    inputClasses: {
      type: [String, Array, Object],
      default: null,
    },
    placeholder: { type: String, default: '' },
    hint: { type: String, default: null },
    inputAppend: { type: String, default: null },
    inputPrepend: { type: String, default: null },
    hideErrors: { type: Boolean, default: false },
    hideInvalidIcon: { type: Boolean, default: false },
    config: {
      type: Object,
      default: () => ({}),
    },
    validators: { type: Object, default: null },
    additionalErrors: {
      type: Array,
      default: () => [],
      validator(val) {
        return !val.find(item => item.constructor != String)
      },
    },
  },
  setup() {
    const flatpickCmpRef = ref(null)
    const wasOpened = ref(false)
    const inModal = inject('inModal')
    const modalEl = inject('modalEl', null)
    return {
      flatpickCmpRef,
      wasOpened,
      inModal,
      modalEl,
    }
  },
  computed: {
    clearIconVisible() {
      return (
        this.clearable &&
        this.modelValue?.length &&
        !Object.keys(TIME_VARIABLES).includes((this.modelValue || '').toString())
      )
    },
    passedSlots() {
      return Object.keys(this.$slots).filter(slot =>
        ['label-addon', 'input-append', 'input-prepend'].includes(slot),
      )
    },
    sizeClass() {
      return this.inputSize ? `form-control-${this.inputSize}` : ''
    },
    flatpickrConfig() {
      const result = Object.assign(
        {
          altFormat: 'd/m/Y',
          altInput: true,
          weekNumbers: false,
          allowInput: true,
        },
        this.config,
        {
          enableTime: false,
          static: false,
          wrap: false,
          nextArrow:
            '<div class="btn btn-sm px-2 btn-primary"><i class="fas fa-chevron-right"></div>',
          prevArrow:
            '<div class="btn btn-sm px-2 btn-primary"><i class="fas fa-chevron-left"></div>',
          dateFormat: 'Y-m-d',
        },
      )
      if (this.inModal && !result.position?.length) {
        result.position = 'bottom'
      }
      return result
    },
    flatpickrModelValue: {
      get() {
        if (this.modelValue?.length && this.modelValue?.constructor == Array) {
          return [...this.modelValue].sort().join(', ')
        }
        return this.modelValue
      },
      set(newVal) {
        if (this.flatpickrConfig.mode == 'multiple' && newVal?.includes(', ')) {
          this.$emit('update:modelValue', newVal.split(', ').sort())
        } else {
          this.$emit('update:modelValue', newVal)
        }
        // if (newVal == this.flatpickrModelValue) {
        //   this.$emit('update:modelValue', this.modelValue)
        //   return
        // }
        // const result = []
        // // if (newVal?.length) {
        // //   result = dayjs.tz(newVal, this.properTimeZone).utc().format()
        // // }
        // this.$emit('update:modelValue', result)
      },
    },
  },
  unmounted() {
    this.removeModalListeners()
  },
  methods: {
    closePicker() {
      if (this.flatpickCmpRef?.fp?.isOpen) {
        this.flatpickCmpRef.fp.close()
      }
    },
    runModalListeners() {
      if (this.inModal) {
        this.$eventHub.$on('windowResize', this.closePicker)
        this.modalEl?.addEventListener('scroll', this.closePicker)
        this.flatpickCmpRef?.fp?.calendarContainer?.addEventListener('mousedown', this.preventFn)
      }
    },
    removeModalListeners() {
      if (this.inModal) {
        this.$eventHub.$off('windowResize', this.closePicker)
        this.modalEl?.removeEventListener('scroll', this.closePicker)
        this.flatpickCmpRef?.fp?.calendarContainer?.removeEventListener('mousedown', this.preventFn)
      }
    },
    onOpen(e) {
      if (!this.wasOpened && this.initialVisibleDate && this.flatpickCmpRef?.fp) {
        this.wasOpened = true
        this.flatpickCmpRef.fp._initialDate = this.initialVisibleDate.toDate()
        if (!this.modelValue?.length) {
          this.flatpickCmpRef.fp.setDate(null)
        }
      }
      this.runModalListeners()
      this.$emit('onOpen', e)
    },
    preventFn(e) {
      e.stopPropagation()
      e.preventDefault()
    },
    onClose(e) {
      this.removeModalListeners()
      this.$emit('onClose', e)
    },
  },
}
</script>

<template lang="pug">
form-control-container.date-selector(
  ref="fcc"
  :additional-errors="additionalErrors"
  :class="{ 'has-clear-icon': clearable && !disabled }"
  :errorsTeleport="errorsTeleport"
  :hideErrors="hideErrors"
  :hideInvalidIcon="hideInvalidIcon"
  :hint="hint"
  :input-size="inputSize"
  :inputAppend="inputAppend"
  :inputPrepend="inputPrepend"
  :label="label"
  :name="name"
  :skipDirtyCheck="skipDirtyCheck"
  :validators="validators"
  :value="modelValue"
)
  template(v-for="slotName in passedSlots" #[slotName]="item")
    slot(v-bind="item" :name="slotName")

  template(#default)
    .input-addon-icon-btn(
      v-if="clearIconVisible && !disabled"
      @click="$emit('update:modelValue', null)"
      v-tooltip.options="{ title: 'Clear', placement: 'left' }"
    )
      i.bi-x(:name="name")
    flat-pickr.form-control(
      ref="flatpickCmpRef"
      :class="[sizeClass, inputClasses, `${inputSize == 'sm' ? 'form-control-sm' : ''}`]"
      :config="flatpickrConfig"
      :disabled="disabled"
      :name="name"
      :placeholder="placeholder"
      v-model="flatpickrModelValue"
      @mousedown.prevent.stop
      @mouseup.prevent.stop
      @onChange="$emit('onChange', $event)"
      @onClose="onClose($event)"
      @onDayCreate="$emit('onDayCreate', $event)"
      @onMonthChange="$emit('onMonthChange', $event)"
      @onOpen="onOpen($event)"
      @onReady="$emit('onReady', $event)"
      @onValueUpdate="$emit('onValueUpdate', $event)"
      @onYearChange="$emit('onYearChange', $event)"
    )
</template>
