<script>
import turfBbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import { lineString } from '@turf/helpers'
import ErrorsControl from 'frontend/common/ea-form-controls/_errors-control.vue'
import FifaGisMapPreview from 'frontend/common/maps/fifa-gis/fifa-gis-map-preview.vue'
import { computed, inject, onMounted } from 'vue'

import { DurationRulesService } from '../duration-rules-service'
import { useDurationRulePreview } from './use-duration-rule-preview'

export default {
  name: 'FifaGisDurationPreview',
  props: {
    durationRule: { type: Object, required: true },
    departureAt: { type: Object },
    arrivalAt: { type: Object },
    providedStamp: String,
  },
  setup(props) {
    const blocker = inject('blocker')
    const durationRulesService = new DurationRulesService({ blocker })

    const { arrivalAtCeiled, duration, fetchPreview, route, routeMetadata, serverErrors } =
      useDurationRulePreview(props.durationRule, durationRulesService)

    onMounted(() => {
      fetchPreview(props.durationRule, { withRoute: true })
    })
    const routeCoordinates = computed(() => route.value?.coordinates || [])
    const firstPoint = computed(() => routeCoordinates.value[0])
    const lastPoint = computed(() => routeCoordinates.value[routeCoordinates.value.length - 1])
    const mapItems = computed(() => {
      return [
        {
          type: 'Point',
          coordinates: firstPoint.value?.slice(0, 2)?.reverse(),
          label: 'A',
          title: 'Origin',
        },
        routeCoordinates.value?.length > 2 && {
          type: 'Polyline',
          coordinates: routeCoordinates.value,
        },
        {
          type: 'Point',
          coordinates: lastPoint.value?.slice(0, 2)?.reverse(),
          label: 'B',
          title: 'Destination',
        },
      ]
    })

    const boundingBox = computed(() => {
      if (routeCoordinates.value?.length) {
        const line = lineString(routeCoordinates.value.map(c => c.slice(0, 2).reverse()))
        const bbox = turfBbox(line)

        return bboxPolygon(bbox).geometry
      } else {
        return null
      }
    })

    return {
      firstPoint,
      lastPoint,
      duration,
      route,
      routeMetadata,
      serverErrors,
      arrivalAtCeiled,
      boundingBox,
      mapItems,
    }
  },
  components: {
    FifaGisMapPreview,
    ErrorsControl,
  },
}
</script>

<template lang="pug">
.google-maps-duration-preview
  .row(v-if="routeMetadata")
    .col-auto
      span.text-nowrap Pick-up:
        .bold
          formatted-time(:t="routeMetadata.departureAt" :tz="routeMetadata.departureTimeZone")
    .col-auto
      span.text-nowrap Drop-off:
        .bold
          formatted-time(
            :t="providedStamp == 'departureAt' ? arrivalAtCeiled : routeMetadata.arrivalAt"
            :tz="routeMetadata.arrivalTimeZone"
          )
    .col-auto
      span.text-nowrap Time:
        .bold.text-nowrap {{ duration }}
  fifa-gis-map-preview(v-if="routeMetadata" :boundingBox="boundingBox" :mapItems="mapItems")
  .mt-2
    errors-control(
      v-for="(error, index) in Object.values(serverErrors || {})"
      :additionalErrors="error"
      :key="index"
    )
</template>
